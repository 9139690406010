import React, { Component } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import { confirmPopup } from 'primereact/confirmpopup';
var axios = require('axios');

export default class Specs extends Component {
    constructor(props) {
        super(props);
        this.refFileInput = React.createRef();
        this.state = {
            loading: false,
            selectSpec: "json",
            specData: "",
            serviceId: 0,
            ownUrl: "",
            apiSpecsUrl: "",
            swaggerJson: "",
            errorMessage: "",
            successMessage: "",
        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    componentDidMount = () => {
        this.setState({
            serviceId: this.props.serviceId,
        })
        if (this.props.serviceId != 0) {
            this.getSpecsDetails(this.props.serviceId)
        }
    }
    onSaveServiceApiSpecsClicked = () => {
        if (this.state.swaggerJson == null || this.state.swaggerJson == '') {
            this.onSaveDocumentURL();
        }
        else {
            this.uploadApiSpecs();
        }

    }
    uploadApiSpecs = () => {
        this.setState({
            btnloading: true,
            documentUrl: ""
        })
        var token = CommonValues.GetToken();
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('sourcefile', this.state.swaggerJson);
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/upload/screenshot?serviceId=${this.props.serviceId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios(config)
            .then((response) => {
          
                this.setState({
                    apiSpecsUrl: response.data
                })

                this.onSaveDocumentURL();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    btnloading: false,

                });

                let errorMessage = '';
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while updating the specification document";
                    }
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
                else {
                    errorMessage = "Unknown error while loading the specification document";
                }
                setTimeout(
                    () => this.setState({ errorMessage: "" }),
                    3000
                );
                this.setState({ errorMessage: errorMessage });
            });
    };
    onSaveDocumentURL = () => {
        this.setState({
            loading: true
        });

        var token = CommonValues.GetToken();
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertscreenshotdocument?DocumentType=specs&id=${this.props.serviceId}&Url=${this.state.ownUrl}&Name=${this.state.screenshotName}&json=${this.state.apiSpecsUrl}`,


            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
              
                this.setState({
                    btnloading: false,
                    successMessage: "API specification uploaded successfully."
                });
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    5000
                );
                this.getSpecsDetails(this.props.serviceId)
                this.resetUI();

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                let errorMessage = '';
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while updating the specification document";
                    }

                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
                else {
                    errorMessage = "Unknown error while loading the specification document";
                }
                setTimeout(
                    () => this.setState({ errorMessage: "" }),
                    3000
                );
                this.setState({ errorMessage: errorMessage });

            });

    }
    resetUI = () => {
        this.setState({
            // ownUrl: "",
            // selectSpec: "json",
            // swaggerJson: ""
        });
        // this.refFileInput.current.value = "";
    }
    getSpecsDetails = (id) => {
        this.setState({
            serviceId: id,
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getbyid?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
             
                if(response.data[0].openApiDocuments == 'null')
                {
                  this.setState({ 
                    ownUrl : ""
                   });
                }
                else{
                    this.setState({ 
                        ownUrl: response.data[0].openApiDocuments
                     });
                   } 
                this.setState({
                    specData: response.data[0],
                    apiSpecsUrl: response.data[0].apiSpecsUrl,
                    loading: false,
                });


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onChangeValue = (e) => {
        this.setState({
            selectSpec: e.target.value
        })
    }
    onJsonSelect = (e) => {
        this.setState({ swaggerJson: e.target.files[0] });
    }
    onDocumentOPenClick = (e, url) => {
        window.open(url, "_blank");
        e.preventDefault();
    }
    onConfirmDeleteClick = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Do you want to delete this API specification document?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.deleteApiSpecs(),
            reject: this.reject
        });
    }
    deleteApiSpecs = () => {
        this.setState({
            loading: true
        });

        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'post',

            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertscreenshotdocument?DocumentType=specs&id=${this.props.serviceId}&Url=&Name=&json=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    loading: false,
                    successMessage: "API specification deleted successfully."
                });
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    5000
                );
                this.resetUI();
                this.getSpecsDetails(this.props.serviceId)
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    errorMessage: "Unknow error while deleting Api Spces"
                });
                setTimeout(
                    () => this.setState({ errorMessage: "" }),
                    3000
                );

                let errorMessage = '';
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while deleting the specification document";
                    }
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
                else {
                    errorMessage = "Unknown error while deleting the specification document";
                }
                setTimeout(
                    () => this.setState({ errorMessage: "" }),
                    3000
                );
                this.setState({ errorMessage: errorMessage });
            });

    }

    render() {

        return (
            <div>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>

                            <div >
                                <label className="form-label">
                                    Select Swagger Json
                                </label>
                                <input type="file"
                                    className={this.state.inputTextJsonErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    // name="file"
                                    onChange={this.onJsonSelect}
                                    name="swaggerJson"
                                    accept="application/pdf"
                                // ref={this.refFileInput}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageJSON}</small>
                            </div>
                            <div >
                                <label className="form-label">
                                    URL Of Your Own Page
                                </label>
                                <input type="text"
                                    id="ownUrl"
                                    name="ownUrl"
                                    value={this.state.ownUrl}
                                    className={this.state.inputTextUrlErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageUrl}</small>
                            </div>

                            <br />
                            {
                                this.state.loading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    :
                                    <>
                                        {(this.state.specData.apiSpecsUrl == "" || this.state.specData.apiSpecsUrl == null) ? null :
                                            <>
                                                <div className="row ">
                                                    <div className='col-md-4 col-4'><b>Swagger JSON</b></div>
                                                    <div className='col-md-4 col-4'><b>Action</b></div>

                                                </div>
                                                <div className="row">

                                                    <div className='col-md-4 col-4'>
                                                        <a href={this.state.specData.apiSpecsUrl} onClick={(e) => this.onDocumentOPenClick(e, this.state.specData.apiSpecsUrl)} >Open Spec JSON</a>
                                                    </div>
                                                    <div className='col-md-4 col-4'>
                                                        <i className="fa fa-trash " aria-hidden="true" onClick={this.onConfirmDeleteClick} style={{ cursor: "pointer" }}></i>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </>
                            }

                            <br />

                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.serviceId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveServiceApiSpecsClicked} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveServiceApiSpecsClicked} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>

                        </>
                }

            </div>
        );
    }
}

