import React, { Component } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import CommonValues from '../../Common/Util';
import { Message } from 'primereact/message';
import { animateScroll as scroll } from 'react-scroll'
import { ProgressSpinner } from 'primereact/progressspinner';
import { withRouter } from 'react-router-dom';
import { Redirect } from "react-router-dom";

class CompanyDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyId: 0,
            loading: false,
            address1: "",
            address2: "",
            description: "",
            website: "",
            companyName: "",
            userType: "",
            typeOfInsurance: "",
            lineOfBusiness: "",
            lineOfBusinessSubType: "",
            category: "",
            typeOfCompany: "",
            typeOfCompanySubType: "",
            valueChain: "",
            valueChainSubType: "",
            technology: "",
            subTechnology: "",
            email: "",
            phone: "",
            linkedIn: "",
            roles: "",
            inputTextErrorClass: false,
            errorMessageCompanyname: "",
            errorMessage: "",
            successMessage: "",
            businessList: [],
            lineOfBusinessList: [],
            companyTypeList: [],
            valueChainList: [],
            valueChainSubTypeList: [],
            technologyList: [],
            subTechnologyList: [],
            companyTypeLoading: false,
            companyTypeSubTypeLoading: false,
            lineOfBusinessLoading: false,
            lineOfBusinessSubTypeLoading: false,
            subTechnologyLoading: false,
            technologyLoading: false,
            valueChainLoading: false,
            valueChainSubTypeLoading: false,
            companyTypeIds: [],
            technologyIds: [],
            lineOfBusinessIds: [],
            typeOfCompanySubTypeIds: [],
            lineOfBusinessSubTypeIds: [],
            subTechnologyIds: [],
            valueChainIds: [],
            valueChainSubTypeIds: [],

            selectedCompanyType: [],
            selectedCompanyTypeSubType: [],
            selectedLineOfBusiness: [],
            selectedLineOfBusinessSubType: [],
            selectedTechnology: [],
            selectedTechnologySubType: [],
            selectedValueChain: []
        }

    }
    componentDidMount = () => {


        if (this.props.companyId != 0) {
            this.getCompanyDetails(this.props.companyId)
        }
        else {
            if(this.props.companyName !=null && this.props.companyName != undefined && this.props.companyName != ''){
                this.setState({companyName : this.props.companyName});
            }
            this.getAllCompanyType();
            this.getAllLineOfBusiness();
            // this.getAllTechnology();
            this.getAllValueChain();
            this.getAllCategories();
        }
    

    }
    componentDidUpdate = (prevProps) => {

        if (prevProps.companyId != this.props.companyId) {

            this.getCompanyDetails(this.props.companyId)
        }

        if (prevProps.companyName != this.props.companyName) {

            this.setState({companyName : this.props.companyName});
        }
    }

    getAllCompanyType = () => {
        this.setState({
            companyTypeLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getcompanytype`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                this.setState({
                    companyTypeList: response.data,
                    companyTypeLoading: false
                });

                const companyType = [];
                if (this.state.selectedCompanyType != null && response.data != null) {
                    this.state.selectedCompanyType.forEach(companyTypeId => {
                        response.data.forEach(item => {
                            if (item.id == companyTypeId) {
                                companyType.push(item)
                            }
                        });
                    });
                }
                this.setState({
                    typeOfCompany: companyType,
                })
                
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyTypeLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllCategories = () => {
        this.setState({
            companyTypeSubTypeLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getcategories`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
              
                this.setState({
                    companySubTypeList: response.data,
                    companyTypeSubTypeLoading: false
                });


                const category = [];
                if (this.state.selectedCompanyTypeSubType != null && response.data != null) {
                    this.state.selectedCompanyTypeSubType.forEach(categoryId => {
                        response.data.forEach(item => {
                            if (item.id == categoryId) {
                                category.push(item)
                            }
                        });
                    });
                }
                this.setState({
                    typeOfCompanySubType: category,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyTypeSubTypeLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllLineOfBusiness = () => {
        this.setState({
            lineOfBusinessLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getlineofbusiness`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                this.setState({
                    lineOfBusinessList: response.data,
                    lineOfBusinessLoading: false
                });
                const lineOfBusiness = [];
                if (this.state.selectedLineOfBusiness != null && response.data != null) {
                    this.state.selectedLineOfBusiness.forEach(lineOfBusinessId => {
                        response.data.forEach(item => {
                            if (item.id == lineOfBusinessId) {
                                lineOfBusiness.push(item)
                            }
                        });
                    });
                }
                this.setState({
                    lineOfBusiness: lineOfBusiness,
                })
                this.getLineOfBusinessSubType(lineOfBusiness, this.state.selectedLineOfBusinessSubType);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    lineOfBusinessLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllCategories = () => {
        this.setState({
            companyTypeSubTypeLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getcategories`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
              
                this.setState({
                    companySubTypeList: response.data,
                    companyTypeSubTypeLoading: false
                });


                const category = [];
                if (this.state.selectedCompanyTypeSubType != null && response.data != null) {
                    this.state.selectedCompanyTypeSubType.forEach(categoryId => {
                        response.data.forEach(item => {
                            if (item.id == categoryId) {
                                category.push(item)
                            }
                        });
                    });
                }
                this.setState({
                    typeOfCompanySubType: category,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyTypeSubTypeLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllValueChain = () => {
        this.setState({
            valueChainLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getallvaluechain`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                this.setState({
                    valueChainList: response.data,
                    valueChainLoading: false
                });
                const valueChain = [];
                if (this.state.selectedValueChain != null && response.data != null) {
                    this.state.selectedValueChain.forEach(valueChainId => {
                        response.data.forEach(item => {
                            if (item.id == valueChainId) {
                                valueChain.push(item)
                            }
                        });
                    });
                }

                this.setState({
                    valueChain: valueChain,
                })
                this.getValueChainSubType(valueChain, this.state.selectedValueChainSubType)
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    valueChainLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onLineOfBusinessChange = (e) => {
        this.setState({ lineOfBusiness: e.value });
        this.getLineOfBusinessSubType(e.value, '');
        this.setState({
            lineOfBusinessIds: e.value.map(element => element.id )
        })

    }
    getLineOfBusinessSubType = (lineOfBusiness, val) => {
        if(lineOfBusiness.length==0)
        {
            this.setState({
                businessListSubType:"",
                lineOfBusinessSubType:""
            })
        }
        this.setState({
            lineOfBusinessSubTypeLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify(lineOfBusiness);
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getlineofbusinesssubtype`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                this.setState({
                    businessListSubType: response.data,
                    lineOfBusinessSubTypeLoading: false
                });
                if (this.state.lineOfBusinessSubType!=null && this.state.lineOfBusinessSubType!='' && this.state.lineOfBusinessSubType.length > 0    ) {
                    if (response.data!=null && response.data.length > 0) {
                        const lineOfBusinessSubType = [];
                        response.data.forEach(list => {
                            this.state.lineOfBusinessSubType.forEach(item => {
                                if (item.id == list.id) {
                                    lineOfBusinessSubType.push(list)
                                }
                            })
                        })
                        this.setState({
                            lineOfBusinessSubType: lineOfBusinessSubType
                        });
                    }
                    
                }

                if (val != null) {
                    if (val.length != 0) {
                        const lineOfBusinessSubType = [];
                        val.forEach(lineOfBusinessSubTypeId => {
                            this.state.businessListSubType.forEach(item => {
                                if (item.id == lineOfBusinessSubTypeId) {
                                    lineOfBusinessSubType.push(item)
                                }
                            });
                        });
                        this.setState({
                            lineOfBusinessSubType: lineOfBusinessSubType
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    lineOfBusinessSubTypeLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onCompanyTypeChange = (e) => {
        this.setState({ typeOfCompany: e.value });      
        this.setState({
            companyTypeIds:e.value.map(element => element.id )
        });
    }
   
   
    onCompanyTypeSubTypeChange = (e) => {
        this.setState({ typeOfCompanySubType: e.value })
        this.setState({
            typeOfCompanySubTypeIds: e.value.map(element => element.id )
        });
    }
    onlineOfBusinessSubTypeChange = (e) => {
        this.setState({ lineOfBusinessSubType: e.value })
        this.setState({
            lineOfBusinessSubTypeIds: e.value.map(element => element.id )
        });
    }
    onTechnologySubTypeChange = (e) => {
        this.setState({ subTechnology: e.value })
        this.setState({
            subTechnologyIds: e.value.map(element => element.id )
        });
    }
    onValueChainChange = (e) => {
        this.setState({ valueChain: e.value })
        this.getValueChainSubType(e.value, '');
        this.setState({
            valueChainIds: e.value.map(element => element.id )
        });
    }
    onValueChainSubtypeChange = (e) => {
        this.setState({ valueChainSubType: e.value })
        this.setState({
            valueChainSubTypeIds: e.value.map(element => element.id )
        });
    }
    getValueChainSubType = (valueChain, val) => {
        if(valueChain.length==0)
        {
            this.setState({
                valueChainSubTypeList:"",
                valueChainSubType:""
            })
        }
        this.setState({
            valueChainSubTypeLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify(valueChain);
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getvaluechainsubtype`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                this.setState({
                    valueChainSubTypeList: response.data,
                    valueChainSubTypeLoading: false
                });

                if (this.state.valueChainSubType!=null && this.state.valueChainSubType!='' && this.state.valueChainSubType.length > 0    ) {
                    if (response.data!=null && response.data.length > 0) {
                        const valuechainSubType = [];
                        response.data.forEach(list => {
                            this.state.valueChainSubType.forEach(item => {
                                if (item.id == list.id) {
                                    valuechainSubType.push(list)
                                }
                            })
                        })
                        this.setState({
                            valueChainSubType: valuechainSubType
                        });
                    }
                    
                }
                if (val != null) {
                    if (val.length != 0) {
                        const valuechainSubType = [];
                        val.forEach(ValueChainSubTypeId => {
                            this.state.valueChainSubTypeList.forEach(item => {
                                if (item.id == ValueChainSubTypeId) {
                                    valuechainSubType.push(item)
                                }
                            });
                        });
                        this.setState({
                            valueChainSubType: valuechainSubType
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    valueChainSubTypeLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getCompanyDetails = (id) => {
        this.setState({
            companyId: id,
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getcompanybyid?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                
                this.getAllCompanyType();
                this.getAllLineOfBusiness();
                // this.getAllTechnology();
                this.getAllValueChain();
                this.getAllCategories();
                this.setState({
                    loading: false
                });

                if (response.data.userType == "Consumer, Creator") {
                    this.setState({
                        userType: "Both"
                    });
                }
                else {
                    this.setState({
                        userType: response.data.userType
                    });
                }

                this.setState({
                    selectedCompanyType: response.data.companyTypeIds,
                    selectedCompanyTypeSubType: response.data.companyTypeSubTypeIds,
                    selectedLineOfBusiness: response.data.lineOfBusinessIds,
                    selectedLineOfBusinessSubType: response.data.lineOfBusinessSubTypeIds,
                    selectedTechnology: response.data.technologyIds,
                    selectedTechnologySubType: response.data.technologySubTypeIds,
                    selectedValueChain: response.data.valueChainIds,
                    selectedValueChainSubType: response.data.valueChainSubTypeIds,
                    companyName: response.data.name,
                    website: response.data.website,
                    description: response.data.description,
                    address1: response.data.addressLine1,
                    address2: response.data.addressLine2,
                    city: response.data.city,
                    state: response.data.state,
                    zipcode: response.data.zipCode,
                    email: response.data.email,
                    phone: response.data.phoneNumber,
                    linkedIn: response.data.linkedInURL,
                    iconURL:response.data.iconURL
                })


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onCompanyDetailsSaveClick = () => {
        if (this.isValid()) {
            this.setState({
                btnloading: true
            });
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Name": this.state.companyName,
                "Website": this.state.website,
                "Description": this.state.description,
                "Email": this.state.email,
                "PhoneNumber": this.state.phone,
                "UserType": this.state.userType,
                "CompanyTypeIds": this.state.companyTypeIds,
                "CompanyTypeSubTypeIds": this.state.typeOfCompanySubTypeIds,
                "LineOfBusinessIds": this.state.lineOfBusinessIds,
                "LineOfBusinessSubTypeIds": this.state.lineOfBusinessSubTypeIds,
                "TechnologyIds": this.state.technologyIds,
                "TechnologySubTypeIds": this.state.subTechnologyIds,
                "ValueChainIds": this.state.valueChainIds,
                "ValueChainSubTypeIds": this.state.valueChainSubTypeIds,
                "AddressLine1": this.state.address1,
                "AddressLine2": this.state.address2,
                "City": this.state.city,
                "State": this.state.state,
                "ZipCode": this.state.zipcode,
                "LinkedInURL": this.state.linkedIn,
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_CompanyService_Url}/api/company/addcompany`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    debugger;

                    this.setState({
                        successMessage: "Company added successfully...!!!",
                        btnloading: false,
                        companyId: response.data.column1 + ''
                    });
                    this.props.setCompanyId(response.data.column1)
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        1000
                    );
                    setTimeout(
                        () => this.getCompanyDetails(response.data.column1),
                        1000
                    );
                    // this.props.history.push(`/company/${response.data.column1}`)
                    window.location = `/company/${response.data.column1}`;
                })
                .catch((error) => {
                    this.setState({
                        btnloading: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "company with the same name already exists"
                            });
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp`s Something went wrong"
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }

                    }
                });

        }
    }
    onCompanyDetailsUpdateClick = () => {
        if (this.isValid()) {
            this.setState({
                btnloading: true
            });
            const companyTypeIds = [];
            if (this.state.typeOfCompany != '') {
                this.state.typeOfCompany.forEach(element => {
                    companyTypeIds.push(element.id)
                });
            }

            const typeOfCompanySubTypeIds = [];
            if (this.state.typeOfCompanySubType != '') {
                this.state.typeOfCompanySubType.forEach(element => {
                    typeOfCompanySubTypeIds.push(element.id )
                });
            }

            const lineOfBusinessIds = [];
            if (this.state.lineOfBusiness != '') {
                this.state.lineOfBusiness.forEach(element => {
                    lineOfBusinessIds.push(element.id )
                });
            }

            const lineOfBusinessSubTypeIds = [];
            if (this.state.lineOfBusinessSubType != '') {
                this.state.lineOfBusinessSubType.forEach(element => {
                    lineOfBusinessSubTypeIds.push(element.id)
                });
            }

            const technologyIds = [];
            if (this.state.technology != '') {
                this.state.technology.forEach(element => {
                    technologyIds.push(element.id )
                });
            }

            const subTechnologyIds = [];

            if (this.state.subTechnology != '') {
                this.state.subTechnology.forEach(element => {
                    subTechnologyIds.push(element.id)
                });
            }

            const valueChainIds = [];
            if (this.state.valueChain != '') {
                this.state.valueChain.forEach(element => {
                    valueChainIds.push(element.id )
                });
            }


            const valueChainSubTypeIds = [];
            if (this.state.valueChainSubType != '') {
                this.state.valueChainSubType.forEach(element => {
                    valueChainSubTypeIds.push(element.id )
                })
            }

            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "CompanyId": this.props.companyId,
                "Name": this.state.companyName,
                "Website": this.state.website,
                "Description": this.state.description,
                "Email": this.state.email,
                "PhoneNumber": this.state.phone,
                "UserType": this.state.userType,
                "CompanyTypeIds": companyTypeIds,
                "CompanyTypeSubTypeIds": typeOfCompanySubTypeIds,
                "LineOfBusinessIds": lineOfBusinessIds,
                "LineOfBusinessSubTypeIds": lineOfBusinessSubTypeIds,
                "TechnologyIds": technologyIds,
                "TechnologySubTypeIds": subTechnologyIds,
                "ValueChainIds": valueChainIds,
                "ValueChainSubTypeIds": valueChainSubTypeIds,
                "AddressLine1": this.state.address1,
                "AddressLine2": this.state.address2,
                "City": this.state.city,
                "State": this.state.state,
                "ZipCode": this.state.zipcode,
                "LinkedInURL": this.state.linkedIn,
                "IconURL":this.state.iconURL
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_CompanyService_Url}/api/company/updatecompany`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then((response) => {

                    this.setState({
                        successMessage: "Company updated successfully...!!!",
                        btnloading: false
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        1000
                    );
                    setTimeout(
                        () => this.getCompanyDetails(this.state.companyId),
                        1000
                    );

                })
                .catch((error) => {
                    this.setState({
                        btnloading: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "company with the same name already exists"
                            });
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp`s Something went wrong"
                            });
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }

                    }
                });

        }
    }
    isValid = () => {

        if (this.state.companyName == "" || this.state.companyName == null) {
            this.setState({
                inputTextErrorClass: true,
                errorMessageCompanyname: "Please enter company name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextErrorClass: false,
                    errorMessageCompanyname: "",
                });
            }, 3000);
            scroll.scrollToTop();
            return false;
        }

        return true
    }
    render() {

        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.ClearValues();
            return <Redirect to="/" />
        }
      

        return (
            <div>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div >
                                <label className="form-label">
                                    Company Name
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="companyName"
                                    name="companyName"
                                    value={this.state.companyName}
                                    className={this.state.inputTextErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>
                            <label className="form-label">
                                Website
                            </label>
                            <input type="text"
                                id="website"
                                name="website"
                                value={this.state.website}
                                className="form-control"
                                onChange={this.onChange}
                            />

                            <label className="form-label">
                                Description
                            </label>
                            <textarea type="text"
                                id="description"
                                name="description"
                                value={this.state.description}
                                className="form-control"
                                onChange={this.onChange}
                            />

                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className="form-label">
                                        Address Line 1
                                    </label>
                                    <input type="text"
                                        id="address1"
                                        name="address1"
                                        value={this.state.address1}
                                        className="form-control"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label className="form-label">
                                        Address Line 2
                                    </label>
                                    <input type="text"
                                        id="address2"
                                        name="address2"
                                        value={this.state.address2}
                                        className="form-control"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className="form-label">
                                        City
                                    </label>
                                    <input type="text"
                                        id="city"
                                        name="city"
                                        value={this.state.city}
                                        className="form-control"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label className="form-label">
                                        State
                                    </label>
                                    <input type="text"
                                        id="state"
                                        name="state"
                                        value={this.state.state}
                                        className="form-control"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className="form-label">
                                        Zip code
                                    </label>
                                    <input type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        value={this.state.zipcode}
                                        className="form-control"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <label className="form-label">
                                User Type
                            </label>
                            <div className="p-field-radiobutton" style={{ marginBottom: "10px" }}>
                                <RadioButton inputId="Consumer" name="userType" value="Consumer" onChange={(e) => this.setState({ userType: e.value })} checked={this.state.userType === 'Consumer'} />
                                <label htmlFor="Consumer" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Consumer</label>

                                <RadioButton inputId="Creator" name="userType" value="Creator" onChange={(e) => this.setState({ userType: e.value })} checked={this.state.userType === 'Creator'} />
                                <label htmlFor="Creator" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Creator</label>

                                <RadioButton inputId="Both" name="userType" value="Both" onChange={(e) => this.setState({ userType: e.value })} checked={this.state.userType === 'Both'} />
                                <label htmlFor="Both" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Both</label>
                            </div><br />

                            <div className="row">
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.companyTypeLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.typeOfCompany}
                                                options={this.state.companyTypeList}
                                                onChange={this.onCompanyTypeChange}
                                                optionLabel="name"
                                                placeholder="Select a Company Type"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }

                                </div>
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.companyTypeSubTypeLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.typeOfCompanySubType}
                                                options={this.state.companySubTypeList}
                                                onChange={this.onCompanyTypeSubTypeChange}
                                                optionLabel="name"
                                                placeholder="Select Categories"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.lineOfBusinessLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.lineOfBusiness}
                                                options={this.state.lineOfBusinessList}
                                                onChange={this.onLineOfBusinessChange}
                                                optionLabel="name"
                                                placeholder="Select a Line Of Business"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }
                                </div>
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.lineOfBusinessSubTypeLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.lineOfBusinessSubType}
                                                options={this.state.businessListSubType}
                                                onChange={this.onlineOfBusinessSubTypeChange}
                                                optionLabel="name"
                                                placeholder="Select a Line Of Business Sub Type"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }
                                </div>
                            </div>
                            <br />

                            {/* <div className="row">
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.technologyLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.technology}
                                                options={this.state.technologyList}
                                                onChange={this.onTechnologyChange}
                                                optionLabel="name"
                                                placeholder="Select a Technology"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }
                                </div>
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.subTechnologyLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.subTechnology}
                                                options={this.state.subTechnologyList}
                                                onChange={this.onTechnologySubTypeChange}
                                                optionLabel="name"
                                                placeholder="Select a Technology Sub Type"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.valueChainLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.valueChain}
                                                options={this.state.valueChainList}
                                                onChange={this.onValueChainChange}
                                                optionLabel="name"
                                                placeholder="Select a Value Chain"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }

                                </div>
                                <div className="col-md-6 col-6">
                                    {
                                        this.state.valueChainSubTypeLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <MultiSelect value={this.state.valueChainSubType}
                                                options={this.state.valueChainSubTypeList}
                                                onChange={this.onValueChainSubtypeChange}
                                                optionLabel="name"
                                                placeholder="Select a Value Chain Sub Type"
                                                maxSelectedLabels={5}
                                                style={{ width: "100%" }} />
                                    }

                                </div>
                            </div>

                            <br />
                            <label className="form-label">
                                Enter Email
                            </label>
                            <input type="text"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                className="form-control" />


                            <label className="form-label">
                                Enter Phone Number
                            </label>
                            <input type="text" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} className="form-control" />


                            <label className="form-label">
                                Enter Linkedin Details
                            </label>
                            <input type="text" value={this.state.linkedIn} onChange={(e) => this.setState({ linkedIn: e.target.value })} className="form-control" /><br />

                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">
                                    {
                                        this.state.companyId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onCompanyDetailsSaveClick} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onCompanyDetailsUpdateClick} style={{ float: "right" }} />
                                    }

                                </div>
                            </div>

                        </>
                }

            </div>
        )
    }
}
export default withRouter(CompanyDetails);
