import React, { Component } from 'react';
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import { Button } from 'primereact/button';
import Footer from '../../Common/Footer';
import CommonValues from '../../Common/Util';
import PageHeader from '../../Common/PageHeader';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

class WorkflowMaster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            loading: false,
            workflowList:[]
        }
    }
    onBodyClick = () => {
        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    componentDidMount = () => {
        this.getAllWorkflowList(0,'');
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.onSearchClick();
        }
    }
    getAllWorkflowList = (workflowId,searchText) => {
        this.setState({
            loading: true
        });
        let workflowIds=[];
        if(workflowId!=0)
        {
            workflowIds.push(workflowId)
        }
        var axios = require('axios');
        var data = JSON.stringify({
            "WorkflowId": workflowIds,
            "Name":searchText
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/list`,
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then((response) => {

                this.setState({
                    workflowList: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onWorkflowClick=(workflowId)=>{
        this.props.history.push(`/workflow/${workflowId}`)
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.onWorkflowClick(rowData.workflowId)} />
                &emsp;
                {/* <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.confirmDeleteService(rowData)} /> */}
            </React.Fragment>
        );
    }
    descriptionBodyTemplate =(rowData)=>{
        return (
            <React.Fragment>
                <p dangerouslySetInnerHTML={{ __html: rowData.shortDescription}}/>                    
            </React.Fragment>
        );
    }
    onSearchClick =()=>{
        this.getAllWorkflowList(0,this.state.searchText)
    }
    onRefreshClick =()=>{
        this.setState({
            searchText:""
        });
        this.getAllWorkflowList(0,'');
    }
    onAddNewWorkflowClick =()=>{
        this.props.history.push(`/workflow/0`)
    }
    render() {
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <PageHeader PageTitle="Workflow Master"></PageHeader>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div
                                                            className="input-group input-group-sm"
                                                        >
                                                            <input
                                                                type="text"
                                                                name="searchText"
                                                                className="form-control float-right"
                                                                placeholder="Search"
                                                                value={this.state.searchText}
                                                                onChange={this.onChange}
                                                                onKeyDown={this.onKeyClick}
                                                            />
                                                            <div className="input-group-append">
                                                                <button type="submit" className="btn btn-default" onClick={this.onSearchClick}>
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicemain">
                                                        <Button label="Refresh" className='p-button-sm' icon="pi pi-sync" onClick={this.onRefreshClick} />
                                                        &nbsp;
                                                        <Button label="Add New Workflow" className='p-button-sm' icon="pi pi-plus" onClick={this.onAddNewWorkflowClick} />
                                                    </div>


                                                </div>

                                                <div className="card-tools">

                                                </div>
                                            </div>
                                            {
                                                this.state.loading ?
                                                    <>
                                                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                                        <div style={{ height: "600px" }}></div>
                                                    </>

                                                    :
                                                    <div className="card-body table-responsive p-0">
                                                        <div className="d-none d-md-block">
                                                            <DataTable value={this.state.workflowList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={10} responsiveLayout="scroll">                                                              
                                                                
                                                                <Column field="title" header="Workflow Name" sortable></Column>
                                                                <Column field={this.descriptionBodyTemplate} header="Description" ></Column>
                                                                <Column field="workflowType" header="Workflow Type" ></Column>
                                                                <Column field="created" header="Date created" sortable></Column>                                                               
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>

                                                        <div className="d-block d-md-none">
                                                            <DataTable value={this.state.workflowList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">

                                                                <Column field="title" header="Workflow Name" sortable></Column>
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        )
    }
}
export default WorkflowMaster
