import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import CommonValues from '../../Common/Util';

export default class UserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            userId: 0,
            companyListOption: [],
            userRole: "",
            firstName: "",
            lastName: "",
            workEmail: "",
            companyName: "",
            isUserApproved: false,
            isUserActive: false,
            userDetails: [],
        }
        this.userRoleList = [
            { name: 'IG Admin', id: '1' },
            { name: 'Company Admin', id: '2' },
            { name: 'Normal', id: '3' }
        ];
    }
   
    componentDidMount = () => {
        this.setState({
            userId: this.props.userId,
        });
        this.getAllCompanyList('');
   
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onUserRoleChange = (e) => {
        this.setState({
            userRole: e.value
        })
    }
    onCompanyNameChange = (e) => {
        this.setState({
            companyName: e.value,
        })
    }
    getAllCompanyList = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                this.setState({
                    companyListOption: response.data,
                });
                if (this.props.userId != 0) {
                    this.getUserDetailsById(this.props.userId)
                }
                else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    
    render() {
        return (
            <div>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div >
                                <label className="form-label">
                                    First Name
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={this.state.firstName}
                                    className={this.state.inputTextServiceErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                    disabled={true}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageServicename}</small>
                            </div>

                            <div >
                                <label className="form-label">
                                    Last Name
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={this.state.lastName}
                                    className={this.state.inputTextServiceErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                    disabled={true}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageServicename}</small>
                            </div>

                            <div >
                                <label className="form-label">
                                    Work Email
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="workEmail"
                                    name="workEmail"
                                    value={this.state.workEmail}
                                    className={this.state.inputTextServiceErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                    disabled={true}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageServicename}</small>
                            </div>
                            <div >
                                <label className="form-label">
                                    Company Name
                                </label><span style={{ color: "red" }}>*</span>
                                <Dropdown
                                    className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.companyName}
                                    options={this.state.companyListOption}
                                    onChange={this.onCompanyNameChange}
                                    optionLabel="name"
                                    placeholder="Select Company Name"
                                    style={{ width: "100%" }}
                                    disabled={true}
                                />

                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>

                            <div >
                                <label className="form-label">
                                    User Role
                                </label><span style={{ color: "red" }}>*</span>
                                <Dropdown
                                    className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.userRole}
                                    options={this.userRoleList}
                                    onChange={this.onUserRoleChange}
                                    optionLabel="name"
                                    placeholder="Select User Role"
                                    style={{ width: "100%" }}
                                    disabled={true}
                                />

                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>

                            <div >
                                <label className="form-label ">
                                    Approved User
                                </label><span style={{ color: "red" }}>*</span>
                                <InputSwitch
                                    className="ml-2 inputswitch-css"
                                    checked={this.state.isUserApproved}
                                    onChange={(e) => this.setState({ isUserApproved: e.value })}
                                    disabled={true}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>
                            <div  >
                                <label className="form-label">
                                    Active User
                                </label><span style={{ color: "red" }}>*</span>
                                <InputSwitch
                                    className="ml-2 inputswitch-css"
                                    checked={this.state.isUserActive}
                                    disabled={true}
                                    onChange={(e) => this.setState({ isUserActive: e.value })}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>

                            <div className="row">
                                <div className="col-10">
                                    {/* {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null} */}
                                </div>
                                <div className="col-2">

                                    {/* {
                                        this.state.userId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onOverviewSaveClick} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={() => this.onOverviewUpdateClick(this.props.serviceId)} style={{ float: "right" }} />
                                    } */}


                                </div>
                            </div>
                        </>
                }

            </div>
        )
    }
}
