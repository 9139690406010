import React, { Component } from 'react'
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import CommonValues from '../../Common/Util';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ListBox } from 'primereact/listbox';

export default class EndpointDetailsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sequenceNo: "",
            endpointcode: "",
            responseType: "",
            responseMapping: "",
            inputDataHeader: "",
            outputDataHeader: "",
            outputDataScope: "",
            inputDataScope: "",
            inputDataKey: "",
            successMessage: "",

            errorMessage: "",
            errorMessageSequenceNo: "",
            errorMessageEndpointCode: "",
            errorMessageUseResponse: "",
            errorMessageResponseType: "",
            errorMessageResponseMapping: "",
            errorMessageName: "",
            errorInputDataKey: "",
            errorInputDataValue: "",
            errorinputDataHeader: "",
            errorinputDataScope: "",
            errorOutputDataScope: "",
            errorOutputDataHeader: "",
            errorOutputDataValue: "",
            errorOutputDataHeader: "",

            inputDataList: [],
            responseTypeList: [],

            isUseResponse: false,
            loading: false,
            showInputData: false,
            showOutputData: false,
            btnoutputdatasave: false,
            outputDataDetailsLoading: false
        }
        this.ScopeList =
            [
                { name: 'Header', id: 'H' },
                { name: 'Parameter', id: 'P' }
            ]

    }
    componentDidMount = () => {
        if (this.props.endpoint != null) {
            this.setState({
                endpointcode: this.props.endpoint.endpointCode,
                sequenceNo: this.props.endpoint.sequenceNo,
                responseMapping: this.props.endpoint.responseMapping,
                name: this.props.endpoint.name,
            });
            this.getEndpointInputData();
            this.getEndpointOutputData();
        }
        this.getEndPointDetails();

    }
    getEndPointDetails = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints?id=0&flag=getall`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false,
                    endpoints: response.data
                });
                if (this.props.endpoint != null) {
                    response.data.forEach(element => {
                        if (this.props.endpoint.endpointCode == element.apiEndPointCode) {
                            this.setState({
                                endpointcode: element
                            })
                        }

                    });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getEndpointOutputData = () => {
        this.setState({
            outputDataDetailsLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigendpointoutputdata?id=${this.props.endpoint.id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    outputDataDetailsLoading: false,
                    endpointsOutputData: response.data
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    outputDataDetailsLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getEndpointInputData = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigendpointinputdata?id=${this.props.endpoint.id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
             
                this.setState({
                    loading: false,
                    endpointsInputData: response.data
                });


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    checkInputValidation = () => {
        let formIsValid = true;
        if (this.state.inputDataValue == "" || this.state.inputDataValue == null) {
            formIsValid = false;
            this.setState({
                errorInputDataValue: "Please enter input value"
            });
            setTimeout(() => {
                this.setState({

                    errorInputDataValue: "",
                });
            }, 3000);

        }

        if (this.state.inputDataHeader == "" || this.state.inputDataHeader == null) {
            formIsValid = false;
            this.setState({
                errorinputDataHeader: "Please enter input header"
            });
            setTimeout(() => {
                this.setState({

                    errorinputDataHeader: "",
                });
            }, 3000);

        }
        if (this.state.inputDataScope == "" || this.state.inputDataScope == null) {
            formIsValid = false;
            this.setState({
                errorinputDataScope: "Please select input scope"
            });
            setTimeout(() => {
                this.setState({

                    errorinputDataScope: "",
                });
            }, 3000);

        }
        return formIsValid;
    }
    checkOutputValidation = () => {
        let formIsValid = true;
        if (this.state.outputDataValue == "" || this.state.outputDataValue == null) {
            formIsValid = false;
            this.setState({
                errorOutputDataValue: "Please enter output value"
            });
            setTimeout(() => {
                this.setState({
                    errorOutputDataValue: "",
                });
            }, 3000);

        }

        if (this.state.outputDataHeader == "" || this.state.outputDataHeader == null) {
            formIsValid = false;
            this.setState({
                errorOutputDataHeader: "Please enter output header"
            });
            setTimeout(() => {
                this.setState({
                    errorOutputDataHeader: "",
                });
            }, 3000);

        }
        if (this.state.outputDataScope == "" || this.state.outputDataScope == null) {
            formIsValid = false;
            this.setState({
                errorOutputDataScope: "Please select output scope"
            });
            setTimeout(() => {
                this.setState({
                    errorOutputDataScope: "",
                });
            }, 3000);

        }
        return formIsValid;
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onResponseTypeChange = (e) => {
        this.setState({
            responseType: e.value
        });
    }
    onEndpointChange = (e) => {
        this.setState({
            endpointcode: e.value
        });
    }
    oninputDataScopeChange = (e) => {
        this.setState({
            inputDataScope: e.value
        });
    }
    onOutputDataScopeChange = (e) => {
        this.setState({
            outputDataScope: e.value
        });
    }
    onEndpointDetailsSaveClick = () => {
        this.setState({
            btnloadingsave: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "Name": this.state.name,
            "GIGCode": this.props.gigCode,
            "EndpointCode": this.state.endpointcode.apiEndPointCode,
            "SequenceNo": this.state.sequenceNo,
            "ResponseMapping": this.state.responseMapping,
            "Id": this.props.endpoint != null ? this.props.endpoint.id : 0
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigendpointdetails?`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {


                this.setState({
                    successMessage: "Details added successfully...!!!",
                    btnloadingsave: false,

                });

                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    1000
                );

                this.props.onEndpointGetAddedOrUpdated()

            })
            .catch((error) => {
                this.setState({
                    btnloadingsave: false
                });
                if (error.response != null) {
                    if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "endpoint with the same name already exists"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s Something went wrong"
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }

                }
            });
    }
    onCancelClick = () => {
        this.props.onCancelClick()
    }
    onShowInputPopupClicked = () => {
        this.setState({
            showInputData: !this.state.showInputData
        })
    }
    onShowOutputPopupClicked = () => {
        this.setState({
            showOutputData: !this.state.showOutputData
        })
    }
    actionBodyTemplateInputData = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.onDeleteFieldMappingClicked(rowData.id)} />
            </React.Fragment>
        );
    }
    actionBodyTemplateOutputData = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.onDeleteOutputDataClicked(rowData.id)} />
            </React.Fragment>
        );
    }
    actionBodyTemplateOutputDataScope = (rowData) => {
        return (
            <React.Fragment>
                <p>{rowData.scope == "H" ? "Header" : "Parameter"}</p>

            </React.Fragment>
        );
    }
    actionBodyTemplateInputDataScope = (rowData) => {
        return (
            <React.Fragment>
                <p>{rowData.scope == "H" ? "Header" : "Parameter"}</p>

            </React.Fragment>
        );
    }
    onAddInputDataClick = () => {
        if (this.checkInputValidation()) {
            this.setState({
                btninputdatasave: true
            });
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Value": this.state.inputDataValue,
                "APIEndPointid": this.props.endpoint.id,
                "Scope": this.state.inputDataScope.id,
                "Header": this.state.inputDataHeader
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigendpointinputdata?`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.getEndpointInputData()
                    this.setState({
                        successMessage: "Input data details added successfully...!!!",
                        btninputdatasave: false,
                        inputDataHeader: "",
                        inputDataValue: "",
                        inputDataScope: ""
                    });

                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        1000
                    );

                })
                .catch((error) => {
                    this.setState({
                        btninputdatasave: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "input data with the same name already exists"
                            });
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp`s Something went wrong"
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }

                    }
                });
        }
    }
    onAddOutputDataClick = () => {
        if (this.checkOutputValidation()) {
            this.setState({
                btnoutputdatasave: true
            });
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Value": this.state.outputDataValue,
                "APIEndPointid": this.props.endpoint.id,
                "Scope": this.state.outputDataScope.id,
                "Header": this.state.outputDataHeader
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigendpointoutputdata?`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.getEndpointOutputData();
                    this.setState({
                        successMessage: "Output data details added successfully...!!!",
                        outputDataValue: "",
                        btnoutputdatasave: false,
                        outputDataValue: "",
                        outputDataHeader: "",
                        outputDataScope: ""
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        1000
                    );

                })
                .catch((error) => {
                    this.setState({
                        btnoutputdatasave: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "Output data with the same name already exists"
                            });
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp`s Something went wrong"
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }

                    }
                });
        }
    }
    countryOptionTemplate(option) {
        return (
            <div className="country-item">
                <img alt={option.name} src={option.iconUrl} width="20px" /> &emsp;{option.apiEndPointCode}

            </div>
        );
    }
    render() {
        return (
            <>

                <div className="row p-2" >
                    <div className='col-2'>
                        <label>Name</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-10'>
                        <input
                            type="text"
                            name="name"
                            className={this.state.errorMessageName.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                            value={this.state.name}
                            onChange={this.onChange}
                        />
                        <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                    </div>
                </div>
                <div className="row p-2" >
                    <div className='col-2'>
                        <label>Endpoint Code</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-4'>
                        <Dropdown value={this.state.endpointcode}
                            options={this.state.endpoints}
                            onChange={this.onEndpointChange}
                            optionLabel="apiEndPointCode"
                            placeholder="Select"
                            filter
                            style={{ width: "100%" }}
                            itemTemplate={this.countryOptionTemplate}
                            className={this.state.errorMessageEndpointCode.length > 0 ? "p-invalid p-mr-2" : ""}
                        />
                        <small id="username-help" className="p-error">{this.state.errorMessageEndpointCode}</small>
                    </div>

                    <div className='col-2'>
                        <label>Sequence No</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-4'>
                        <input
                            type="text"
                            name="sequenceNo"
                            className={this.state.errorMessageSequenceNo.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                            value={this.state.sequenceNo}
                            onChange={this.onChange}
                        />
                        <small id="username-help" className="p-error">{this.state.errorMessageSequenceNo}</small>
                    </div>
                </div>

                <div className="row p-2" >
                    <div className='col-2'>
                        <label>Input Data</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-4'>
                        <ListBox
                            options={this.state.endpointsInputData}
                            optionLabel="value"
                            //style={{ width: '15rem' }} 
                            listStyle={{ maxHeight: '200px', minHeight: '200px' }} />
                    </div>

                    <div className='col-2'>
                        <label>Output Data</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-4'>
                        <ListBox
                            options={this.state.endpointsOutputData}
                            optionLabel="value"
                            //style={{ width: '15rem' }} 
                            listStyle={{ maxHeight: '200px', minHeight: '200px' }} />
                    </div>
                </div>

                <div className="row p-2" >
                    <div className='col-6'>
                        <Button label="Manage Input" icon="pi pi-pencil" loading={this.state.btnloadingsave} style={{ float: "right" }} onClick={this.onShowInputPopupClicked} />
                    </div>
                    <div className='col-6'>
                        <Button label="Manage Output" icon="pi pi-pencil" loading={this.state.btnloadingsave} style={{ float: "right" }} onClick={this.onShowOutputPopupClicked} />
                    </div>
                </div>
                {/* <div className="row p-2" >
                    <div className='col-2'>
                        <label>Use Response</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-4'>
                        <InputSwitch
                            checked={this.state.isUseResponse}
                            onChange={(e) => this.setState({ isUseResponse: e.value })}

                        />
                        <small id="username-help" className="p-error">{this.state.errorMessageUseResponse}</small>
                    </div>

                    <div className='col-2'>
                        <label>Response Type</label>
                        <span style={{ color: "red" }}>*</span>
                    </div>
                    <div className='col-4'>
                        {this.state.userLoading ?
                            <div style={{ textAlign: "center" }}>
                                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                            :
                            <Dropdown value={this.state.responseType}
                                options={this.state.responseTypeList}
                                onChange={this.onResponseTypeChange}
                                optionLabel="name"
                                placeholder="Select"
                                style={{ width: "100%" }}
                                filter
                                className={this.state.errorMessageResponseType.length > 0 ? "p-invalid p-mr-2" : ""}
                            />
                        }
                        <small id="username-help" className="p-error">{this.state.errorMessageResponseType}</small>
                    </div>
                </div> */}
                <br />


                <div className="row">
                    <div className="col-4">
                        {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                            : null}
                        {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                            : null}
                    </div>
                    <div className="col-8">

                        <Button label="Cancel" icon="pi pi-times" loading={this.state.btnloadingsave} style={{ float: "right", margin: "10px" }} onClick={this.onCancelClick} />
                        <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} style={{ float: "right", margin: "10px" }} onClick={this.onEndpointDetailsSaveClick} />


                    </div>
                </div>

                <Dialog header="Input Data" visible={this.state.showInputData} style={{ width: '53vw' }} onHide={() => this.onShowInputPopupClicked()} draggable={false}>
                    <>{
                        this.state.customHeaderloading ?
                            <div style={{ textAlign: "center" }}>
                                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                            :
                            <>
                                <div className="row p-2" >


                                    <div className='col-1 mb-2'>
                                        <label>Value</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-5 mb-2'>
                                        <input
                                            type="text"
                                            name="inputDataValue"
                                            className={this.state.errorInputDataValue.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                            value={this.state.inputDataValue}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorInputDataValue}</small>
                                    </div>


                                    <div className='col-1 mb-2'>
                                        <label>Header</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-5 mb-2'>
                                        <input
                                            type="text"
                                            name="inputDataHeader"
                                            className={this.state.errorinputDataHeader.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                            value={this.state.inputDataHeader}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorinputDataHeader}</small>
                                    </div>


                                    <div className='col-1'>
                                        <label>Scope</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-5'>
                                        <Dropdown value={this.state.inputDataScope}
                                            options={this.ScopeList}
                                            onChange={this.oninputDataScopeChange}
                                            optionLabel="name"
                                            placeholder="Select"
                                            style={{ width: "100%" }}
                                            className={this.state.errorinputDataScope.length > 0 ? "p-invalid p-mr-2" : ""}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorinputDataScope}</small>
                                    </div>
                                    <div className='col-6'>
                                        <Button label="ADD" loading={this.state.btninputdatasave} onClick={this.onAddInputDataClick} className="float-right" />
                                    </div>
                                    <div className='col-12 mt-2 text-center '>
                                        {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} />
                                            : null}
                                        {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} />
                                            : null}
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <DataTable value={this.state.endpointsInputData}
                                        rows={5} >
                                        <Column field="value" header="Value" ></Column>
                                        <Column field="header" header="Header" ></Column>
                                        <Column body={this.actionBodyTemplateInputDataScope} header="Scope" ></Column>
                                        <Column body={this.actionBodyTemplateInputData} header="Action"></Column>
                                    </DataTable>
                                </div>
                            </>
                    }
                    </>
                </Dialog>

                <Dialog header="Output Data" visible={this.state.showOutputData} style={{ width: '53vw' }} onHide={() => this.onShowOutputPopupClicked()} draggable={false}>
                    <>{
                        this.state.btnoutputdatasave ?
                            <div style={{ textAlign: "center" }}>
                                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                            :
                            <>
                                <div className="row p-2" >

                                    <div className='col-1'>
                                        <label>Value</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-5'>
                                        <input
                                            type="text"
                                            name="outputDataValue"
                                            className={this.state.errorOutputDataValue.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                            value={this.state.outputDataValue}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorOutputDataValue}</small>
                                    </div>

                                    <div className='col-1 mb-2'>
                                        <label>Header</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-5 mb-2'>
                                        <input
                                            type="text"
                                            name="outputDataHeader"
                                            className={this.state.errorOutputDataHeader.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                            value={this.state.outputDataHeader}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorOutputDataHeader}</small>
                                    </div>


                                    <div className='col-1'>
                                        <label>Scope</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-5'>
                                        <Dropdown value={this.state.outputDataScope}
                                            options={this.ScopeList}
                                            onChange={this.onOutputDataScopeChange}
                                            optionLabel="name"
                                            placeholder="Select"
                                            style={{ width: "100%" }}
                                            className={this.state.errorOutputDataScope.length > 0 ? "p-invalid p-mr-2" : ""}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorOutputDataScope}</small>
                                    </div>

                                    <div className='col-6'>
                                        <Button label="ADD" loading={this.state.addCustomHeaderloading} onClick={this.onAddOutputDataClick} className="float-right" />
                                    </div>
                                    <div className='col-12 mt-2 text-center '>
                                        {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} />
                                            : null}
                                        {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} />
                                            : null}
                                    </div>

                                </div>
                                <div className='mt-4'>
                                    {this.state.outputDataDetailsLoading ?
                                        <div style={{ textAlign: "center" }}>
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                        </div>
                                        :
                                        <DataTable value={this.state.endpointsOutputData}
                                            rows={5} >

                                            <Column field="value" header="Value" ></Column>
                                            <Column field="header" header="Header" ></Column>
                                            <Column body={this.actionBodyTemplateOutputDataScope} header="Scope" ></Column>
                                            <Column body={this.actionBodyTemplateOutputData} header="Action"></Column>
                                        </DataTable>
                                    }
                                </div>
                            </>
                    }
                    </>
                </Dialog>
            </>
        )
    }
}
