
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/css/adminlte.css';
import './assets/css/adminlte.min.css';
import './plugins/summernote/summernote-bs4.min.css';
import './plugins/daterangepicker/daterangepicker.css';
import './plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
import './plugins/jqvmap/jqvmap.min.css';
import './plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import './plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
import './plugins/fontawesome-free/css/all.min.css';
import './assets/css/customcss.css'

import CompanyMaster from './All Pages/CompanyMaster/CompanyMaster';
import Login from './All Pages/Account/Login';
import NewCompany from './All Pages/CompanyMaster/NewCompany';
import ServiceMaster from './All Pages/ServiceMaster/ServiceMaster';
import NewService from './All Pages/ServiceMaster/NewService';
import AdditionalDetails from './All Pages/ServiceMaster/AdditionalDetails';
import Report from './All Pages/Report/Report';
import UserMaster from './All Pages/UserMaster/UserMaster';
import NewUser from './All Pages/UserMaster/NewUser';
import WorkflowDashboard from './All Pages/WorkflowDashboard/WorkflowDashboard';
import WorkflowMaster from './All Pages/WorkflowMaster/WorkflowMaster';
import NewWorkflow from './All Pages/WorkflowMaster/NewWorkflow';
import PartnerReport from './All Pages/PartnerReport/PartnerReport';
import DatafabricMaster from './All Pages/DataFabric/DatafabricMaster';
import APIMaster from './All Pages/DataFabric/APIMaster';
import EventList from './All Pages/Events/EventList';
import NewEvent from './All Pages/Events/NewEvent';
import BlogList from './All Pages/Blogs/BlogList';
import BlogDetails from './All Pages/Blogs/BlogDetails';
import NewBlog from './All Pages/Blogs/NewBlog';
import RTGUserDetails from './All Pages/RTGUserMaster/RTGUserDetails';
import RTGUserMaster from './All Pages/RTGUserMaster/RTGUserMaster';
import RTGNewUser from './All Pages/RTGUserMaster/RTGNewUser';
import GIGWorkflowMaster from './All Pages/GIGWorkflow/GIGWorkflowMaster';
import GIGDetails from './All Pages/GIGWorkflow/GIGDetails';
// import GigMaster from './All Pages/Gigmarkets/GigMaster';
import GigCodeMaster from './All Pages/GigCodeMaster/GigCodeMaster';
import GigCodeDetails from './All Pages/GigCodeMaster/GigCodeDetails';





function App() {
  return (
    <div>
      <Router>
        <Switch>
          {/* <Route
            path="/igigadmin"
            component={Mainpage} exact
          ></Route> */}

          <Route
            path="/companylist"
            component={CompanyMaster} exact
          ></Route>

          <Route
            path="/"
            component={Login} exact
          ></Route>

          <Route
            path="/company/:index"
            component={NewCompany} exact
          ></Route>

          <Route
            path="/servicelist"
            component={ServiceMaster} exact
          ></Route>

          <Route
            path="/service/:index"
            component={NewService} exact
          ></Route>

          <Route
            path="/additionaldetails"
            component={AdditionalDetails} exact
          ></Route>


          {/* <Route
            path="/userlist"
            component={UserMaster} exact
          ></Route> */}

          <Route
            path="/workflowlist"
            component={WorkflowMaster} exact
          ></Route>

          <Route
            path="/workflow/:index"
            component={NewWorkflow} exact
          ></Route>

          <Route
            path="/report"
            component={Report} exact
          ></Route>

          <Route
            path="/partnerreport"
            component={PartnerReport} exact
          ></Route>

          <Route
            path="/workflowdashboard"
            component={WorkflowDashboard} exact
          ></Route>

          <Route
            path="/eventlist"
            component={EventList} exact
          ></Route>

          <Route
            path="/event/:index"
            component={NewEvent} exact
          ></Route>


          <Route
            path="/bloglist"
            component={BlogList} exact
          ></Route>


          <Route
            path="/blog/:index"
            component={NewBlog} exact
          ></Route>


          <Route
            path="/datafabric"
            component={DatafabricMaster} exact
          ></Route>

          <Route
            path="/datafabric/:index"
            component={APIMaster} exact
          ></Route>

          <Route
            path="/gigworkflow"
            component={GIGWorkflowMaster} exact
          ></Route>

          <Route
            path="/gigworkflow/:index"
            component={GIGDetails} exact
          ></Route>

          {/* <Route
            path="/user/:index"
            component={NewUser} exact
          ></Route> */}


          <Route
            path="/userlist"
            component={RTGUserMaster} exact
          ></Route>

          <Route
            path="/newuser"
            component={RTGNewUser} exact
          ></Route>

          <Route
            path="/user/:index"
            component={RTGUserDetails} exact
          ></Route>

                    <Route
            path="/gigcodemaster"
            component={GigCodeMaster} exact
          ></Route>

          <Route
            path="/gigcodedetails/:index"
            component={GigCodeDetails} exact
          ></Route>



        </Switch>
      </Router>
    </div>
  );
}



export default App;
