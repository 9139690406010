import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router';
import CommonValues from '../../Common/Util';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Editor } from 'primereact/editor';
class WorkflowDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            workflowId: 0,
            errorMessage: "",
            successMessage: "",
            sidebarShow: true,
            workflowName: "",
            workflowDescription: "",
            singleBundle: "",
            businessDriver: "",
            sizeOfOpportunity: "",
            threatsToAdoption: "",
            typeOfOpportunity: "",
            financialModel: "",
            estimatedBuyer: "",
            launchHours: "",
            triggerEvent: "",
            comment: "",
            tShirtSize: "",
            workflowTypeList: [],
            workflowType: {}
        }
        this.singleBundleList = [
            { "name": "Single" },
            { "name": "Bundled" }
        ]
        this.businessDriverList = [
            { "name": "Partner Size / Opportunity" },
            { "name": "Immediate Impact / Revenue" },
            { "name": "Repeatability / High Demand" }
        ]
        this.tShirtSizeList = [
            { "name": "Small" },
            { "name": "Medium" },
            { "name": "Large" }
        ]
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            workflowId: parseInt(params.index),
        });
        this.getAllWorkflowType();
    }
    getWorkflowById = (workflowId, searchText) => {
        this.setState({
            loading: true
        });
        let workflowIds = [];
        if (workflowId != 0) {
            workflowIds.push(workflowId)
        }
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "WorkflowId": workflowIds,
            "Name": searchText
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/list`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false,
                    workflowName: response.data[0].title,
                    workflowDescription: response.data[0].shortDescription,
                    financialModel: response.data[0].financialModel,
                    typeOfOpportunity: response.data[0].typeOfOpportunity,
                    threatsToAdoption: response.data[0].threatsToAddoption,
                    sizeOfOpportunity: response.data[0].sizeOfOpportunity,
                    estimatedBuyer: response.data[0].estimatedBuyer,
                    launchHours: response.data[0].launchHours,
                    costPerHour: response.data[0].costPerHour,
                    triggerEvent: response.data[0].triggerEvent,
                    comment: response.data[0].comments,
                    singleBundle: { "name": response.data[0].singleOrbundled },
                    businessDriver: { "name": response.data[0].businessDriver },
                    tShirtSize: { "name": response.data[0].tShirtSize }
                });

                this.state.workflowTypeList.forEach(workflowlist => {
                    if (workflowlist.name == response.data[0].workflowType) {
                        this.setState({
                            workflowType: workflowlist
                        })
                    }
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getAllWorkflowType = () => {
        this.setState({
            loading: true
        })
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/getallworkflowtype`,

        };

        axios(config)
            .then((response) => {
                this.setState({
                    workflowTypeList: response.data
                })
                if (this.state.workflowId != 0) {
                    this.getWorkflowById(this.state.workflowId, '');
                }
                else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });


    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onSingleBundleChange = (e) => {
        this.setState({
            singleBundle: e.value
        })
    }
    onBusinessDriverChange = (e) => {
        this.setState({
            businessDriver: e.value
        })
    }
    onTshirtSizeChange = (e) => {
        this.setState({
            tShirtSize: e.value
        })
    }
    isValid = () => {
        let valid = true;
        this.setState({
            inputTextErrorClass: false,
            errorMessageWorkflowname: "",
            errorMessageWorkflowType: ""
        })
        if (this.state.workflowName == "") {
            this.setState({
                inputTextErrorClass: true,
                errorMessageWorkflowname: "Please enter workflow name"
            })
            valid = false;
        }
        if (this.state.workflowType.id == undefined) {
            this.setState({
                inputTextErrorClass: true,
                errorMessageWorkflowType: "Please select workflow type"
            })
            valid = false;
        }

        return valid;
    }
    onWorkflowSaveClick = () => {
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true
            })
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Title": this.state.workflowName,
                "ShortDescription": this.state.workflowDescription,
                "Comments": this.state.comment,
                "WorkflowType": this.state.workflowType.id.toString(),
                "FinancialModel": this.state.financialModel,
                "TypeOfOpportunity": this.state.typeOfOpportunity,
                "SizeOfOpportunity": this.state.sizeOfOpportunity,
                "ThreatsToAddoption": this.state.threatsToAdoption,
                "EstimatedBuyer": parseInt(this.state.estimatedBuyer),
                "TShirtSize": this.state.tShirtSize.name,
                "LaunchHours": parseInt(this.state.launchHours),
                "CostPerHour": parseInt(this.state.costPerHour),
                "TriggerEvent": this.state.triggerEvent,
                "BusinessDriver": this.state.businessDriver.name,
                "SingleOrbundled": this.state.singleBundle.name
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/addworkflow`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                 
                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Workflow added successfully...!!!"
                    })
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    window.location = `/workflow/${response.data}`;
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    })
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "Workflow with the same name already exists."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                });
        }

    }
    onWorkflowUpdateClick = () => {
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true
            })
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "WorkflowId": this.state.workflowId,
                "Title": this.state.workflowName,
                "ShortDescription": this.state.workflowDescription,
                "Comments": this.state.comment,
                "WorkflowType": this.state.workflowType.id.toString(),
                "FinancialModel": this.state.financialModel,
                "TypeOfOpportunity": this.state.typeOfOpportunity,
                "SizeOfOpportunity": this.state.sizeOfOpportunity,
                "ThreatsToAddoption": this.state.threatsToAdoption,
                "EstimatedBuyer": parseInt(this.state.estimatedBuyer),
                "TShirtSize": this.state.tShirtSize.name,
                "LaunchHours": parseInt(this.state.launchHours),
                "CostPerHour": parseInt(this.state.costPerHour),
                "TriggerEvent": this.state.triggerEvent,
                "BusinessDriver": this.state.businessDriver.name,
                "SingleOrbundled": this.state.singleBundle.name
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/updateworkflow`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Workflow updated successfully...!!!"

                    })
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.getAllWorkflowType();

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    })
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "Workflow with the same name already exists."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                });
        }
    }
    onWorkflowTypeChange = (e) => {
        this.setState({
            workflowType: e.value
        });
    }
    render() {

        return (
            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Name</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <input
                                        type="text"
                                        name="workflowName"
                                        className={this.state.inputTextErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                        value={this.state.workflowName}
                                        onChange={this.onChange}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageWorkflowname}</small>
                                </div>
                            </div>

                            <div className="row p-2" >
                                {/* <div className="col-2">
                                    <label>Description</label>
                                </div>
                                <div className="col-4">
                                    <textarea type="text"
                                        id="workflowDescription"
                                        name="workflowDescription"
                                        value={this.state.workflowDescription}
                                        className="form-control"
                                        onChange={this.onChange}
                                    />
                                </div> */}
                                <div className="col-2">
                                    <label>Description</label>
                                </div>
                                <div className="col-10">
                                    <Editor style={{ height: '150px' }}
                                        value={this.state.workflowDescription}
                                        onTextChange={(e) => this.setState({ workflowDescription: e.htmlValue })}
                                    />
                                </div>


                            </div>

                            <div className="row p-2" >
                                <div className="col-2">
                                    <label>Single/Bundled</label>
                                </div>
                                <div className="col-4">
                                    <Dropdown value={this.state.singleBundle}
                                        options={this.singleBundleList}
                                        onChange={this.onSingleBundleChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }} />
                                </div>

                                <div className="col-2">
                                    <label>Workflow Type</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className="col-4">
                                    <Dropdown value={this.state.workflowType}
                                        options={this.state.workflowTypeList}
                                        onChange={this.onWorkflowTypeChange}
                                        optionLabel="name"
                                        placeholder="Select Workflow Type"
                                        style={{ width: "100%" }}
                                        className={this.state.inputTextErrorClass ? "p-invalid p-mr-2" : ""} />
                                    <small id="username-help" className="p-error">{this.state.errorMessageWorkflowType}</small>

                                </div>
                                {/* <div className="col-2">
                                    <label>Business Driver</label>
                                </div>
                                <div className="col-4">
                                    <Dropdown value={this.state.businessDriver}
                                        options={this.businessDriverList}
                                        onChange={this.onBusinessDriverChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }} />
                                </div> */}
                            </div>

                            {/* <div className="row p-2" >
                                <div className="col-2">
                                    <label>Financial Model</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name="financialModel"
                                        className="form-control"
                                        value={this.state.financialModel}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="col-2">
                                    <label>Type Of Opportunity</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name="typeOfOpportunity"
                                        className="form-control"
                                        value={this.state.typeOfOpportunity}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className="col-2">
                                    <label>Threats To Adoption</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name="threatsToAdoption"
                                        className="form-control"
                                        value={this.state.threatsToAdoption}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="col-2">
                                    <label>Size Of Opportunity</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name="sizeOfOpportunity"
                                        className="form-control"
                                        value={this.state.sizeOfOpportunity}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className="col-2">
                                    <label>Estimated Buyer</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="number"
                                        name="estimatedBuyer"
                                        className="form-control"
                                        value={this.state.estimatedBuyer}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="col-2">
                                    <label>T Shirt Size</label>
                                </div>
                                <div className="col-4">
                                    <Dropdown value={this.state.tShirtSize}
                                        options={this.tShirtSizeList}
                                        onChange={this.onTshirtSizeChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }} />
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className="col-2">
                                    <label>Launch Hours</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="number"
                                        name="launchHours"
                                        className="form-control"
                                        value={this.state.launchHours}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="col-2">
                                    <label>Cost Per Hour ($)</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="number"
                                        name="costPerHour"
                                        className="form-control"
                                        value={this.state.costPerHour}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className="col-2">
                                    <label>Trigger Event</label>
                                </div>
                                <div className="col-10">
                                    <input
                                        type="text"
                                        name="triggerEvent"
                                        className="form-control"
                                        value={this.state.triggerEvent}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div> */}

                            <div className="row p-2" >
                                <div className="col-2">
                                    <label>Comments </label>
                                </div>
                                <div className="col-10">
                                    <textarea
                                        type="text"
                                        name="comment"
                                        className="form-control"
                                        value={this.state.comment}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.workflowId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onWorkflowSaveClick} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onWorkflowUpdateClick} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>
                        </>
                }

            </>
        )
    }
}
export default withRouter(WorkflowDetails)
