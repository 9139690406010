import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
var axios = require('axios');

class Price extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceId: 0,
            loading: false,
            serviceName: "",
            unit: "",
            costInCent: "",
            costType: "",
            costInPercent: "",
            errorMessage: "",
            successMessage: "",
            pricePlanId:0
        }
        this.PricePlanCostType = [
            { name: 'Dollar Based', id: '1' },
            { name: 'Percentage Based', id: '2' }
        ];
    }
    // componentDidUpdate = (prevProps) => {

    //     if (prevProps.serviceId != this.props.serviceId) {

    //         this.getPriceDetails(this.props.serviceName)
    //     }
    // }
    componentDidMount = () => {
        this.setState({
            serviceId: this.props.serviceId,
        })
        if (this.props.serviceId != 0) {
            this.getPriceDetails(this.props.serviceId)
        }
    }
    checkValidation = () => {
        if (this.state.costType == "" || this.state.costType == null) {
            this.setState({
                inputTextCostTypeErrorClass: true,
                errorMessageCostType: "Please select cost type"
            });
            setTimeout(() => {
                this.setState({
                    inputTextCostTypeErrorClass: false,
                    errorMessageCostType: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.unit == "" || this.state.unit == null) {
            this.setState({
                inputTextUnitErrorClass: true,
                errorMessageUnit: "Please enter Unit"
            });
            setTimeout(() => {
                this.setState({
                    inputTextUnitErrorClass: false,
                    errorMessageUnit: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.costType.name == "Dollar Based") {
            if (this.state.costInCent == "" || this.state.costInCent == null) {
                this.setState({
                    inputTextCostIncentErrorClass: true,
                    errorMessageCostInCent: "Please enter cost in cent"
                });
                setTimeout(() => {
                    this.setState({
                        inputTextCostIncentErrorClass: false,
                        errorMessageCostInCent: "",
                    });
                }, 3000);

                return false;
            }
        }
        if (this.state.costType.name == "Percentage Based") {
            if (this.state.costInPercent == "" || this.state.costInPercent == null) {
                this.setState({
                    inputTextCostInPercentageErrorClass: true,
                    errorMessageCostInPercentage: "Please enter cost in percentage"
                });
                setTimeout(() => {
                    this.setState({
                        inputTextCostInPercentageErrorClass: false,
                        errorMessageCostInPercentage: "",
                    });
                }, 3000);

                return false;
            }
        }

        return true
    }
    onCostTypeChange = (e) => {
        this.setState({
            costType: e.value
        })
    }
    getPriceDetails = (id) => {
        this.setState({
            serviceId: id,
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "ServiceId":  parseInt(id)
        })     
        var config = {
            method: 'POST',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getservicepriceplan`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
              
                if(response.data.length>0)
                {
                    this.setState({
                        pricePlan: response.data,
                        pricePlanId:response.data[0].pricePlanId                     
    
                    });
                }
                this.setState({
                    loading: false,
                })


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="fa fa-trash " aria-hidden="true" onClick={this.onConfirmDeleteClick} style={{ cursor: "pointer" }}></i>
            </React.Fragment>
        );
    }
    onConfirmDeleteClick = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you really want to delete this price plan?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: this.onDeleteServicePricePlan,
            reject: this.reject
        });
    }
    onDeleteServicePricePlan = () => {
        // this.resetErrorMessage();

        this.setState({
            loading: true          
        });

        var token = CommonValues.GetToken();
        var data = JSON.stringify({
            "Name": this.props.serviceName,
            "ServiceId": parseInt(this.props.serviceId),
            "PricePlanId":this.state.pricePlanId
        })
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/deleteservicepriceplan`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    loading: false,
                    successMessage: "Priceplan deleted successfully.",
                    pricePlan: '',
                    pricePlanId:''     
                });
                
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getPriceDetails(this.props.serviceId)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false                  
                });

                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while deleting price plan";
                    }
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
                else {
                    errorMessage = "Unknown error while deleting price plan";
                }
                setTimeout(
                    () => this.setState({ errorMessage: "" }),
                    3000
                );

                this.setState({ errorMessage: errorMessage, successMessage: "" });


            });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onPriceSaveClick = () => {

        if (this.checkValidation()) {

            this.setState({
                btnloading: true
            });

            var token = CommonValues.GetToken();

            var data = JSON.stringify({
                "Name": this.props.serviceName,
                "CostType": this.state.costType.name,
                "Unit": this.state.unit,
                "CostInCents": this.state.costInCent,
                "CostPercentage": this.state.costInPercent,
                "ServiceId":  parseInt(this.props.serviceId)
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ServicesService_Url}/api/Services/addpriceplan`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    this.setState({
                        costType: "",
                        unit: "",
                        costInCent: "",
                        costInPercent: "",
                        btnloading: false,
                        successMessage: "Price plan saved sucessfully."
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.getPriceDetails(this.props.serviceId)

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false
                    });

                    let errorMessage = "";
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else {
                            this.setState({
                                errorMessage: "Unknown error while saving price plan"
                            });
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );

                        }
                    }
                    else {
                        this.setState({
                            errorMessage: "Unknown error while saving price plan"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );

                    }

                    this.setState({ errorMessage: errorMessage, successMessage: "" });

                });
        }

    }
    render() {
        
        return (
            <div>
               
                        <>
                            <div >
                                <label className="form-label">
                                    Service Name
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="serviceName"
                                    name="serviceName"
                                    value={this.props.serviceName}
                                    disabled={true}
                                    className={this.state.inputTextServiceErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageServicename}</small>
                            </div>
                            <div>
                                <label className="form-label">
                                    Select Cost Type
                                    <span style={{ color: "red" }}>*</span>
                                </label>

                                <Dropdown
                                    className={this.state.inputTextCostTypeErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.costType}
                                    options={this.PricePlanCostType}
                                    onChange={this.onCostTypeChange}
                                    optionLabel="name"
                                    placeholder="Select Cost Type"
                                    style={{ width: "100%" }}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageCostType}</small>
                            </div>
                            <div>
                                <label className="form-label">
                                    Unit
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="number"
                                    id="unit"
                                    name="unit"
                                    value={this.state.unit}
                                    className={this.state.inputTextUnitErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageUnit}</small>
                            </div>
                            {
                                this.state.costType.name == "Dollar Based" ?
                                    <div>
                                        <label className="form-label">
                                            Cost In Cent
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input type="number"
                                            id="costInCent"
                                            name="costInCent"
                                            value={this.state.costInCent}
                                            className={this.state.inputTextCostIncentErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorMessageCostInCent}</small>
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.costType.name == "Percentage Based" ?
                                    <div>
                                        <label className="form-label">
                                            Cost Percentage
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input type="number"
                                            id="costInPercent"
                                            name="costInPercent"
                                            value={this.state.costInPercent}
                                            className={this.state.inputTextCostInPercentageErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorMessageCostInPercentage}</small>
                                    </div>
                                    :
                                    null
                            }
                            <br />
                            {
                                this.state.loading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    :
                                    <div className="form-row">
                                        {
                                            this.state.pricePlan != null && this.state.pricePlan.length > 0 ?
                                                <div className="col-12" >
                                                    <DataTable value={this.state.pricePlan} paginator
                                                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                                        rows={5} responsiveLayout="scroll" >
                                                        <Column field="name" header=" Name"></Column>
                                                        <Column field="costType" header="Cost Type" ></Column>
                                                        <Column field="unit" header="Unit" ></Column>
                                                        <Column field="costInCents" header="Cost In Cent" ></Column>
                                                        <Column field="costPercentage" header="Cost Percentage" ></Column>
                                                        <Column body={this.actionBodyTemplate} header="Action" ></Column>
                                                    </DataTable>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                            }
                            <br />
                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">
                                    {/* <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onPriceSaveClick} style={{ float: "right" }} /> */}

                                    {
                                        this.state.serviceId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onPriceSaveClick} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onPriceSaveClick} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>

                        </>
               

            </div>
        );
    }
}
export default Price;
