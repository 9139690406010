import React, { Component } from 'react';
import Sidebar from '../../Common/Sidebar';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import CompanyDetails from './CompanyDetails';
import Contact from './Contact';
import Documentation from './Documentation';
import AdditionalCompanyInfo from './AdditionalCompanyInfo';
import { withRouter } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import CommonValues from '../../Common/Util';
import queryString from 'query-string';
import Agreement from './Agreement';

class NewCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageText: '',
            sidebarShow: true,
            innerWidth: 1024,
            companyId: 0,
            companyName: '',
            contactRefresh: true
        }
        this.template = this.template.bind(this);
    }





    setCompanyId = (id) => {
        this.setState({
            companyId: id
        });
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    checkAndApplySideBar = (innerWidth) => {

        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }



    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    componentDidMount = () => {

        let qparams = queryString.parse(this.props.location.search)
        //console.log(qparams);
        const {
            match: { params },
        } = this.props;
        this.setState({
            companyId: params.index,
        });

        if (params.index == 0) {
            this.setState({ pageText: 'Add New Company' })
        } else {
            this.setState({ pageText: 'Edit Company' })
        }
        if (qparams != null && qparams != undefined && qparams.name != null && qparams.name != undefined) {
            this.setState({
                companyName: qparams.name,
            });
        }
    }
    template(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Company Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    template1(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Point Of Contact
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    template2(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Documentation
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    template3(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Additional Company Information
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    template4(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Agreements and Legal Documents
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    onContactAdded = () => {
        let contactRefresh = this.state.contactRefresh;
        contactRefresh = !contactRefresh;
        this.setState({ contactRefresh: contactRefresh });
    }
    render() {

        const token = CommonValues.GetToken();
        if (token == null || token == '') {
            CommonValues.ClearValues();
            return <Redirect to="/" />
        }
        return (

            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item hamburgericon">
                                <a className="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i className="fas fa-bars"></i></a>
                            </li>

                        </ul>



                        <ul className="navbar-nav ml-auto">

                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-widget="control-sidebar"
                                    data-slide="true"
                                    href="/"
                                    role="button"
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <Sidebar></Sidebar>

                    <div className="content-wrapper">

                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>{this.state.pageText}</h1>
                                    </div>

                                </div>
                            </div>

                        </section>

                        <section className="content">
                            <div className="container-fluid">


                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">

                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">
                                                    <Panel headerTemplate={this.template} toggleable>
                                                        <CompanyDetails
                                                            companyId={this.state.companyId} companyName={this.state.companyName}
                                                            setCompanyId={this.setCompanyId}></CompanyDetails>
                                                    </Panel><br />

                                                    <Panel headerTemplate={this.template1} toggleable collapsed>
                                                        {
                                                            this.state.companyId != 0 ?
                                                                <Contact companyId={this.state.companyId} onContactAdded={this.onContactAdded}></Contact>
                                                                :
                                                                null
                                                        }
                                                    </Panel><br />

                                                    <Panel headerTemplate={this.template2} toggleable collapsed>
                                                        {
                                                            this.state.companyId != 0 ?
                                                                <Documentation
                                                                    companyId={this.state.companyId}
                                                                ></Documentation>
                                                                :
                                                                null
                                                        }
                                                    </Panel><br />

                                                    <Panel headerTemplate={this.template3} toggleable collapsed>
                                                        {
                                                            this.state.companyId != 0 ?
                                                                <AdditionalCompanyInfo companyId={this.state.companyId}></AdditionalCompanyInfo>
                                                                :
                                                                null
                                                        }
                                                    </Panel> <br />

                                                    <Panel headerTemplate={this.template4} toggleable collapsed>
                                                        {
                                                            this.state.companyId != 0 ?
                                                                <Agreement contactRefresh={this.state.contactRefresh} companyId={this.state.companyId}></Agreement>
                                                                :
                                                                null
                                                        }
                                                    </Panel>
                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </section>

                    </div>

                    <footer className="main-footer">

                        <strong>
                            Copyright © 2021 <a href="https://adminlte.io">InsuranceGIG</a>.
                        </strong>{" "}
                        All rights reserved.
                    </footer>

                    <aside className="control-sidebar control-sidebar-dark">

                    </aside>

                </div>
            </div>
        )
    }
}
export default withRouter(NewCompany);
