import React, { Component } from 'react'
import Footer from '../../Common/Footer';
import TopNavBar from '../../Common/TopNavBar';
import Sidebar from '../../Common/Sidebar';
import CommonValues from '../../Common/Util';
import PageHeader from '../../Common/PageHeader';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

export default class GigCodeMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: true,
            loading: true,
            innerWidth: 1024,
            pfsearch: "",
            filteredCoupon: null,
            pfcoupounlist: []

        }

    }
    componentDidMount = () => {

        window.addEventListener("resize", this.updateWindowSize);
        this.updateWindowSize();
        this.getAllCoupounDetails("");

    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    checkAndApplySideBar = (innerWidth) => {

        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    editCoupounDetails = (id) => {

        this.props.history.push(`/gigcodedetails/${id}`)

    }
    actionBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.editCoupounDetails(rowData.id)} />
                &emsp;
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => this.onConfrimDeleteCoupounDetails(rowData.id)} />
            </React.Fragment>
        );
    }
    thumbnailImageTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.fileUrl ? (
                    <img
                        src={rowData.fileUrl}
                        width="70"
                        height="70"
                        alt="No Image uploaded"
                        onError={(e) => {
                            e.target.style.display = 'none';
                        }}
                    />
                ) : (
                    <span>No Image uploaded</span>
                )}
            </React.Fragment>
        );
    }
    onConfrimDeleteCoupounDetails = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete the GigCode details?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.deleteCoupounDetails(id),
            reject: this.reject,
            resizable:false,
            draggable:false

        });
    }
    deleteCoupounDetails = (id) => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/deletecoupon?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.toast.show({
                    severity: 'success',
                    detail: 'GigCode deleted Succesfully',
                    life: 2000
                });
                this.getAllCoupounDetails("");

            })
            .catch((error) => {
                console.log(error);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }

            });

    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    onNewBtnClicked = () => {
        this.props.history.push(`/gigcodedetails/0`);
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    getAllCoupounDetails = (pfsearch) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/coupondetails?CouponCode=${this.state.pfsearch}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    pfcoupounlist: response.data,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }

            });
    }

    onSearchClick = () => {
        if (this.state.pfsearch.trim() != '') {
            this.getAllCoupounDetails(this.state.pfsearch);
        }
    }

    searchCoupon = (event) => {
        setTimeout(() => {
            let filteredCoupon;
            if (!event.query.trim().length) {
                filteredCoupon = [...this.state.pfcoupounlist];
            }
            else {
                filteredCoupon = this.state.pfcoupounlist.filter((item) => {
                    return item.couponCode.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({ filteredCoupon: filteredCoupon });
        }, 250);

    };

    onRefreshClick = () => {
        this.getAllCoupounDetails("");
    }

    onKeyClick = (e) => {
        if (e.key === "Enter") {
            this.searchCoupon()
        }
    }
    onCouponSelect = (e) => {

        if (e.value.id != 0) {
            this.editCoupounDetails(e.value.id)
        }

    }
    render() {
        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.Logout();
        }
        return (
            <>

                <div onClick={this.onBodyClick}>
                    <Toast ref={(el) => this.toast = el} />
                    <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                        <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                        <Sidebar></Sidebar>
                        <div className="content-wrapper">
                            <PageHeader PageTitle="GigCode Master"></PageHeader>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                                                            <div
                                                                className="input-group input-group-sm"

                                                            >
                                                                <AutoComplete onSelect={this.onCouponSelect} value={this.state.pfsearch} completeMethod={this.searchCoupon} field="couponCode" suggestions={this.state.filteredCoupon} onKeyDown={this.onKeyClick} onChange={(e) => this.setState({ pfsearch: e.value })} />


                                                                <div className="input-group-append company-search-btn">
                                                                    <button type="submit" className="btn btn-default" onClick={this.onSearchClick}>
                                                                        <i className="fas fa-search" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 servicemain">
                                                            <Button label="Refresh" className='p-button-sm' icon="pi pi-sync" onClick={this.onRefreshClick} />
                                                            &nbsp;
                                                            <Button label="New" className='p-button-sm' icon="pi pi-plus" onClick={this.onNewBtnClicked} />
                                                        </div>

                                                    </div>

                                                    <div className="card-tools">

                                                    </div>

                                                </div>
                                                {
                                                    this.state.loading ?
                                                        <>
                                                            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                                            <div style={{ height: "600px" }}></div>
                                                        </>
                                                        :
                                                        <div className="card-body table-responsive p-0">
                                                            <div className="d-none d-md-block">
                                                                <DataTable value={this.state.pfcoupounlist} paginator
                                                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                    rows={7} responsiveLayout="scroll"
                                                                    className="justify-content-end">
                                                                    <Column field='couponCode' header="GigCode" sortable ></Column>
                                                                    <Column field="isActive" header="Active" sortable></Column>
                                                                    <Column field="pfPartnerId" header="PF Partner Companies" sortable ></Column>
                                                                    <Column body={this.thumbnailImageTemplate} header="Logo"  ></Column>
                                                                    <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                                </DataTable>
                                                            </div>
                                                            <div className="d-block d-md-none">
                                                                <DataTable value={this.state.pfcoupounlist} paginator
                                                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                    rows={7} responsiveLayout="scroll">

                                                                    <Column field="couponCode" header="GigCode" sortable></Column>
                                                                    <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                                </DataTable>
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <Footer></Footer>
                    </div>
                </div>
            </>
        )
    }
}
