import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import Sidebar from '../../Common/Sidebar';
import UserDetails from '../UserMaster/UserDetails';
import { Ripple } from 'primereact/ripple';
import { AutoComplete } from 'primereact/autocomplete';
import CommonValues from '../../Common/Util';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
var axios = require('axios');

export default class RTGUserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            userId: 0,
            innerWidth: 1024,
            companyListOption: [],
            companyListId: [],
            companyName: "",
            companyNameId: "",
            companyNameTest: "",
            serviceType: "",
            filteredCompany: null,
            serviceName: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            email: "",
            activeUser: false,
            supportUser: false,
            gigMarketUser: false,
            allowUserTotakeControl: false,
            companySettings: "",
            typeOfWidget: "",
            widgetTypeList: [],
            widgetTypeListForSearch: [],
            selectedWidgetType: [],
            SelectedwidgetTypeList: [],
            typeOfWidget: "",
            widgetTypeIds: [],
            searchText: "",
            loading: false,
            loadingWidget: false,
            loadingSave: false,
            companyNameToShow: "",
            widgetDetails: "",
            loadingWidget: false,
            loadingUserInfo: false,
            isAssigned: false,
            description: "",
            loadingUpdate: false,
            selectAllActive: false,
            showUndoChanges: false,
            isUserApproved: false,
            isUserActive: false,
            userRole: [],
            selectedUserRole: null,


            /*SELECTED SERVICES*/
            _serviceList: [],
            assignedServices: [],
            selectedServices: [],

            filters1: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'representative': { value: null, matchMode: FilterMatchMode.IN },
                'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
            }
        };

        this.userRoleList = [
            { name: 'IG Admin', id: '1' },
            { name: 'Company Admin', id: '2' },
            { name: 'User', id: '3' }
        ];
    }

    onUserRoleChange = (e) => {
        this.setState({
            selectedUserRole: e.value
        });
    }
    componentDidMount = () => {
        this.getAllCompanyList('');
        const {
            match: { params },
        } = this.props;
        this.setState({
            userId: params.index,
        });
        this.getUserDetailsById(params.index);
    }

    getAllWidgetList = (searchText) => {
        var token = CommonValues.GetToken();
        var axios = require('axios');
        this.setState({
            loadingWidget: true
        });

        var config = {
            method: 'get',
            //url: `https://localhost:44394/api/user/rtgwidget?searchText=${this.state.searchText}`,
            url: `${process.env.REACT_APP_UserService_Url}/api/user/rtgwidget?searchText=${this.state.searchText}`,
            //url: `${process.env.REACT_APP_UserService_Url}/api/user/rtgwidget?searchText=${""}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    widgetTypeList: response.data,
                    searchText: ""
                });


                this.setState({
                    loadingWidget: false
                });
                this.getUserWidgetsByID(this.state.userId);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingWidget: false,
                    searchText: ""
                });


                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                } else {
                    this.setState({
                        errorMessage: "Oops... something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }

    getUserWidgetsByID = (userId) => {
        var token = CommonValues.GetToken();
        var axios = require('axios');

        this.setState({ loadingWidget: true });

        var config = {
            method: 'get',
            //url: `https://localhost:44394/api/user/getrtguserwidgettoview?userId=${userId}`,
            url: `${process.env.REACT_APP_UserService_Url}/api/user/getrtguserwidgettoview?userId=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    _serviceList: response.data,
                    selectedWidgetType: response.data,
                    assignedServices: response.data,
                    selectedServices: response.data,
                    loadingWidget: false
                });

                let widgetType = [];
                let selectedList = [];
                if (response.data.toString().includes(",")) {
                    selectedList = response.data.split(",");
                } else {
                    selectedList.push(response.data);
                }


                if (response.data != null) {
                    this.state.widgetTypeList.forEach(widgetTypeId => {
                        let isInserted = false;
                        selectedList.forEach(item => {
                            if (item == widgetTypeId.serviceId) {
                                isInserted = true;
                            }
                        });
                        widgetType.push({
                            "name": widgetTypeId.name,
                            "isAssigned": isInserted ? true : false,
                            "serviceId": widgetTypeId.serviceId,
                            "widgetId": widgetTypeId.widgetId,
                            "description": widgetTypeId.description,
                            "providerCompanyName": widgetTypeId.providerCompanyName
                        });
                    });
                }
                this.setState({
                    widgetTypeList: widgetType,
                    widgetTypeListForSearch: widgetType
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingWidget: false
                });
            });
    }

    onWidgetTypeChange = (e) => {
        this.setState({
            typeOfWidget: e.value,
            widgetTypeIds: e.value.map(element => element.id + ',' + element.version)
        });
    }

    onUpdateClick = () => {
        this.setState({
            loadingUpdate: true
        });

        var token = CommonValues.GetToken();
        let list = [];
        this.state.widgetTypeList.forEach(item => {
            if (item.isAssigned == true) {
                list.push({
                    "serviceId": item.serviceId
                })
            }
        })
        const stateValueWidgets =
            list.map((element) => {
                return element.serviceId;
            }).join(',');
        var axios = require('axios');
        var data = JSON.stringify({
            "FirstName": this.state.firstName,
            "Lastname": this.state.lastName,
            "CompanyName": this.state.companyName.name,
            "FullName": "",
            "Password": "",
            "Email": this.state.email,
            "Active": this.state.isUserActive,
            "IsSupportUser": false,
            "AllowIGSupportUserToTakeControl": false,
            "CompanySettingsId": "",
            "SlugURL": "",
            "HasGIGMarket": this.state.gigMarketUser ? 1 : 0,
            "WidgetSelected": stateValueWidgets,
            "OldWidgets": this.state._serviceList.toString(),
            "UserId": this.state.userId,
            "UserRoleId": this.state.selectedUserRole.id
        });
        var config = {
            method: 'post',
            //url: 'https://localhost:44394/api/user/updategigmarketuser',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/updategigmarketuser`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                this.setState({
                    loadingUpdate: false,
                    companyName: "",
                    typeOfWidget: "",
                    firstName: "",
                    lastName: "",
                    email: ""
                })

                this.toast.show({
                    severity: 'success',
                    summary: 'Success message ',
                    detail: 'Changes saved successfully.',
                    life: 2000
                });
                this.getUserDetailsById(this.state.userId);
                this.getAllWidgetList();
                this.clearFilter('filters1');
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loadingUpdate: false })

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.toast.show({
                            severity: 'error',
                            summary: 'Error message ',
                            detail: 'Opp`s something went wrong',
                            life: 2000
                        });
                    }
                }
            });

    }

    getUserDetailsById = (userId) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/getrtguser?userId=${userId}`,
            //url: `https://localhost:44394/api/user/getrtguser?userId=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                // this.state.companyListOption.forEach(element => {
                //     if (element.companyId == response.data.companyId)
                //         this.setState({
                //             companyName: element
                //         });
                // });
              
                this.setState({
                    userDetails: response.data,
                    gigMarketUser: response.data.hasGIGMarket,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    workEmail: response.data.workEmail,
                    isUserApproved: response.data.approved,
                    isUserActive: response.data.activeUser,
                    companyNameToShow: response.data.companyName,
                    userRole: response.data.roleId,
                    loading: false
                });

                if (response.data.roleId != null) {
                    let roles = response.data.roleId;
                    this.userRoleList.forEach(prevRole => {
                        if (prevRole.id == roles) {
                            this.setState({ selectedUserRole: prevRole });
                        }
                    });
                };
                this.getAllWidgetList('');
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Oops... something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }

    getAllCompanyList = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/rtgcompany?searchText=`,
            //url: 'https://testapi.insurancegig.com/companyapidev/api/company/company?searchText=',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyListOption: response.data,

                });
                if (this.props.serviceId != 0) {
                    //this.getOverviewDetails(this.props.serviceId);
                }
                else {
                    this.setState({
                        loading: false
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Oops... something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    checkValidation = () => {
        if (this.state.companyName == "" || this.state.companyName == null) {
            this.setState({
                inputTextCompanyNameErrorClass: true,
                errorMessageCompanyname: "Please select company name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextCompanyNameErrorClass: false,
                    errorMessageCompanyname: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.typeOfWidget == "" || this.state.typeOfWidget == null) {
            this.setState({
                inputTextWidgetNameErrorClass: true,
                errorMessageWidgetName: "Please select widget name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextWidgetNameErrorClass: false,
                    errorMessageWidgetName: "",
                });
            }, 3000);

            return false;
        }


        if (this.state.firstName == "" || this.state.firstName == null) {
            this.setState({
                inputTextFirstNameErrorClass: true,
                errorMessageFirstName: "Please enter first name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextFirstNameErrorClass: false,
                    errorMessageFirstName: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.lastName == "" || this.state.lastName == null) {
            this.setState({
                inputTextLastNameErrorClass: true,
                errorMessageLastName: "Please enter last name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextLastNameErrorClass: false,
                    errorMessageLastName: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.email == "" || this.state.email == null) {
            this.setState({
                inputTextEmailErrorClass: true,
                errorMessageEmail: "Please enter email"
            });
            setTimeout(() => {
                this.setState({
                    inputTextEmailErrorClass: false,
                    errorMessageEmail: "",
                });
            }, 3000);

            return false;
        }

        // if (this.state.serviceType == "" || this.state.serviceType == null) {
        //     this.setState({
        //         inputTextServiceTypeErrorClass: true,
        //         errorMessageServiceType: "Please select service type"
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             inputTextServiceTypeErrorClass: false,
        //             errorMessageServiceType: "",
        //         });
        //     }, 3000);

        //     return false;
        // }
        // if (this.state.tagline == "" || this.state.tagline == null) {
        //     this.setState({
        //         inputTextTagErrorClass: true,
        //         errorMessageTagline: "Please enter tagline"
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             inputTextTagErrorClass: false,
        //             errorMessageTagline: "",
        //         });
        //     }, 3000);

        //     return false;
        // }

        // if (this.state.description == "" || this.state.description == null) {
        //     this.setState({
        //         inputTextDescErrorClass: true,
        //         errorMessageDesc: "Please enter description"
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             inputTextDescErrorClass: false,
        //             errorMessageDesc: "",
        //         });
        //     }, 3000);

        //     return false;
        // }

        return true;
    }

    onSearchClick = () => {
        if (this.state.searchText == "") {
            this.setState({ widgetTypeList: this.state.widgetTypeListForSearch });
        }

        else {
            let ST = this.state.searchText;
            const dataToDisplay = [];
            this.state.widgetTypeListForSearch.forEach(element => {
                if ((element.name != null && element.name.toLowerCase().includes(ST.toLowerCase())) ||
                    (element.providerCompanyName != null && element.providerCompanyName.toLowerCase().includes(ST.toLowerCase()))) {

                    dataToDisplay.push(element);
                }
            });
            this.setState({ widgetTypeList: dataToDisplay });
        }
    }

    onKeyDownForSearch = (e) => {
        if (e.keyCode == 13) {
            this.getAllWidgetList(this.state.searchText);
        }
    };

    onCompanyNameChange = (e) => {
        this.setState({
            companyName: e.value,
        })
    }

    itemTemplate = (item) => {
        return (
            <div className="company-item">
                <div>{item.name}</div>
            </div>
        );
    }

    searchCountry = (event) => {
        setTimeout(() => {
            let filteredCompany;
            if (!event.query.trim().length) {
                filteredCompany = [...this.state.companyListOption];
            }
            else {
                filteredCompany = this.state.companyListOption.filter((company) => {
                    return company.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({ filteredCompany });
        }, 250);
    }

    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }

    actionBodyTemplateForIsAssigned = (service) => {
        return (
            <React.Fragment>
                <InputSwitch checked={service.isAssigned} onChange={(e) => this.onIsAssignedChange(service, e)} />
            </React.Fragment>
        );
    }

    onIsAssignedChange = (service, e) => {
        // fix this (9 - 20 - 2022)
        let list = [];
        //let selectedList = this.state.assignedServices.split(',').map(Number);
        //let isInList = selectedList.includes(service.serviceId);


        //console.log("Selected list: ", selectedList);
        this.state.widgetTypeList.forEach(item => {
            if (item.serviceId == service.serviceId) {
                list.push({
                    "name": item.name,
                    "isAssigned": e.value,
                    "widgetId": item.widgetId,
                    "description": item.description,
                    "serviceId": item.serviceId,
                    "providerCompanyName": item.providerCompanyName
                });
            }
            else {
                list.push(item)
            }
        })
        this.setState({
            widgetTypeList: list,
            typeOfWidget: e.value,
            showUndoChanges: true
        });
        //console.log("Service", service.name,"has been", service.isAssigned ? 'disabled' : 'enabled', ". Current active service list: ", this.state.assignedServices);
    }

    renderHeader(filtersKey) {
        return (
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center mr-3">
                    <InputSwitch checked={this.state.selectAllActive} onChange={(e) => { this.onSelectAllChange(e.target.value) }} className="mr-2" />
                    <span>Select all</span>
                </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Search widget" />
                </span>
                {/* {this.state.showUndoChanges &&
                <div className="ml-3">
                    <Button onClick={() => {this.onUndoChangesClick()}} label="Undo Changes" icon="pi pi-times" className="p-button-rounded p-button-danger"></Button>
                </div>} */}
            </div>
        );
    }

    onSelectAllChange = (select) => {
        this.setState({ selectAllActive: !this.state.selectAllActive, showUndoChanges: true });
        let arr = [];

        this.state.widgetTypeList.map(item => {
            arr.push({
                "name": item.name,
                "isAssigned": select,
                "widgetId": item.widgetId,
                "description": item.description,
                "serviceId": item.serviceId,
                "providerCompanyName": item.providerCompanyName
            });
        });

        this.setState({
            widgetTypeList: arr,
            widgetTypeListForSearch: arr
        })
    }

    onUndoChangesClick = () => {
        let arr = [];
        let originalList = this.state._serviceList.split(',').map(Number);
        this.state.widgetTypeList.map(item => {
            let isInserted = false;
            originalList.map(i => {

                if (i == item.serviceId) {
                    isInserted = true;
                }
            });
            arr.push({
                "name": item.name,
                "isAssigned": isInserted ? true : false,
                "widgetId": item.widgetId,
                "description": item.description,
                "serviceId": item.serviceId,
                "providerCompanyName": item.providerCompanyName
            });
        })
        this.setState({
            //assignedServices: selectedList,
            widgetTypeList: arr,
            widgetTypeListForSearch: arr,
            selectAllActive: false,
            showUndoChanges: false
        });
    }

    clearFilter(filtersKey) {
        this.state[`${filtersKey}`]['global'].value = ""
    }
    onGlobalFilterChange(event, filtersKey) {
        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    onGigMarketChange = (e) => {
        this.setState({
            gigMarketUser: e.value
        });
        if (e.value) {
            this.onUndoChangesClick();
        }
        if (!e.value) {
            let arr = [];
            this.state.widgetTypeList.map(item => {
                arr.push({
                    "name": item.name,
                    "isAssigned": false,
                    "widgetId": item.widgetId,
                    "description": item.description,
                    "serviceId": item.serviceId,
                    "providerCompanyName": item.providerCompanyName
                });
            })
            this.setState({
                widgetTypeList: arr,
                widgetTypeListForSearch: arr,
            });
        }
    }

    render() {
        const header1 = this.renderHeader('filters1');
        let userRole = "User";
        if (this.state.userRole != "" || this.state.userRole != null) {

            this.userRoleList.forEach(role => {
                if (role.id == this.state.userRole) {
                    userRole = role.name;
                }
            });
        }
        return (
            <div onClick={this.onBodyClick}>
                <Toast ref={(el) => this.toast = el} />
                <div className="wrapper" class={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i className="fas fa-bars"></i></a>
                            </li>

                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-widget="control-sidebar"
                                    data-slide="true"
                                    href="/"
                                    role="button"
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>User Information</h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">
                                                    {
                                                        this.state.loading ?
                                                            <div style={{ textAlign: "center" }}>
                                                                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                            </div>
                                                            :
                                                            <div>
                                                                <Panel headerTemplate={this.userTemplate} toggleable>
                                                                    {/* <label className="form-label d-block">
        Company Name
        <span style={{ color: "red" }}>*</span>
    </label>
    {
        this.state.loading ?

            <ProgressSpinner className="d-flex align-items-center justify-content-start" style={{ width: '40px', height: '40px', margin: 'inherit' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
            :
            <div >
                <AutoComplete
                    className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2 w-100" : "w-100"}

                    autocomplete
                    value={this.state.companyName}
                    suggestions={this.state.filteredCompany}
                    completeMethod={this.searchCountry}
                    field="name" dropdown forceSelection
                    onChange={this.onCompanyNameChange}
                    placeholder="Select Company Name"
                />
                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
            </div>
    } */}

                                                                    <div >
                                                                        <label className="form-label">
                                                                            Company Name
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <input type="text"
                                                                            id="firstName"
                                                                            name="firstName"
                                                                            value={this.state.companyNameToShow}
                                                                            className={this.state.inputTextFirstNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                            onChange={this.onChange}
                                                                            // placeholder="Enter First Name"
                                                                            disabled={true}
                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageFirstName}</small>
                                                                    </div>

                                                                    {/* <label className="form-label">
                                                                        Widget Name
                                                                    </label><span style={{ color: "red" }}>*</span>
                                                                    {
                                                                        this.state.loadingWidget ?
                                                                            <ProgressSpinner className="d-flex align-items-center justify-content-start" style={{ width: '40px', height: '40px', margin: 'inherit' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                            :
                                                                            <div >
                                                                                <MultiSelect value={this.state.typeOfWidget}
                                                                                    className={this.state.inputTextWidgetNameErrorClass ? " p-invalid p-mr-2 w-100 multiselectnew" : "w-100 multiselectnew"}
                                                                                    options={this.state.widgetTypeList}
                                                                                    onChange={this.onWidgetTypeChange}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select a Widget Name"
                                                                                    maxSelectedLabels={5}
                                                                                    style={{ width: "100%" }}
                                                                                // className="multiselectnew"
                                                                                />
                                                                                <small id="username-help" className="p-error">{this.state.errorMessageWidgetName}</small>
                                                                            </div>
                                                                    } */}

                                                                    {/* <div >
                                                                        <label className="form-label">
                                                                            Widget Name
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        {
                                                                            this.state.loadingWidget ?
                                                                                <div >
                                                                                    <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <textarea type="text"
                                                                                        id="firstName"
                                                                                        name="firstName"
                                                                                        value={this.state.widgetDetails}
                                                                                        className={this.state.inputTextFirstNameErrorClass ? "form-control p-invalid p-mr-2 rtgTextArea" : " rtgTextArea form-control"}
                                                                                        onChange={this.onChange}
                                                                                        // placeholder="Enter First Name"
                                                                                        disabled={true}
                                                                                    />
                                                                                    <small id="username-help" className="p-error">{this.state.errorMessageFirstName}</small>
                                                                                </div>
                                                                        }
                                                                    </div> */}


                                                                    <div >
                                                                        <label className="form-label">
                                                                            First Name
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <input type="text"
                                                                            id="firstName"
                                                                            name="firstName"
                                                                            value={this.state.firstName}
                                                                            className={this.state.inputTextFirstNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                            onChange={this.onChange}
                                                                            // placeholder="Enter First Name"
                                                                            disabled={true}
                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageFirstName}</small>
                                                                    </div>
                                                                    <div >
                                                                        <label className="form-label">
                                                                            Last Name
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <input type="text"
                                                                            id="lastName"
                                                                            name="lastName"
                                                                            value={this.state.lastName}
                                                                            className={this.state.inputTextLastNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                            onChange={this.onChange}
                                                                            // placeholder="Enter Last Name"
                                                                            disabled={true}
                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageLastName}</small>
                                                                    </div>
                                                                    <div >
                                                                        <label className="form-label">
                                                                            Email
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <input type="text"
                                                                            id="email"
                                                                            name="email"
                                                                            value={this.state.workEmail}
                                                                            className={this.state.inputTextEmailErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                            onChange={this.onChange}
                                                                            // placeholder="Enter Email"
                                                                            disabled={true}
                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageEmail}</small>
                                                                    </div>

                                                                    <div >
                                                                        <label className="form-label">
                                                                            User Role
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <Dropdown
                                                                            className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2" : ""}
                                                                            value={this.state.selectedUserRole}
                                                                            options={this.userRoleList}
                                                                            onChange={this.onUserRoleChange}
                                                                            optionLabel="name"
                                                                            placeholder={userRole}
                                                                            style={{ width: "100%" }}
                                                                        //disabled={true}
                                                                        />

                                                                        <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                                                                    </div>

                                                                    <div >
                                                                        <label className="form-label ">
                                                                            Approved User
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <InputSwitch
                                                                            className="ml-2 inputswitch-css"
                                                                            checked={this.state.isUserApproved}
                                                                            onChange={(e) => this.setState({ isUserApproved: e.value })}
                                                                            disabled={true}
                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                                                                    </div>
                                                                    <div  >
                                                                        <label className="form-label">
                                                                            Active User
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <InputSwitch
                                                                            className="ml-2 inputswitch-css"
                                                                            checked={this.state.isUserActive}
                                                                            onChange={(e) => this.setState({ isUserActive: e.value })}
                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                                                                    </div>
                                                                    <div>
                                                                        <label className="form-label">
                                                                            GIGMarket User
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <InputSwitch
                                                                            className="ml-2 inputswitch-css"
                                                                            checked={this.state.gigMarketUser}
                                                                            onChange={(e) => { this.onGigMarketChange(e.target) }}
                                                                        //disabled={true}
                                                                        />

                                                                        <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>

                                                                    </div>

                                                                    {this.state.gigMarketUser &&
                                                                        <div>
                                                                            <label className="form-label">
                                                                                Widget Name
                                                                            </label><span style={{ color: "red" }}></span>
                                                                            <div className="card-body table-responsive p-0">
                                                                                <div className="d-none d-md-block">
                                                                                    <DataTable value={this.state.widgetTypeList}
                                                                                        filters={this.state.filters1}
                                                                                        paginator
                                                                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                                        rows={10}
                                                                                        header={header1}

                                                                                        responsiveLayout="scroll">

                                                                                        <Column field={this.actionBodyTemplateForIsAssigned} header="Is Assigned"></Column>
                                                                                        <Column field="name" header="Widget Name" sortable></Column>
                                                                                        {/* <Column field={this.descriptionBodyTemplate} header="Description" ></Column> */}
                                                                                        <Column field="description" header="Description" ></Column>
                                                                                        <Column field="providerCompanyName" header="Provider" sortable></Column>
                                                                                        {/* <Column field="startingPrice" header="Starting Price" sortable></Column> */}
                                                                                        {/* <Column field="availaibleIntegrations" header="Available Integrations"></Column> */}

                                                                                    </DataTable>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button label="Update" href="#" icon="pi pi-check" loading={this.state.loadingUpdate} onClick={this.onUpdateClick} />
                                                                    </div>
                                                                </Panel><br />


                                                            </div>

                                                    }



                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <footer className="main-footer">
                        <strong>
                            Copyright © 2021 <a href="https://adminlte.io">InsuranceGIG</a>.
                        </strong>{" "}
                        All rights reserved.
                    </footer>
                    <aside className="control-sidebar control-sidebar-dark">
                    </aside>
                </div>
            </div>
        )
    }
}

