import React, { Component } from 'react';
import { Button } from 'primereact/button';
import CommonValues from '../../Common/Util';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { confirmDialog } from 'primereact/confirmdialog';
import { Redirect } from "react-router-dom";

class Documentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chooseFile: "",
            companyId: 0,
            companyLogoUrl: "",
            companyIconUrl: "",
            companyDocumentUrl: "",
            choosedLogo: "",
            choosedIcon: "",
            choosedDocument: "",
            errorMessage: "",
            successMessage: "",
            companyLogoLoading: false,
            companyIconLoading: false,
            companyDocumentLoading: false
        }
    }
    componentDidMount = () => {
        this.getCompanyDetails(this.props.companyId)
        this.getCompanyDocument(this.props.companyId)
    }    
    getCompanyDetails = (id) => {
        this.setState({
            companyId: id,
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getcompanybyid?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false,
                    companyLogoUrl: response.data.logoURL,
                    companyIconUrl: response.data.iconURL
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getCompanyDocument=(companyId)=>{
        this.setState({
            companyDocumentLoading:true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getdocument?CompanyId=${companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {    
                 
                this.setState({
                    loading: false,
                    companyDocumentLoading:false,
                    
                   
                })
                if(response.data.length>0)
                {
                    this.setState({
                        companyDocumentUrl:response.data[0].documentURL
                    })
                }
                
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyDocumentLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onLogoChoose = (e) => {
        this.setState({ choosedLogo: e.target.files[0] });
        this.onLogoUploadClick(e.target.files[0]);
    };
    onLogoUploadClick = (choosedLogo) => {

        if (choosedLogo == "" || choosedLogo == null) {
            return;
        }
        this.setState({
            companyLogoLoading: true
        });
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('sourcefile', choosedLogo);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/upload/logo?CompanyId=${this.props.companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyLogoUrl: response.data,
                    companyLogoLoading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyLogoLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }

            });

    };
    onIconChoose = (e) => {
        this.setState({ choosedIcon: e.target.files[0] });
        this.onIconUploadClick(e.target.files[0]);
    };
    onIconUploadClick = (choosedIcon) => {

        if (choosedIcon == "" || choosedIcon == null) {
            return;
        }
        this.setState({
            companyIconLoading: true
        });
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('sourcefile', choosedIcon);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/upload/icon?CompanyId=${this.props.companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyIconUrl: response.data,
                    companyIconLoading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyIconLoading: false
                });
                if (error.response != null) {

                }

            });

    };
    onDocumentChoose = (e) => {
        this.setState({ choosedDocument: e.target.files[0] });
        this.onDocumentUploadClick(e.target.files[0]);
    }
    onDocumentUploadClick = (choosedDocument) => {
        if (choosedDocument == "" || choosedDocument == null) {
            return;
        }
        this.setState({
            companyDocumentLoading: true
        })
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('sourcefile', choosedDocument);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/upload/document?CompanyId=${this.props.companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyDocumentUrl: response.data,
                    companyDocumentLoading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyDocumentLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }

            });

    };
    onCompanyLogoDeleteClick = () => {
        confirmDialog({
            message: 'Are you sure you want to delete company logo?',
            header: 'Delete file',
            icon: 'pi pi-exclamation-triangle',
            accept: () => this.onDocumentationSaveClick(this.state.companyIconUrl, '',this.state.companyDocumentUrl),
            reject: this.reject
        });
    }
    onCompanyIconDeleteClick = () => {
        confirmDialog({
            message: 'Are you sure you want to delete company icon?',
            header: 'Delete file',
            icon: 'pi pi-exclamation-triangle',
            accept: () => this.onDocumentationSaveClick('', this.state.companyLogoUrl,this.state.companyDocumentUrl),
            reject: this.reject
        });

    }
    onCompanyDocumentDeleteClick =()=>{
        confirmDialog({
            message: 'Are you sure you want to delete company document?',
            header: 'Delete file',
            icon: 'pi pi-exclamation-triangle',
            accept: () => this.onDocumentationSaveClick(this.state.companyIconUrl, this.state.companyLogoUrl,''),
            reject: this.reject
        });
    }
    onDocumentationSaveClick = (companyIconUrl, companyLogoUrl, companyDocumentUrl) => {
        this.setState({
            btnloading: true,
            companyIconUrl: companyIconUrl,
            companyLogoUrl: companyLogoUrl,
            companyDocumentUrl: companyDocumentUrl
        })

        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "CompanyId": this.props.companyId,
            "IconURL": companyIconUrl,
            "LogoURL": companyLogoUrl,
            "DocumentURL": companyDocumentUrl
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/updatedocumentation`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {

                this.setState({
                    successMessage: "Documentation updated successfully...!!!",
                    btnloading: false,
                });
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    1000
                );
            })
            .catch((error) => {
                this.setState({
                    btnloading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    render() {
        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.ClearValues();
            return <Redirect to="/" />
        }
        //console.log(this.state.companyDocumentUrl)
        return (
            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div className='row' style={{ border: "1px solid #dee2e6" }}>
                                <div className='col-2' style={{ borderRight: "1px solid #dee2e6" }}><b>Document Type</b></div>
                                <div className='col-5' style={{ borderRight: "1px solid #dee2e6" }}><b>Preview</b></div>
                                <div className='col-3' style={{ borderRight: "1px solid #dee2e6" }}><b>Upload</b></div>
                                <div className='col-2' ><b>Action</b></div>
                            </div>

                            <div className='row' style={{ border: "1px solid #dee2e6", padding: "10px" }}>
                                <div className='col-2' style={{ borderRight: "1px solid #dee2e6" }}>
                                    Company Logo
                                </div>
                                <div className='col-5' style={{ borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                                    {
                                        this.state.companyLogoLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <img src={this.state.companyLogoUrl} width="70" height="70" alt="No Image uploaded" />
                                    }
                                </div>
                                <div className='col-3' style={{ borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                                    <input type="file" id="choosedLogo" name="choosedLogo" onChange={this.onLogoChoose} />
                                </div>
                                <div className='col-2' style={{ textAlign: "center" }}>
                                    <Button icon="pi pi-trash" className="p-button p-button-danger"  onClick={this.onCompanyLogoDeleteClick} />
                                </div>
                            </div>
                            <div className='row' style={{ border: "1px solid #dee2e6", padding: "10px" }}>
                                <div className='col-2' style={{ borderRight: "1px solid #dee2e6" }}>
                                    Company Icon
                                </div>
                                <div className='col-5' style={{ borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                                    {
                                        this.state.companyIconLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <img src={this.state.companyIconUrl} width="70" height="70" alt="No Image uploaded" />
                                    }
                                </div>
                                <div className='col-3' style={{ borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                                    <input type="file" id="choosedIcon" name="choosedIcon" onChange={this.onIconChoose} />
                                </div>
                                <div className='col-2' style={{ textAlign: "center" }}>

                                    <Button icon="pi pi-trash" className="p-button p-button-danger"  onClick={this.onCompanyIconDeleteClick} />
                                </div>
                            </div>
                            <div className='row' style={{ border: "1px solid #dee2e6", padding: "10px" }}>
                                <div className='col-2' style={{ borderRight: "1px solid #dee2e6" }}>
                                    API docs
                                </div>

                                <div className='col-5' style={{ borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                                    {
                                        this.state.companyDocumentLoading ?
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <>
                                                {
                                                    this.state.companyDocumentUrl == "" || this.state.companyDocumentUrl == null ?
                                                        <p>No document uploaded yet</p>
                                                        :
                                                        <a href={this.state.companyDocumentUrl}>click to view document</a>
                                                }
                                            </>
                                    }


                                </div>
                                <div className='col-3' style={{ borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                                    <input type="file" id="choosedDocument" name="choosedDocument" onChange={this.onDocumentChoose} />
                                </div>
                                <div className='col-2' style={{ textAlign: "center" }}>
                                    <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={this.onCompanyDocumentDeleteClick}/>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className='col-6'></div>
                                <div className='col-4'>
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2" style={{ textAlign: "right" }}>
                                    {/* <Button label="Add more" icon="pi pi-check" loading={this.state.btnloading}  onClick={this.onAddMoreClicked} style={{ marginRight: "10px" }}    /> */}
                                    <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={() => this.onDocumentationSaveClick(this.state.companyIconUrl, this.state.companyLogoUrl, this.state.companyDocumentUrl)} />

                                </div>
                            </div>


                        </>
                }
            </>
        )
    }
}
export default Documentation;
