import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonValues from '../../Common/Util';
import { Message } from 'primereact/message';
import { Redirect } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';

var Scroll = require('react-scroll');

var scroller = Scroll.scroller;


class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            title: "",
            workEmail: "",
            personalEmail: "",
            workPhoneNo: "",
            personalPhoneNo: "",
            address: "",
            addressLine2: "",
            city: "",
            zipcode: "",
            linkedIn: "",
            sourceContent: "",
            sourceContentList: [],
            comments: "",
            searchText: "",
            userIdForUpdate: "",
            contactList: [],
            userContactDetails: [],
            sourceContentId: "",
            hideShowBtn: 0,
            errorMessage:"",
            successMessage: "",
            successMessageForDelete: "",
            loadingContactList: "",
            errorMessageFirstname: "",
            errorMessageLastname: "",
            errorMessageWorkEmail: "",
            errorMessagePersonalEmail: "",
            isPrimaryContact: false,
            inputTextErrorClassFirstName: false,
            inputTextErrorClassLastName: false,
            inputTextErrorClassWorkEmail: false,

            inputTextErrorClassPersonalEmail: false,


        }
    }
    componentDidMount = () => {
        this.getContactListDetails("");
        this.getContactSouraceList();
    }
    getContactSouraceList = () => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/getsourcecontentlist?`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
             
                this.setState({
                    sourceContentList: response.data,
                });
            })
            .catch((error) => {
                console.log(error);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.ClearValues();
                        return <Redirect to="/" />
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    isValid = () => {

        this.clearAllValidation();

        if (this.state.firstName == "" || this.state.firstName == null) {
            this.setState({
                inputTextErrorClassFirstName: true,
                errorMessageFirstname: "Please enter first name"
            });

            scroller.scrollTo('contactDiv', {
                duration: 1500,
                delay: 100,
                smooth: true
            });
            return false;
        }
        if (this.state.lastName == "" || this.state.lastName == null) {
            this.setState({
                inputTextErrorClassLastName: true,
                errorMessageLastname: "Please enter last name"
            });


            scroller.scrollTo('contactDiv', {
                duration: 1500,
                delay: 100,
                smooth: true
            });
            return false;
        }
        if (this.state.workEmail == "" || this.state.workEmail == null) {
            this.setState({
                inputTextErrorClassWorkEmail: true,
                errorMessageWorkEmail: "Please enter work email"
            });



            scroller.scrollTo('contactDiv', {
                duration: 1500,
                delay: 100,
                smooth: true
            });
            return false;
        }

        const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if (regex.test(this.state.workEmail) === false) {
            this.setState({
                inputTextErrorClassWorkEmail: true,
                errorMessageWorkEmail: "Please enter valid work email"
            });

            scroller.scrollTo('contactDiv', {
                duration: 1500,
                delay: 100,
                smooth: true
            });
            return false;
        }
        if (regex.test(this.state.personalEmail) === false && this.state.personalEmail !== "") {
            this.setState({
                inputTextErrorClassPersonalEmail: true,
                errorMessagePersonalEmail: "Please enter valid personal email"
            });
            scroller.scrollTo('contactDiv', {
                duration: 1500,
                delay: 100,
                smooth: true
            });
            return false;
        }
        return true
    }

    clearAllValidation = () => {
        this.setState({
            inputTextErrorClassFirstName: false,
            inputTextErrorClassLastName: false,
            errorMessageFirstname: "",
            errorMessageLastname: "",
            errorMessageWorkEmail: "",
            errorMessagePersonalEmail: "",
            inputTextErrorClassWorkEmail: false,
            inputTextErrorClassPersonalEmail: false,
            errorMessage:""
            // successMessage:"",
            // successMessageForDelete:""
        });
    }

    onPointsOfContactSaveClick = () => {
        if (this.isValid()) {
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "AddressLine1": this.state.address,
                "AddressLine2": this.state.addressLine2,
                "City": this.state.city,
                "Comments": this.state.comments,
                "FirstName": this.state.firstName,
                "CompanyId": parseInt(this.props.companyId),
                "LastName": this.state.lastName,
                "LinkedinUrl": this.state.linkedIn,
                "PersonalEmail": this.state.personalEmail,
                "PersonalPhoneNumber": this.state.personalPhoneNo,
                "PrimaryContact": this.state.isPrimaryContact,
                "SourceContent": this.state.sourceContentId,
                "Title": this.state.title,
                "WorkEmail": this.state.workEmail,
                "WorkPhoneNumber": this.state.workPhoneNo,
                "Zipcode": this.state.zipcode
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_UserService_Url}/api/user/insertcontactdetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            this.setState({
                btnsavebtnloading: true
            });
            axios(config)
                .then((response) => {
                    this.setState({
                        successMessage: "Contact details added successfully...!!!",
                        btnsavebtnloading: false,
                    });

                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                  
                    this.clearAll();
                    this.clearAllValidation();                    
                    this.getContactListDetails("");
                    this.props.onContactAdded();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnsavebtnloading: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();     
                        }
                        else if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "There already exists an user with this email address."
                            });
                        }
                    }                    
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({errorMessage: "" }),
                            3000
                        );
                    }
                });

        }
    }
    actionBodyTemplate = (rowData) => {
        return (
            this.state.hideShowBtn == 0?
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => this.editUserContactDetails(rowData.userId)} />
                &emsp;
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => this.onConfirmDeleteContactDetails(rowData.userId)} />
            </React.Fragment>
            :null
        );
    }
    onConfirmDeleteContactDetails = (userId) => {
        confirmDialog({
            message: 'Are you sure you want to delete the contact details?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.deleteUserContactDetails(userId),
            reject: this.reject
        });
    }

    deleteUserContactDetails = (userId) => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/deleteUserContactDetails?UserId=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        this.setState({
            loadingContactList: true
        });
        axios(config)
            .then((response) => {
                this.setState({
                    loadingContactList: false,
                    successMessageForDelete: "Contact details deleted successfully...!!!",

                });
              
                setTimeout(
                    () => this.setState({ successMessageForDelete: "" }),
                    3000
                );
                this.getContactListDetails("");
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingContactList: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    editUserContactDetails = (userId) => {

        this.clearAllValidation();
        this.setState({
            userIdForUpdate: userId,
            hideShowBtn: 1
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/getContactDetailsById?UserId=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
              
                response.data.forEach(element => {
                    this.state.sourceContentList.forEach(listItem => {
                        if (listItem.sourceContentId == element.sourceContent) {
                            this.setState({
                                sourceContent: listItem
                            });
                        }
                    });
                })
                this.setState({
                    userContactDetails: response.data,
                    firstName: response.data[0].firstName,
                    lastName: response.data[0].lastName,
                    title: response.data[0].title,
                    workEmail: response.data[0].workEmail,
                    personalEmail: response.data[0].personalEmail,
                    workPhoneNo: response.data[0].workPhoneNumber,
                    personalPhoneNo: response.data[0].personalPhoneNumber,
                    address: response.data[0].addressLine1,
                    addressLine2: response.data[0].addressLine2,
                    city: response.data[0].city,
                    zipcode: response.data[0].zipcode,
                    linkedIn: response.data[0].linkedinUrl,
                    //  sourceContent: response.data[0].sourceContent,
                    comments: response.data[0].comments,
                    isPrimaryContact: response.data[0].primaryContact
                });
            })

            .catch((error) => {
                console.log(error);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    onContactSourceChange = (e) => {
  
        let sourceContentId = e.value.sourceContentId
        let selectedRequest = {};

        this.state.sourceContentList.forEach(element => {
            if (element.sourceContentId == sourceContentId) {
                selectedRequest = element;
            }
        });
        this.setState({
            sourceContent: selectedRequest,
            sourceContentId: selectedRequest.sourceContentId
        });
    }
    onUpdateDetailsClicked = () => {
        if (this.isValid()) {
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "AddressLine1": this.state.address,
                "AddressLine2": this.state.addressLine2,
                "City": this.state.city,
                "Comments": this.state.comments,
                "FirstName": this.state.firstName,
                "CompanyId": parseInt(this.props.companyId),
                "LastName": this.state.lastName,
                "LinkedinUrl": this.state.linkedIn,
                "PersonalEmail": this.state.personalEmail,
                "PersonalPhoneNumber": this.state.personalPhoneNo,
                "PrimaryContact": this.state.isPrimaryContact,
                "SourceContent": this.state.sourceContentId,
                "Title": this.state.title,
                "UserId": this.state.userIdForUpdate,
                "WorkEmail": this.state.workEmail,
                "WorkPhoneNumber": this.state.workPhoneNo,
                "Zipcode": this.state.zipcode
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_UserService_Url}/api/user/updateUserContactDetails?`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            this.setState({
                btnUpdatebtnLoading: true
            });
            axios(config)
                .then((response) => {
                    this.setState({
                        successMessage: "Contact details updated successfully...!!!",
                        btnUpdatebtnLoading: false
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.clearAllValidation();
                    this.getContactListDetails("");
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnUpdatebtnLoading: false
                    });
                    this.clearAll();
                    this.clearAllValidation();
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();     
                        }
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({errorMessage: "" }),
                            3000
                        );
                    }
                });
        }
    }

    clearAll = ()=>{
        this.setState({
            firstName: "",
            lastName: "",
            title: "",
            workEmail: "",
            personalEmail: "",
            workPhoneNo: "",
            personalPhoneNo: "",
            address: "",
            addressLine2: "",
            city: "",
            zipcode: "",
            linkedIn: "",
            sourceContent: "",
            isPrimaryContact: false,
            comments: "",
            hideShowBtn: 0

        });
    }
    onCancelbtnClicked = () => {
        this.clearAllValidation();
        this.clearAll();
      
    }
    onKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.onSearchClick();
        }
    }
    onSearchClick = () => {
        this.getContactListDetails(this.state.searchText);

    }
    getContactListDetails = (searchText) => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/searchcontactdetails?companyId=${this.props.companyId}&searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        this.setState({
            loadingContactList: true
        });
        axios(config)
            .then((response) => {
              
                this.setState({
                    contactList: response.data,
                    loadingContactList: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingContactList: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    render() {
        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.ClearValues();
            return <Redirect to="/" />
        }
        return (

            <div id="contactDiv" name="contactDiv">
                <div className='row'>
                    <div className='col-8'></div>
                    <div className="input-group input-group-sm col-md-4 col-4">
                        <input
                            type="text"
                            name="searchText"
                            className="form-control "
                            placeholder="Search"
                            value={this.state.searchText}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyClick}
                        />
                        <div className="input-group-append">
                            <button type="submit"
                                className="btn btn-default"
                                onClick={this.onSearchClick}

                            >
                                <i className="fas fa-search" />
                            </button>
                        </div>
                    </div>
                </div><br />
                {this.state.loadingContactList ?
                    <div style={{ textAlign: "center" }}>
                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                    :
                    <>
                        {this.state.contactList.length > 0 ?
                            <>
                                <DataTable value={this.state.contactList} paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={10} responsiveLayout="scroll">
                                    <Column field="fullName" header="Name" sortable></Column>
                                    <Column field="workEmail" header="Email Id" sortable></Column>
                                    <Column field="action" body={this.actionBodyTemplate} header="Action"></Column>
                                </DataTable><br />
                            </>
                            :
                            <>
                                <h4 style={{ textAlign: "center" }}>No Contacts Available</h4>
                                <br />
                            </>
                        }
                    </>
                }
                <div className='row'>
                    <div className='col-12'>
                        {this.state.successMessageForDelete != "" ? <Message severity="success" text={this.state.successMessageForDelete} style={{ float: "right" }} />
                            : null}

                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            First Name
                        </label><span style={{ color: "red" }}>*</span>
                        <input type="text"
                            id="firstName"
                            name="firstName"
                            className={this.state.inputTextErrorClassFirstName ? "form-control p-invalid p-mr-2" : "form-control"}
                            onChange={this.onChange}
                            value={this.state.firstName} />
                        <small id="username-help" className="p-error">{this.state.errorMessageFirstname}</small>

                    </div>
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Last Name
                        </label><span style={{ color: "red" }}>*</span>
                        <input type="text"
                            id="lastName"
                            name="lastName"
                            className={this.state.inputTextErrorClassLastName ? "form-control p-invalid p-mr-2" : "form-control"}
                            value={this.state.lastName}
                            onChange={this.onChange}
                        />
                        <small id="username-help" className="p-error">{this.state.errorMessageLastname}</small>

                    </div>
                </div>
                <label className="form-label">
                    Title
                </label>
                <input type="text"
                    id="title"
                    name="title"
                    className="form-control"
                    value={this.state.title}
                    onChange={this.onChange}
                />


                <div className="row">
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Work Email
                        </label><span style={{ color: "red" }}>*</span>
                        <input type="text"
                            id="workEmail"
                            name="workEmail"
                            className={this.state.inputTextErrorClassWorkEmail ? "form-control p-invalid p-mr-2" : "form-control"}
                            onChange={this.onChange}
                            value={this.state.workEmail} />
                        <small id="username-help" className="p-error">{this.state.errorMessageWorkEmail}</small>
                    </div>
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Personal Email
                        </label>
                        <input type="text"
                            id="personalEmail"
                            name="personalEmail"
                            className={this.state.inputTextErrorClassPersonalEmail ? "form-control p-invalid p-mr-2" : "form-control"}
                            value={this.state.personalEmail}
                            onChange={this.onChange}
                        />
                        <small id="username-help" className="p-error">{this.state.errorMessagePersonalEmail}</small>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Work Phone Number
                        </label>
                        <input type="text"
                            id="workPhoneNo"
                            name="workPhoneNo"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.workPhoneNo} />
                    </div>
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Personal Phone Number
                        </label>
                        <input type="text"
                            id="personalPhoneNo"
                            name="personalPhoneNo"
                            className="form-control"
                            value={this.state.personalPhoneNo}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Address Line1
                        </label>
                        <input type="text"
                            id="address"
                            name="address"
                            value={this.state.address}
                            className="form-control"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            Address Line2
                        </label>
                        <input type="text"
                            id="addressLine2"
                            name="addressLine2"
                            value={this.state.addressLine2}
                            className="form-control"
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            City
                        </label>
                        <input type="text"
                            id="city"
                            name="city"
                            value={this.state.city}
                            className="form-control"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-md-6 col-6">
                        <label className="form-label">
                            ZipCode
                        </label>
                        <input type="text"
                            id="zipcode"
                            name="zipcode"
                            value={this.state.zipcode}
                            className="form-control"
                            onChange={this.onChange}
                        />
                    </div>
                </div>


                <label className="form-label">
                    Linkedin URL
                </label>
                <input type="text"
                    value={this.state.linkedIn}
                    onChange={(e) => this.setState({ linkedIn: e.target.value })}
                    className="form-control" />
                <div className='row'>
                    <div className='col-md-6 col-6'>
                        <label className="form-label">
                            Source Content
                        </label><br />
                        {/* <textarea type="text"
                            id="sourceContent"
                            name="sourceContent"
                            value={this.state.sourceContent}
                            className="form-control"
                            onChange={this.onChange}
                            rows={5}
                        /> */}

                        <Dropdown value={this.state.sourceContent}
                            options={this.state.sourceContentList}
                            onChange={(e) => this.onContactSourceChange(e)}
                            optionLabel="sourceContentName"
                            placeholder="Select a Contact Source"
                            style={{ width: "100%" }}
                        />

                    </div>
                    <div className='col-md-6 col-6'>
                        <label className="form-label">
                            Comments
                        </label>
                        <textarea type="text"
                            id="comments"
                            name="comments"
                            value={this.state.comments}
                            className="form-control"
                            onChange={this.onChange}
                            rows={5}
                        />
                    </div>

                </div><br />
                <div className='row'>
                    <div className='col-md-2 col-2'>
                        <label className="form-label">
                            Primary Contact
                        </label>
                    </div>
                    <div className='col-md-1 col-1'>
                        <InputSwitch
                            checked={this.state.isPrimaryContact}
                            onChange={(e) => this.setState({ isPrimaryContact: e.value })}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className='col-7'>
                        {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                            : null}  
                            {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                            : null}                     
                    </div>
                    <div className="col-md-12 col-lg-5 col-xs-12" style={{ textAlign: "right" }}>
                   
                        {this.state.hideShowBtn == 1 ?
                            <>
                                <div className='row'>
                                    <div className='col-6'>
                                        <Button label="Cancel" icon="pi pi-check" loading={this.state.btnCancelbtnLoading} onClick={this.onCancelbtnClicked} />

                                    </div>
                                    <div className='col-6'>
                                        <Button label="Update Contact" icon="pi pi-check" loading={this.state.btnUpdatebtnLoading} onClick={this.onUpdateDetailsClicked} />
                                    </div>
                                </div>
                            </>
                            :
                            <Button label="Add Contact" icon="pi pi-check" loading={this.state.btnsavebtnloading} onClick={this.onPointsOfContactSaveClick} />

                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Contact;
