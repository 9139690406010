import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import Sidebar from '../../Common/Sidebar';
import { Ripple } from 'primereact/ripple';
import Overview from './Overview';
import Price from './Price';
import Specs from './Specs';
import Legal from './Legal';
import Screenshot from './Screenshot';
import Video from './Video';
import MultipleVideo from './MultipleVideo';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import AdditionalDetails from './AdditionalDetails';


class NewService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            serviceId: 0,
            serviceData: {},
            serviceName: "",
            innerWidth: 1024,
            pageText:'',
        }

    }
  
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            serviceId: params.index,
        });

        if (params.index != 0) {
            this.getDetails(params.index)
        }
        if(params.index == 0){
            this.setState({pageText:'Add New Service'})
        }else{
            this.setState({pageText:'Edit service'})
        }
        window.addEventListener("resize", this.updateWindowSize);
        this.updateWindowSize();
    }
    getDetails = (id) => {
        this.setState({
            serviceId: id,
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getbyid?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
     
        axios(config)
            .then((response) => {
                this.setState({
                    serviceData: response.data[0],
                    serviceName: response.data[0].serviceName,
                    loading: false,
                });


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    overviewTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Overview
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    priceTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Price
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    specsTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Specs
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    legalTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Legal
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    screenshotTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Screenshot
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    videoTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Video
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    multipleVideoTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                Video
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    AdditionalDetailsTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Additional Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    checkAndApplySideBar = (innerWidth) => {

        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    render() {
       
        return (

            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i className="fas fa-bars"></i></a>
                            </li>

                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-widget="control-sidebar"
                                    data-slide="true"
                                    href="/"
                                    role="button"
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                    <h1>{this.state.pageText}</h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">

                                                    <Panel headerTemplate={this.overviewTemplate} toggleable>
                                                        <Overview serviceId={this.state.serviceId}></Overview>
                                                    </Panel><br />
                                                    {this.state.serviceId == '0' ? null :
                                                        <>
                                                            <Panel headerTemplate={this.priceTemplate} toggleable collapsed>
                                                                <Price serviceId={this.state.serviceId} serviceName={this.state.serviceName} ></Price>
                                                            </Panel><br />

                                                            <Panel headerTemplate={this.specsTemplate} toggleable collapsed>
                                                                <Specs serviceId={this.state.serviceId}></Specs>
                                                            </Panel><br />

                                                            <Panel headerTemplate={this.legalTemplate} toggleable collapsed>
                                                                <Legal serviceId={this.state.serviceId} serviceName={this.state.serviceName} ></Legal>
                                                            </Panel><br />

                                                            <Panel headerTemplate={this.screenshotTemplate} toggleable collapsed>
                                                                <Screenshot serviceId={this.state.serviceId} serviceName={this.state.serviceName} ></Screenshot>
                                                            </Panel><br />

                                                            {/* <Panel headerTemplate={this.videoTemplate} toggleable collapsed>
                                                                <Video serviceId={this.state.serviceId}></Video>
                                                            </Panel><br/> */}

                                                            <Panel headerTemplate={this.multipleVideoTemplate} toggleable collapsed>
                                                                <MultipleVideo serviceId={this.state.serviceId} serviceName={this.state.serviceName} ></MultipleVideo>
                                                            </Panel><br />

                                                            <Panel headerTemplate={this.AdditionalDetailsTemplate} toggleable collapsed>
                                                                <AdditionalDetails serviceId={this.state.serviceId}></AdditionalDetails>
                                                            </Panel>

                                                            
                                                        </>
                                                    }<br />

                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <footer className="main-footer">
                        <strong>
                            Copyright © 2021 <a href="https://adminlte.io">InsuranceGIG</a>.
                        </strong>{" "}
                        All rights reserved.
                    </footer>
                    <aside className="control-sidebar control-sidebar-dark">
                    </aside>
                </div>
            </div>
        );
    }
}
export default NewService;
