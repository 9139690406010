import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import Seller from './Seller';
import Buyer from './Buyer';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Message } from 'primereact/message';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
import { confirmPopup } from 'primereact/confirmpopup';


class WorkflowImplementation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allImplementationLoading: false,
            workflowId: 0,
            errorMessage: "",
            successMessage: "",
            showPanel: false,
            implementationList: [],
            status: "",
            date1: "",
            implementationId: 0,
            implementationName: "",
            statusList: [],
            revenueList: [],
            implementationSellerList: [],
            goLiveDate: new Date(),
            throughIGIG: true,
            editButton: false,
            revenueType: "",
            inputTextRevenueTypeErrorClass: false,
            errorMessagerevenuetype: ""
        }

    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            workflowId: params.index,
        });

        //  this.getWorkflowImplementationById(params.index, 0);
        this.getAllWorkflowStatus();
        this.getRevenueTypeList();

    }
    getAllWorkflowStatus = () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/workflowstatus`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    statusList: response.data,

                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({

                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getRevenueTypeList = () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/revenuetype`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    revenueList: response.data,

                })
                this.getWorkflowImplementationById(this.state.workflowId, 0);
            })
            .catch((error) => {
                console.log(error);
                this.setState({

                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getWorkflowImplementationById = (workflowId, implementationId) => {
        let workflowIds = [];
        let implementationIds = [];
        if (workflowId != 0) {
            workflowIds.push(parseInt(workflowId));
        }
        if (implementationId != 0) {
            implementationIds.push(implementationId);
        }
        var axios = require('axios');
        var data = JSON.stringify({
            "WorkflowId": workflowIds,
            "WorkflowImplementationId": implementationIds
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/implementation`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        this.setState({
            allImplementationLoading: true,
            implementationSellerList: []
        })
        axios(config)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    implementationList: response.data,
                    allImplementationLoading: false,
                })
                if (this.state.implementationId != 0) {
                    this.setState({
                        implementationName: response.data[0].name,
                        implementationDescription: response.data[0].description,
                        goLiveDate: new Date(response.data[0].goLiveDate),
                        throughIGIG: response.data[0].throughIGIG,
                    })
                    this.state.statusList.forEach(element => {
                        if (response.data[0].status == element.name) {
                            this.setState({
                                status: element
                            })
                        }
                    });
                    this.state.revenueList.forEach(element => {
                        if (response.data[0].revenueType == element.id) {
                            this.setState({
                                revenueType: element
                            })
                        }
                    });
                    this.getWorkflowImplementationSeller();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    allImplementationLoading: false
                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    getWorkflowImplementationSeller = () => {
        this.setState({
            loading: true,

        });
        let implementationId = [];
        if (this.state.implementationId != 0) {
            implementationId.push(this.state.implementationId);
        }
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "WorkflowImplementationId": implementationId,
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/seller`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {

                this.setState({

                    loading: false
                });

                if (response.data.length > 0) {
                    this.setState({
                        implementationSellerList: response.data
                    })
                    // this.setState({
                    //     sellerId: response.data[0].workflowImplementationSellerId,
                    //     cost: response.data[0].costInCents,
                    //     percent: response.data[0].costInPercentage,
                    //     igfee: response.data[0].igComission,
                    //     costType: response.data[0].costType
                    // })
                    // this.state.companyList.forEach(element => {
                    //     if (element.name == response.data[0].seller) {
                    //         this.setState({
                    //             partnerName: element
                    //         })
                    //     }
                    // });
                    // this.state.statusList.forEach(element => {
                    //     if (response.data[0].status == element.name) {
                    //         this.setState({
                    //             status: element
                    //         })
                    //     }
                    // });
                    // this.unitOfMeasureList.forEach(element => {
                    //     if (element.name == response.data[0].unitOfMeasure) {
                    //         this.setState({
                    //             costUnit: element
                    //         })
                    //     }

                    // });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onAddImplementationClick = () => {
        this.setState({
            errorMessage: "",
            successMessage: "",
            inputTextErrorClass: false,
            errorMessageImplementationName: "",
            showPanel: true,
            implementationId: 0,
            implementationName: "",
            implementationDescription: "",
            goLiveDate: new Date(),
            status: "",
            editButton: true,
            revenueType: ""

        })
    }
    onCancelImplementationClick = () => {
        this.setState({
            errorMessage: "",
            successMessage: "",
            inputTextErrorClass: false,
            errorMessageImplementationName: "",
            showPanel: false,
            implementationId: 0,
            editButton: false
        })
        this.getWorkflowImplementationById(this.state.workflowId, 0);
    }
    buyerTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Buyer
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    sellerTemplate(options, val) {

        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Seller - {val != undefined ? val.seller : "Add New"}
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    onStatusChange = (e) => {
        this.setState({
            status: e.value
        })
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onRevenueTypeChange = (e) => {
        this.setState({
            revenueType: e.value
        })
    }
    isValid = () => {
        let valid = true;
        if (this.state.implementationName == "") {
            this.setState({
                inputTextErrorClass: true,
                errorMessageImplementationName: "Please enter implementation name."
            })
            valid = false;
        }

        if (this.state.status == "") {
            this.setState({
                inputTextErrorClass1: true,
                errorMessageImplementationStatus: "Please select status."
            })
            valid = false;
        }
        if (this.state.revenueType == "") {
            this.setState({
                inputTextRevenueTypeErrorClass: true,
                errorMessagerevenuetype: "Please select revenue type."
            })
            valid = false;
        }
        return valid;
    }
    goLiveDateChange = (e) => {
        this.setState({ goLiveDate: e.value });
    }
    onImplementationBasicDetailsSaveClick = () => {

        this.setState({
            errorMessage: "",
            successMessage: "",
            inputTextErrorClass: false,
            errorMessageImplementationName: "",
            inputTextErrorClass1: false,
            errorMessageImplementationStatus: "",
            inputTextRevenueTypeErrorClass: false,
            errorMessagerevenuetype: ""
        })
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true,

            })
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Name": this.state.implementationName,
                "Status": this.state.status.id.toString(),
                "Description": this.state.implementationDescription,
                "WorkflowId": this.state.workflowId,
                "GoLiveDate": moment(this.state.goLiveDate).format("MMM DD,YYYY"),
                "ThroughIGIG": this.state.throughIGIG,
                "RevenueType": this.state.revenueType.id
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/addimplementation`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.getWorkflowImplementationById(this.state.workflowId, 0);
                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Workflow implementation added successfully...!!!",
                        // implementationId: response.data,
                        showPanel: false,
                        editButton: false
                    })
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    })
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "Workflow Implementation with the same name already exists."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                });
        }
    }
    onImplementationBasicDetailsUpdateClick = () => {

        this.setState({
            errorMessage: "",
            successMessage: "",
            inputTextErrorClass: false,
            errorMessageImplementationName: "",
            inputTextErrorClass1: false,
            errorMessageImplementationStatus: "",
            inputTextRevenueTypeErrorClass: false,
            errorMessagerevenuetype: ""
        })
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true,
            })
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Name": this.state.implementationName,
                "Status": this.state.status.id.toString(),
                "Description": this.state.implementationDescription,
                "WorkflowImplementationId": this.state.implementationId,
                "GoLiveDate": moment(this.state.goLiveDate).format("MMM DD,YYYY"),
                "WorkflowId": this.state.workflowId.toString(),
                "ThroughIGIG": this.state.throughIGIG,
                "RevenueType": this.state.revenueType.id
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/updateimplementation`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.getWorkflowImplementationById(this.state.workflowId, this.state.implementationId);
                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Workflow implementation updated successfully...!!!",

                    })
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    })
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "Workflow Implementation with the same name already exists."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                });
        }
    }
    onSaveSellerAndPageRefresh = () => {

        this.getWorkflowImplementationById(0, this.state.implementationId);
    }
    onWorkflowImplementationClick = (implementationId) => {

        this.setState({
            showPanel: true,
            implementationId: implementationId,
            errorMessage: "",
            successMessage: "",
            inputTextErrorClass: false,
            errorMessageImplementationName: "",
            editButton: true
        })
        this.getWorkflowImplementationById(0, implementationId);

    }
    onDeleteImplementationClick = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Do you want to delete this implementation?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.DeleteImplementation(id),
            reject: this.reject
        });
    }
    DeleteImplementation = (id) => {
        this.setState({
            deleteImplementaionLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/deleteimplementation?ImplementationId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.getWorkflowImplementationById(this.state.workflowId, 0);
                this.setState({
                    deleteImplementaionLoading: false,
                    successMessage: "Workflow implementation deleted successfully...!!!",

                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    deleteImplementaionLoading: false
                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }

                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p>{moment(rowData.created).format("MMM DD,YYYY")}</p>
            </React.Fragment>
        );
    }
    actionBodyTemplate = (rowData) => {
        return (

            <React.Fragment>
                {
                    this.state.editButton ?
                        null
                        :
                        <>
                            {
                                this.state.deleteImplementaionLoading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    : <>

                                        {
                                            process.env.REACT_APP_DIRECTPARTNER_WORKFLOW_ID == this.state.workflowId ?
                                                null
                                                : <>
                                                    <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.onWorkflowImplementationClick(rowData.workflowImplementationId)} />
                                                    &nbsp;
                                                    <Button icon="pi pi-trash" className="p-button p-mr-2 editbtn" onClick={(e) => this.onDeleteImplementationClick(e, rowData.workflowImplementationId)} />
                                                </>
                                        }
                                    </>
                            }

                        </>
                }
            </React.Fragment>


        );
    }
    render() {

        return (
            <>
                {
                    this.state.allImplementationLoading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <DataTable value={this.state.implementationList} paginator
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                rows={5} responsiveLayout="scroll" >
                                {/* <Column field="workflowImplementationId" header="Imp ID"></Column> */}
                                <Column field="name" header="Implementation Name" ></Column>
                                <Column field="buyers" header="Buyer" ></Column>
                                <Column field="sellers" header="Seller(s)" ></Column>
                                <Column body={this.dateBodyTemplate} field="created" header="Date created" ></Column>
                                <Column field="status" header="Status" ></Column>
                                <Column field="goLiveDate" header="Go Live Date" ></Column>
                                <Column body={this.actionBodyTemplate} header="Action" ></Column>

                            </DataTable><br />
                        </>
                }
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        {this.state.editButton ?
                            null
                            :
                            <>
                                {
                                    process.env.REACT_APP_DIRECTPARTNER_WORKFLOW_ID == this.state.workflowId ?
                                        null
                                        :
                                        <Button label="Add Implementation" loading={this.state.btnloadingsave} onClick={this.onAddImplementationClick} style={{ float: "right" }} />
                                }
                            </>
                        }

                    </div>
                </div><br />

                {this.state.showPanel && !this.state.allImplementationLoading ?
                    <>
                        <div className='row'>
                            <div className='col-2'>
                                <label>Implementation Name</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    name="implementationName"
                                    className={this.state.inputTextErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    value={this.state.implementationName}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageImplementationName}</small>
                            </div>
                        </div><br />
                        <div className='row'>
                            <div className='col-2'>
                                <label>Status</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <Dropdown value={this.state.status}
                                    options={this.state.statusList}
                                    onChange={this.onStatusChange}
                                    optionLabel="name"
                                    placeholder="Select"
                                    style={{ width: "100%" }}
                                    className={this.state.inputTextErrorClass1 ? "p-invalid p-mr-2" : ""} />
                                <small id="username-help" className="p-error">{this.state.errorMessageImplementationStatus}</small>
                            </div>
                        </div><br />

                        <div className='row'>
                            <div className='col-2'>
                                <label>Description</label>
                            </div>
                            <div className='col-10'>
                                <textarea type="text"
                                    id="implementationDescription"
                                    name="implementationDescription"
                                    value={this.state.implementationDescription}
                                    className="form-control"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div><br />
                        <div className='row'>
                            <div className='col-2'>
                                <label>Go Live Date</label>
                            </div>
                            <div className='col-4'>
                                <Calendar
                                    className={this.state.inputTextDateErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.goLiveDate}
                                    onChange={this.goLiveDateChange}
                                    errorMessage={this.state.goLiveDateError}
                                    placeholder="Select Date"
                                    minDate={new Date()}
                                />
                            </div>

                            <div className='col-2'>
                                <label>Through InsuranceGIG?</label>
                            </div>
                            <div className='col-4'>
                                <InputSwitch checked={this.state.throughIGIG} onChange={(e) => this.setState({ throughIGIG: e.value })} />
                            </div>
                        </div>
                        <div >
                            <label className="form-label">
                                Revenue Type
                            </label><span style={{ color: "red" }}>*</span>
                            <Dropdown
                                className={this.state.inputTextRevenueTypeErrorClass ? " p-invalid p-mr-2" : ""}
                                value={this.state.revenueType}
                                options={this.state.revenueList}
                                onChange={this.onRevenueTypeChange}
                                optionLabel="name"
                                placeholder="Select Revenue Type"
                                style={{ width: "100%" }}
                            />
                            <small id="username-help" className="p-error">{this.state.errorMessagerevenuetype}</small>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-6'>
                                {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                    : null}
                                {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                    : null}
                            </div>
                            <div className='col-6'>
                                {
                                    this.state.implementationId == 0 ?
                                        <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onImplementationBasicDetailsSaveClick} style={{ float: "right" }} />
                                        :
                                        <Button label="Update" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onImplementationBasicDetailsUpdateClick} style={{ float: "right" }} />
                                }
                                {this.state.editButton || this.state.showPanel ?
                                    <Button label="Cancel" onClick={this.onCancelImplementationClick} style={{ marginRight: '10px', float: "right" }} />
                                    :
                                    null
                                }

                            </div>
                        </div><br />
                        {
                            this.state.implementationId == 0 ?
                                null
                                :
                                <>
                                    <Panel headerTemplate={(options) => this.buyerTemplate(options)} toggleable collapsed>
                                        <Buyer
                                            implementationId={this.state.implementationId}
                                        />
                                    </Panel><br />
                                    {
                                        this.state.implementationSellerList.map(element => {
                                            return (
                                                <>
                                                    <Panel headerTemplate={(options) => this.sellerTemplate(options, element)} toggleable collapsed>
                                                        <Seller
                                                            implementationId={this.state.implementationId}
                                                            implementationSeller={element}
                                                            onSaveSellerAndPageRefresh={this.onSaveSellerAndPageRefresh}
                                                        />
                                                    </Panel><br />
                                                </>
                                            )
                                        })
                                    }
                                    <Panel headerTemplate={this.sellerTemplate} toggleable collapsed>
                                        <Seller
                                            implementationId={this.state.implementationId}
                                            implementationSellerList={this.state.implementationSellerList}
                                            onSaveSellerAndPageRefresh={this.onSaveSellerAndPageRefresh}
                                        />
                                    </Panel><br />
                                </>
                        }

                    </>
                    :
                    null
                }
            </>
        )
    }
}
export default withRouter(WorkflowImplementation)
