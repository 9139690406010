import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import Sidebar from '../../Common/Sidebar';
import UserDetails from './UserDetails';
import { Ripple } from 'primereact/ripple';

export default class NewUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            userId: 0,
            innerWidth: 1024,
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            userId: params.index,
        });
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    userTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    User Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
  render() {
    return (
        <div onClick={this.onBodyClick}>
                <div  className={this.state.sidebarShow ? "sidebar-mini sidebar-open wrapper" : "sidebar-mini sidebar-closed sidebar-collapse wrapper" }>

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i className="fas fa-bars"></i></a>
                            </li>

                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-widget="control-sidebar"
                                    data-slide="true"
                                    href="/"
                                    role="button"
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Add New User</h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">

                                                    <Panel headerTemplate={this.userTemplate} toggleable>
                                                        <UserDetails userId={this.state.userId}></UserDetails>
                                                    </Panel><br />

                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <footer className="main-footer">
                        <strong>
                            Copyright © 2021 <a href="https://adminlte.io">InsuranceGIG</a>.
                        </strong>{" "}
                        All rights reserved.
                    </footer>
                    <aside className="control-sidebar control-sidebar-dark">
                    </aside>
                </div>
            </div>
    )
  }
}
