import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../Common/Sidebar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import CommonValues from '../../Common/Util';
import { ProgressBar } from 'primereact/progressbar';
import { Redirect } from "react-router-dom";
import { AutoComplete } from 'primereact/autocomplete';
import Footer from '../../Common/Footer';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';

class CompanyMaster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            companyList: [],
            loading: true,
           
            innerWidth: 1024,
            searchedSelectedCompany:null,
            filteredCompanies:null
        }
    }
    componentDidMount = () => {
        this.getAllCompanyList('');
        window.addEventListener("resize", this.updateWindowSize);
        this.updateWindowSize();
     
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    checkAndApplySideBar = (innerWidth) => {

        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onRefreshClick = ()=>{
        this.setState({
            searchedSelectedCompany:null,
        })
        this.getAllCompanyList('');
    }
    getAllCompanyList = (searchText) => {
      
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    companyList: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();                       
                    }
                    else {
                        this.setState({
                            errorMessage:"Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    onKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.searchCompanies();
        }
    }
    onSearchClick = () => {
        if(this.state.searchedSelectedCompany.name ==null || this.state.searchedSelectedCompany.name == undefined){
            this.getAllCompanyList(this.state.searchedSelectedCompany);
        }else   {
            this.getAllCompanyList(this.state.searchedSelectedCompany.name);
        }
       
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    onNewBtnClicked = () => {
        this.props.history.push("/company/0")
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    imageBodyTemplate(rowData) {
        return <img src={rowData.iconURL} style={{ width: "50px" }} />;
    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.editCompanyContactDetails(rowData.companyId)} />
                &emsp;
                {/* <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.confirmDeleteProduct(rowData)} /> */}
            </React.Fragment>
        );
    }

    editCompanyContactDetails = (companyId) => {
        this.props.history.push(`/company/${companyId}`)

    }

    onCompanySelect =(e)=>{
       if(e.value !=null && e.value.companyId !=null && e.value != undefined && e.value.companyId !=undefined){

        if(e.value.companyId != 0){
            this.editCompanyContactDetails(e.value.companyId);
        }else{
            this.props.history.push("/company/0?name=" + this.state.searchedSelectedCompany);
        }
         
       }     
    }
    searchCompanies=(event)=>{
        setTimeout(() => {
            let filteredCompanies;
            if (!event.query.trim().length) {
                filteredCompanies = [...this.state.companyList];
            }
            else {
                filteredCompanies = this.state.companyList.filter((company) => {
                    return company.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            if(filteredCompanies.length == 0){
                filteredCompanies.push({ companyId:0,name:'Create new company - ' + event.query} );
            }
            this.setState({ filteredCompanies:filteredCompanies });
        }, 250);
    }
    render() {
        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.Logout();
        }
        
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <PageHeader PageTitle="Company Master"></PageHeader>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                                                        <div
                                                            className="input-group input-group-sm"

                                                        >
                                                            <AutoComplete  onSelect={this.onCompanySelect} value={this.state.searchedSelectedCompany} suggestions={this.state.filteredCompanies} onKeyDown={this.onKeyClick} completeMethod={this.searchCompanies} field="name" onChange={(e) => this.setState({ searchedSelectedCompany: e.value })} />

                                                            <div className="input-group-append company-search-btn">
                                                                <button type="submit" className="btn btn-default" onClick={this.onSearchClick}>
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 servicemain">
                                                        <Button label="Refresh" className='p-button-sm' icon="pi pi-sync" onClick={ this.onRefreshClick} />
                                                        &nbsp;
                                                        <Button label="New Company" className='p-button-sm' icon="pi pi-plus" onClick={this.onNewBtnClicked} />
                                                    </div>

                                                </div>

                                                <div className="card-tools">

                                                </div>
                                            </div>
                                            {
                                                this.state.loading ?
                                                    <>
                                                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                                        <div style={{ height: "600px" }}></div>
                                                    </>

                                                    :
                                                    <div className="card-body table-responsive p-0">
                                                        <div className ="d-none d-md-block">
                                                            <DataTable value={this.state.companyList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">
                                                                <Column body={this.imageBodyTemplate} header="LOGO" sortable ></Column>
                                                                <Column field="name" header="NAME" sortable></Column>
                                                                <Column field="website" header="WEBSITE" sortable ></Column>
                                                                <Column field="companyType" header="TYPE" sortable ></Column>
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>
                                                        <div className ="d-block d-md-none">
                                                            <DataTable value={this.state.companyList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">
                                                               
                                                                <Column field="name" header="NAME" sortable></Column>
                                                          
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>                                                        
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        )
    }
}

export default withRouter(CompanyMaster);
