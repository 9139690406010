import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import CommonValues from '../../Common/Util';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router-dom';
class GigDetailsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            userLoading: false,
            name: "",
            gigCode: "",
            company: "",
            user: "",
            companyList: [],
            userList: [],
            errorMessage: "",
            errorMessageName: "",
            errorMessageGigCode: "",
            errorMessageCompanyName: "",
            errorMessageUserName: "",
            successMessage: "",
            gigId: 0,
            btnloading:false
        }
    }
    componentDidMount = () => {

        this.getAllCompanyList('');

    }
    getGIGDetailsById = (id, flag, searchText) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow?id=${id}&flag=${flag}&searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    name: response.data[0].name,
                    gigCode: response.data[0].gigCode,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onCompanyChange = (e) => {
        this.setState({
            company: e.value
        });
    }
    onUserChange = (e) => {
        this.setState({
            user: e.value
        });
    }
    getAllCompanyList = (searchText) => {

        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyList: response.data,
                    loading: false
                });
                this.getAllUserList('');
                if (this.props.gigDetails != null && this.props.gigDetails.companyId != undefined) {
                    response.data.forEach(company => {
                        if (company.companyId == this.props.gigDetails.companyId) {
                            this.setState({
                                company: company
                            });
                        }
                    });
                }
            })

            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getAllUserList = (searchText) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/rtguser?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    userList: response.data,
                    loading: false,
                    gigCode: this.props.gigDetails != null ? this.props.gigDetails.gigCode : "",
                    name: this.props.gigDetails != null ? this.props.gigDetails.name : ""
                });
                if (this.props.gigDetails != null && this.props.gigDetails.userId != undefined) {
                    response.data.forEach(user => {
                        if (user.userId == this.props.gigDetails.userId) {
                            this.setState({
                                user: user
                            });
                        }
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onGigDetailsSaveClick = () => {
        this.setState({
            btnloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "Name": this.state.name,
            "GIGCode": this.state.gigCode,
            "CompanyId": this.state.company.companyId,
            "UserId": this.state.user.userId,
            "Id": this.props.gigDetails != null ? this.props.gigDetails.id : 0,
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigflow`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {


                this.setState({
                    successMessage: "Details added successfully...!!!",
                    btnloading: false,
                });
                window.location(`/gigworkflow/${response.data}`)

                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    1000
                );


            })
            .catch((error) => {
                this.setState({
                    btnloading: false
                });
                if (error.response != null) {
                    if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "company with the same name already exists"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s Something went wrong"
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }

                }
            });
    }
    render() {
        return (
            <>
                {this.state.loading ?
                    <div style={{ textAlign: "center" }}>
                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                    :
                    <>
                        <div className="row p-2" >
                            <div className='col-2'>
                                <label>Name</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    name="name"
                                    className={this.state.errorMessageName.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                    value={this.state.name}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                            </div>
                        </div>

                        <div className="row p-2" >
                            <div className='col-2'>
                                <label>GigCode</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    name="gigCode"
                                    className={this.state.errorMessageGigCode.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                    value={this.state.gigCode}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageGigCode}</small>
                            </div>
                        </div>

                        <div className="row p-2" >
                            <div className='col-2'>
                                <label>Company</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>

                                <Dropdown value={this.state.company}
                                    options={this.state.companyList}
                                    onChange={this.onCompanyChange}
                                    optionLabel="name"
                                    placeholder="Select"
                                    style={{ width: "100%" }}
                                    filter
                                    className={this.state.errorMessageCompanyName.length > 0 ? "p-invalid p-mr-2" : ""}
                                />

                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyName}</small>
                            </div>
                        </div>

                        <div className="row p-2" >
                            <div className='col-2'>
                                <label>User</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                {this.state.userLoading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    :
                                    <Dropdown value={this.state.user}
                                        options={this.state.userList}
                                        onChange={this.onUserChange}
                                        optionLabel="fullName"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        filter
                                        className={this.state.errorMessageUserName.length > 0 ? "p-invalid p-mr-2" : ""}
                                    />
                                }
                                <small id="username-help" className="p-error">{this.state.errorMessageUserName}</small>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10">
                                {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                    : null}
                                {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                    : null}
                            </div>
                            <div className="col-2">                               
                                <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} style={{ float: "right" }} onClick={this.onGigDetailsSaveClick} />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}
export default withRouter(GigDetailsPanel)