import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';

var axios = require('axios');

class Legal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            serviceId: 0,
            docType: "",
            legalDoc: "",
            errorMessage: "",
            successMessage: "",
            LegalDocUrl:""
        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onLegalDocumentSelect = (e) => {
        this.setState({ legalDoc: e.target.files[0] });
    }
    componentDidMount = () => {
        this.setState({
            serviceId: this.props.serviceId,
        })
        if (this.props.serviceId != 0) {
            this.getLegalDetails(this.props.serviceId, 'Legal')
        }
    }
    urlBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <a href={rowData.documentURL} target='_blank'  >Open Document</a>
            </React.Fragment>
        );
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="fa fa-trash " aria-hidden="true" onClick={(e) => this.onConfirmDeleteClick(e, rowData.id)} style={{ cursor: "pointer" }}></i>
            </React.Fragment>
        );
    }
    onConfirmDeleteClick = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Do you want to delete this legal document?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.onDeleteServiceDocuments(id),
            reject: this.reject
        });
    }
    onDeleteServiceDocuments = (id) => {
        this.setState({
            loading: true,
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/deleteservicedocument?id=${id}&serviceId=${this.props.serviceId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    loading: true,
                    successMessage: "Legal document deleted successfully."
                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getLegalDetails(this.props.serviceId, 'Legal')
              
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: true,
                })
            });
    }

    getLegalDetails = (Val, Type) => {
        this.setState({

            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getservicedocuments?serviceId=${Val}&DocumentType=${Type}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
             
                this.setState({
                    legalDocuments: response.data,
                    loading: false,

                });


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    checkValidation = () => {

        if (this.state.docType == "" || this.state.docType == null) {
            this.setState({
                inputTextNameErrorClass: true,
                errorMessageName: "Please enter legal document name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextNameErrorClass: false,
                    errorMessageName: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.legalDoc == "" || this.state.legalDoc == null) {
            this.setState({
                inputTextSelectDocClass: true,
                errorMessageSelectDoc: "Please select legal document "
            });
            setTimeout(() => {
                this.setState({
                    inputTextSelectDocClass: false,
                    errorMessageSelectDoc: "",
                });
            }, 3000);

            return false;
        }

        return true
    }
    onSaveServiceLegalClicked = () => {

        if (this.checkValidation()) {
            this.setState({
                btnloading: true
            });


            var token = CommonValues.GetToken();
            var FormData = require('form-data');
            var fs = require('fs');
            var data = new FormData();
            data.append('sourcefile', this.state.legalDoc);
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ServicesService_Url}/api/services/upload/screenshot?serviceId=${this.props.serviceId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({
                        LegalDocUrl: response.data
                    })
                    this.onSaveClicked();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false,
                        errorMessage: "Unknown error while uploading legal document."
                    });

                    let errorMessage = '';
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else {
                            errorMessage = "Unknown error while updating the legal document";
                        }
                    }
                    else {
                        errorMessage = "Unknown error while loading the legal document";
                    }
                    this.setState({ errorMessage: errorMessage });


                });

        }

    }
    onSaveClicked = () => {

        var token = CommonValues.GetToken();
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertscreenshotdocument?DocumentType=Legal&id=${this.props.serviceId}&Url=&Name=${this.state.docType}&json=${this.state.LegalDocUrl}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    btnloading: false,
                    successMessage: "Legal document uploaded successfully.",
                    docType: "",
                   
                    
                })

                this.getLegalDetails(this.props.serviceId, 'Legal')
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    btnloading: false
                });


            });

    }

    render() {
        return (
            <div>
             
                        <>
                            <div>
                                <label className="form-label">
                                    Document Name
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="text"
                                    id="docType"
                                    name="docType"
                                    value={this.state.docType}
                                    className={this.state.inputTextNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                            </div>
                            <div>
                                <label className="form-label">
                                    Select Legal Document
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="file"
                                    className={this.state.inputTextSelectDocClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    // name="file"
                                    onChange={this.onLegalDocumentSelect}
                                    name="legalDoc"
                                    accept="application/pdf"
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageSelectDoc}</small>
                            </div>
                            <br />
                            {
                                this.state.loading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    :
                                    <div className="form-row">
                                        {
                                            this.state.legalDocuments != null && this.state.legalDocuments.length > 0 ?
                                                <div className="col-12" >
                                                    <DataTable value={this.state.legalDocuments} paginator
                                                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                                        rows={5} responsiveLayout="scroll" >
                                                        <Column field="name" header=" Name" sortable></Column>
                                                        <Column body={this.urlBodyTemplate} header="Document" sortable></Column>
                                                        <Column body={this.actionBodyTemplate} header="Action" sortable></Column>
                                                    </DataTable>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                            }
                                <br />
                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">
                                    {/* <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onLegalSaveClick} style={{ float: "right" }} /> */}

                                    {
                                        this.state.serviceId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveServiceLegalClicked} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveServiceLegalClicked} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>

                        </>
              

            </div>
        );
    }
}
export default Legal;
