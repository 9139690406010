import React, { Component } from 'react';
import Footer from '../../Common/Footer';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import Sidebar from '../../Common/Sidebar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CommonValues from '../../Common/Util';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CanvasJSReact from '../../assets/canvasjs.react';
import { Redirect } from "react-router-dom";
import { AutoComplete } from 'primereact/autocomplete';
import { ProgressSpinner } from 'primereact/progressspinner';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Report extends Component {
  constructor(props) {
    super(props);
    const previousMonth = new Date();
    previousMonth.setMonth(previousMonth.getMonth() - 1)

    this.state = {
      sidebarShow: true,
      loading: true,
      serviceProviderLoading: false,
      innerWidth: 1024,
      companyListOption: [],
      companyName: "",
      serviceProviderList: [],
      serviceProviderIds: [],
      serviceProvider: "",
      fromDate: previousMonth,
      toDate: new Date(),
      searchData: false,
      noData: false,
      filteredCompany: null,
      costInDoller: ""
    };
    this.searchCountry = this.searchCountry.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
  }
  componentDidMount = () => {
    this.getAllCompanyList('');


  }
  onBarsClick = () => {
    this.setState({
      sidebarShow: !this.state.sidebarShow
    });
  }
  onCompanyNameChange = (e) => {
    this.setState({
      companyName: e.value,

    })
    if (e.value != null) {
      this.getServiceProviderList(e.value.companyId);
    }

  }

  onServiceProviderChange = (e) => {
    this.setState({ serviceProvider: e.value });
    this.setState({
      serviceProviderIds: e.value.map(element => element.id)
    });
  }
  onFromDateChage = (e) => {
    this.setState({ fromDate: e.value });
  }
  onToDateChage = (e) => {
    this.setState({ toDate: e.value });
  }
  getAllCompanyList = () => {
    this.setState({
      loading: true
    });
    var token = CommonValues.GetToken();
    var axios = require('axios');

    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_CompanyService_Url}/api/company/companyList?searchText=`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
    axios(config)
      .then((response) => {

        this.setState({
          companyListOption: response.data,

        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false
        });

        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          }
          else {
            this.setState({
              errorMessage: "Opp's something went wrong."
            })
            setTimeout(
              () => this.setState({ errorMessage: "" }),
              3000
            );
          }
        }
      });
  }
  getServiceProviderList = (id) => {
    if (id != null) {
      this.setState({
        serviceProviderLoading: true
      });

      var axios = require('axios');
      var token = CommonValues.GetToken();

      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_Report_Url}/api/report/serviceprovider?companyId=${id}`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }

      };

      axios(config)
        .then((response) => {
          const previousMonth = new Date();
          previousMonth.setMonth(previousMonth.getMonth() - 1)

          if (response.data.length > 0) {
            const Serviceproviderlist = [];
            response.data.forEach(element => {
              Serviceproviderlist.push(element.id)
            })

            this.setState({
              serviceProviderList: response.data,
              serviceProviderIds: Serviceproviderlist,
              serviceProvider: response.data,
              fromDate: previousMonth,
              toDate: new Date(),
            });

          }
          if (response.data.length < 1) {
            this.setState({
              serviceProvider: "",
              serviceProviderLoading: false
            })
          }
          else {
            this.setState({
              serviceProviderLoading: false
            });
          }

        })
        .catch((error) => {
          console.log(error);
          this.setState({
            pageLoading: false,
            serviceProviderLoading: false,
          });
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            }
          }
        });
    }
  }
  isValid = () => {
    if (this.state.companyName == null || this.state.companyName == '') {
      this.setState({
        inputTextCompanyNameErrorClass: true,
        errorMessageCompanyname: 'Please select company name'
      });
      setTimeout(() => {
        this.setState({
          inputTextCompanyNameErrorClass: false,
          errorMessageCompanyname: "",

        });
      }, 3000);
      return false;
    }

    if (this.state.serviceProviderIds == null || this.state.serviceProviderIds.length == 0) {
      this.setState({
        inputTextServiceProviderErrorClass: true,
        serviceProviderError: 'Please select service'
      });
      setTimeout(() => {
        this.setState({
          inputTextServiceProviderErrorClass: "",
          serviceProviderError: false,

        });
      }, 3000);
      return false;
    }

    if (this.state.fromDate == null || this.state.fromDate == '') {
      this.setState({
        inputTextFromDateErrorClass: true,
        fromDateError: 'Please select from date',
        emptyDateError: 'Please select from date'
      });
      setTimeout(() => {
        this.setState({
          inputTextFromDateErrorClass: false,
          fromDateError: "",
          emptyDateError: "",
        });
      }, 3000);
      return false;
    }
    if (this.state.toDate == null || this.state.toDate == '') {
      this.setState({
        inputTextToDateErrorClass: true,
        toDateError: 'Please select to date',
        emptyDateError: 'Please select to date'
      });
      setTimeout(() => {
        this.setState({
          inputTextToDateErrorClass: false,
          toDateError: "",
          emptyDateError: "",
        });
      }, 3000);
      return false;
    }
    if (this.state.fromDate > this.state.toDate) {
      this.setState({
        inputTextToDateErrorClass: true,
        toDateError: 'To date must be greater than from date'
      });
      setTimeout(() => {
        this.setState({
          inputTextToDateErrorClass: false,
          toDateError: "",
        });
      }, 3000);
      return false;
    }
    return true
  }
  onSearchClick = () => {
    if (this.isValid()) {
      this.setState({
        btnloading: true
      });

      var axios = require('axios');
      var token = CommonValues.GetToken();
      var data = JSON.stringify({
        "CompanyId": parseInt(this.state.companyName.companyId),
        "serviceproviderIds": this.state.serviceProviderIds,
        "fromDate": moment(this.state.fromDate).format('yyyy-MM-DD'),
        "toDate": moment(this.state.toDate).format('yyyy-MM-DD')
      })
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_Report_Url}/api/report/usage?`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then((response) => {
          if (response.data.listChartData.length > 0 && response.data.listData.length > 0) {
            this.setState({
              searchData: true
            })
          }
          this.setState({
            btnloading: false,

          })

          let chartDataList = [];
          if (response.data.listChartData.length > 1) {
            response.data.listChartData.forEach(element => {
              let dataPoints = [];

              element.dataPoints.forEach(d => {
                dataPoints.push({
                  y: d.y,
                  x: new Date(d.x)
                });
              });
              chartDataList.push({
                type: "stackedColumn",
                showInLegend: "true",
                legendText: element.legendText,
                dataPoints: dataPoints
              });

            });
          } else {
            response.data.listChartData.forEach(element => {
              let dataPoints = [];

              element.dataPoints.forEach(d => {
                dataPoints.push({
                  y: d.y,
                  x: new Date(d.x)
                });
              });
              chartDataList.push({
                color: "#4f81bc",
                dataPoints: dataPoints
              });

            });
          }
          let Totalcost = 0;
          response.data.listData.forEach(element => {
            Totalcost = Totalcost + (element.costInCents);

          });
       
          Totalcost = ((Totalcost) / 100).toFixed(2);
          let totalrequestmade = 0;
          response.data.listData.forEach(element => {
            totalrequestmade = totalrequestmade + parseInt(element.numberOfRequest);
          });

          response.data.listData.push({
            "service": "Total",
            "numberOfRequest": totalrequestmade,
            "requestTime": "",
            "company": "",
            "costInDollar": '$'+Totalcost
          });
          this.setState({
            serviceConsumptionList: response.data.listData,
            dataListForChart: chartDataList,
            pageLoading: false,
            totalRequest: totalrequestmade,
            costInDoller: Totalcost
          });

        })
        .catch((error) => {
          console.log(error);
          this.setState({
            btnloading: false
          });
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            }
          }
        });
    }
  }

  onExportClick = () => {
    if (this.isValid()) {
      this.setState({
        pageLoading: true
      });

      var axios = require('axios');
      var token = CommonValues.GetToken();
      var data = JSON.stringify({
        "CompanyId": parseInt(this.state.companyName.companyId),
        "serviceproviderIds": this.state.serviceProviderIds,
        "fromDate": moment(this.state.fromDate).format('yyyy-MM-DD'),
        "toDate": moment(this.state.toDate).format('yyyy-MM-DD')
      })
      var config = {
        method: 'post',
        responseType: 'blob', // important
        url: `${process.env.REACT_APP_Report_Url}/api/report/usageexport`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then((response) => {
          this.setState({
            pageLoading: false
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'usage.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();

        })
        .catch((error) => {
          console.log(error);
          this.setState({
            pageLoading: false
          });
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            }
          }
        });
    }
  }
  itemTemplate = (item) => {
    return (
      <div className="country-item">
        <div>{item.name}</div>
      </div>
    );
  }
  searchCountry = (event) => {
    setTimeout(() => {
      let filteredCompany;
      if (!event.query.trim().length) {
        filteredCompany = [...this.state.companyListOption];
      }
      else {
        filteredCompany = this.state.companyListOption.filter((company) => {
          return company.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      this.setState({ filteredCompany });
    }, 250);
  }
  formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
  balanceBodyTemplate = (rowData) => {
    if (rowData.costInDollar != null) {
      return this.formatCurrency(rowData.costInDollar);
    }

  }



  render() {

    const options = {
      animationEnabled: true,
      exportEnabled: false,
      dataPointWidth: 40,


      title: {
        text: ""
      },
      axisX: {
        title: "Dates",
        valueFormatString: "MM/DD/YYYY",
        interval: 1,
        intervalType: "day"
      },
      axisY: {
        title: "No. of Requests",
      },

      data: this.state.dataListForChart
    }

    const token = CommonValues.GetToken();
    if (token == null) {
      CommonValues.Logout();
    }


    return (
      <div onClick={this.onBodyClick}>
        <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
          <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
          <Sidebar></Sidebar>
          <div className="content-wrapper">
            <PageHeader PageTitle="Report"></PageHeader>
            <section className="content">
              <div className="container-fluid">

                <div className="row">
                  <div className="col-12">
                    <div className="card">

                      <div className="card-header">
                        <div className="row">

                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div
                              className="input-group input-group-sm  w-100"
                            >
                              <AutoComplete value={this.state.companyName}
                                suggestions={this.state.filteredCompany}
                                completeMethod={this.searchCountry}
                                field="name" dropdown forceSelection
                                onChange={this.onCompanyNameChange}
                                placeholder="Select Company Name"
                                className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2 w-100" : "w-100"}

                              />

                              <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div
                              className="input-group input-group-sm"
                            >
                              {
                                this.state.serviceProviderLoading ?

                                  <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />

                                  :
                                  <MultiSelect
                                    className={this.state.inputTextServiceProviderErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.serviceProvider}
                                    options={this.state.serviceProviderList}
                                    onChange={this.onServiceProviderChange}
                                    optionLabel="name"
                                    placeholder="Select a Service Provider"
                                    maxSelectedLabels={2}
                                    style={{ width: "100%" }} />
                              }
                            </div>
                            <small id="username-help" className="p-error">{this.state.serviceProviderError}</small>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                            <div
                              className="input-group input-group-sm"
                            >
                              <Calendar
                                className={this.state.inputTextFromDateErrorClass ? " p-invalid p-mr-2" : ""}
                                value={this.state.fromDate}
                                onChange={this.onFromDateChage}
                                errorMessage={this.state.fromDateError}
                                placeholder="From Date"
                              />
                              <small id="username-help" className="p-error">{this.state.fromDateError}</small>

                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                            <div
                              className="input-group input-group-sm"
                            >
                              <Calendar
                                className={this.state.inputTextToDateErrorClass ? " p-invalid p-mr-2" : ""}
                                value={this.state.toDate}
                                onChange={this.onToDateChage}
                                errorMessage={this.state.toDateError}
                                placeholder="To Date"
                              />
                              <small id="username-help" className="p-error">{this.state.toDateError}</small>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 servicemain">
                            {/* <Button label="Search" className='p-button-sm'  onClick={() => this.getAllCompanyList('')} /> */}
                            <Button label="Search" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSearchClick} />

                          </div>

                        </div>

                        <div className="card-tools">

                        </div>
                      </div>
                      {
                        this.state.totalRequest == 0 ?
                          <div className="pt-4 pb-4">
                            <div className="">
                              <h3 style={{ textAlign: "center" }}>No requests found</h3>
                            </div>
                          </div>
                          :
                          null}
                      {
                        this.state.pageLoading ?
                          <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                          </div>
                          :
                          <>
                            {
                              this.state.searchData ? <><div className="col-md-12">
                                {
                                  this.state.totalRequest == 0 ?

                                    null
                                    :
                                    <>
                                      {/* <div className="row mb-5 reports-charts">
                                <div className="col-md-4">
                                <i className="fa fa-cog icon"   style={{display:"inline-block"}}/ >
                                <h6 className=" mb-0 font-weight-bolder" style={{display:"inline-block"}} >{this.state.totalRequest}</h6>
                                      <span className="text-12 text-muted">Total Requests Made</span>
                                </div> */}

                                      {/* <div className="col-md-7">
                                  <div className="reports-charts">
                                    <div className="mr-1  p-3 icon">
                                      <i className="fa fa-cog" />
                                      
                                    </div>
                                    <div className="pl-2">
                                      <h6 className=" mb-0 font-weight-bolder">{this.state.totalRequest}</h6>
                                      <span className="text-12 text-muted">Total Requests Made</span>
                                    </div>
                                  </div>
                                </div> */}

                                      {/* </div> */}
                                      <h6 className=" mb-0 font-weight-bolder p-4" style={{ display: "inline-block" }}> <span className="text-12 text-muted">Total Requests Made:</span> {this.state.totalRequest}   </h6>
                                      <h6 className=" mb-0 font-weight-bolder p-4" style={{ display: "inline-block" }}> <span className="text-12 text-muted"> Cost:</span> ${this.state.costInDoller}   </h6>

                                      <CanvasJSChart options={options} className="ReportBar" /><hr />
                                    </>
                                }

                              </div>

                                {
                                  this.state.totalRequest == 0 ?

                                    null
                                    :

                                    <div className="col-md-12">
                                      <div className="">
                                        <div className="card-header d-flex align-items-center ">
                                          <h6 className="font-weight-medium pb-1 pt-1">Summary</h6>
                                          <Button label="Export" onClick={this.onExportClick} style={{ position: "absolute", right: "0" }} />
                                        </div>
                                        <div className="card-body" style={{ fontSize: "12px" }}>
                                          <DataTable value={this.state.serviceConsumptionList}
                                            responsiveLayout="scroll" className='demo'>
                                            <Column field="service" header="Service" ></Column>
                                            <Column field="numberOfRequest" header="# of Request"   ></Column>
                                            <Column field="requestTime" header="Request Date" ></Column>
                                            <Column field="company" header="Service Provider" ></Column>
                                            <Column field="costInDollar" header="Cost" body={this.balanceBodyTemplate} ></Column>

                                          </DataTable>

                                        </div>
                                      </div>

                                    </div>

                                }
                              </>

                                : null
                            }
                          </>
                      }
                    </div>
                  </div>
                </div>

              </div>
            </section>
          </div>
          <Footer></Footer>
        </div >
      </div >
    )
  }
}

