import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import ReactPlayer from 'react-player';
import { confirmPopup } from 'primereact/confirmpopup';


export default class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      serviceId: 0,
      selectVideo: "",
      videoData: "",
      errorMessage: "",
      successMessage: "",
    }
  }
  componentDidMount = () => {
    this.setState({
      serviceId: this.props.serviceId,
  })
  //  this.getServiceById(this.props.serviceId)
    if (this.props.serviceId != 0) {
      this.getVideoDetails(this.props.serviceId)
    }
  }
 
  checkValidation = () => {

    if (this.state.selectVideo == "" || this.state.selectVideo == null) {
      this.setState({
        inputTextVideoErrorClass: true,
        errorMessageSelectVideo: "Please select video"
      });
      setTimeout(() => {
        this.setState({
          inputTextVideoErrorClass: false,
          errorMessageSelectVideo: "",
        });
      }, 3000);

      return false;
    }

    return true
  }
  onVideoSaveClick = () => {

    if (this.checkValidation()) {
      this.setState({
        btnloading: true,

      })
      var axios = require('axios');
      var token = CommonValues.GetToken();
      var FormData = require('form-data');
      var fs = require('fs');
      var data = new FormData();
      data.append('sourcefile', this.state.selectVideo);

      var axios = require('axios');

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_ServicesService_Url}/api/services/upload/video?serviceId=${this.props.serviceId}`,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: data
      };

      axios(config)
        .then((response) => {
         
          this.setState({
            btnloading: false,
            selectVideo: "",
            successMessage: "Video uploaded successfully.",
         
          })
          setTimeout(
            () => this.setState({ successMessage: "" }),
            3000
        );

         this.getVideoDetails(this.props.serviceId)

        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorMessage: "Unknown error while uploading video.",
            btnloading: false
          })
          setTimeout(
            () => this.setState({ errorMessage: "" }),
            3000
        );
    })
    }
  }
  getVideoDetails = (id) => {
    this.setState({
      serviceId: id,
      loading: true
    });
    var token = CommonValues.GetToken();
    var axios = require('axios');

    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getbyid?id=${id}`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          videoData: response.data[0],
          loading: false,

        });


      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false
        });
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          }
        }
        else {
          this.setState({
            errorMessage: "Opp`s something went wrong"
          });
          setTimeout(
            () => this.setState({ errorMessage: "" }),
            3000
          );
        }
      });
  }
  onConfirmDeleteClick = (e) => {
    e.preventDefault();
    confirmPopup({
        target: e.currentTarget,
        message: 'Do you want to delete this video?',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => this.onDeleteVideoClick(),
        reject: this.reject
    });
}
onDeleteVideoClick = () => {
  var token = CommonValues.GetToken();
  var axios = require('axios');

  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertvideo?id=${this.props.serviceId}&Videourl=`,
      headers: {
          'Authorization': `Bearer ${token}`,
      }
  };
  this.setState({
      btndeleteloading: true,
  })

  axios(config)
      .then((response) => {
          this.setState({
            btndeleteloading: false,
              successMessage: "Video deleted successfully."
          })
          setTimeout(() => {
              this.setState({
                  successMessage: ""
              })
          }, 3000);
          this.getVideoDetails(this.props.serviceId)
      })
      .catch((error) => {
          console.log(error);
          this.setState({
            btndeleteloading: false,
              errorMessage: "Unknow error occured while deleting video."
          })
          setTimeout(() => {
            this.setState({
              errorMessage: ""
            })
        }, 3000);
      });

}
  onVideoSelect = (e) => {
    this.setState({ selectVideo: e.target.files[0] });
  }
  render() {
    return (
      <div>
        {
          this.state.loading ?
            <div style={{ textAlign: "center" }}>
              <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
            :
            <>
              <div>
                <label className="form-label">
                  Select Video
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input type="file"
                  className={this.state.inputTextVideoErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                  // name="file"
                  onChange={this.onVideoSelect}
                  name="selectVideo"
                />
                <small id="username-help" className="p-error">{this.state.errorMessageSelectVideo}</small>

              </div>
              <br />
              {
                this.state.videoData.explainerVideo == '' || this.state.videoData.explainerVideo == null ?
                  null
                  :
                  <>
                    <div className="test">
                      <ReactPlayer url={this.state.videoData.explainerVideo} style={{ width: "150px" , margin: "0 auto" }} controls={true} />
                    </div>
                  
                  </>
              }
                <br />
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                    : null}
                  {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                    : null}
                </div>
                <div className="col-md-5 col-sm-12 videodiv">

                  {
                    this.state.serviceId == 0 ?
                      <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onVideoSaveClick} style={{ float: "right" }} />
                      :
                      <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onVideoSaveClick} style={{ float: "right" }} />
                  }
                  {
                    this.state.videoData.explainerVideo == '' || this.state.videoData.explainerVideo == null ?
                      null
                      :

                      <Button label="Delete" className="mr-2" icon="pi pi-check" loading={this.state.btndeleteloading} onClick={this.onConfirmDeleteClick} style={{ float: "right", backgroundColor: "red", border: "red" }} />

                  }

                </div>
              </div>

            </>
        }

      </div>
    );
  }
}
