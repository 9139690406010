import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactPlayer from 'react-player';
import { confirmPopup } from 'primereact/confirmpopup';
import { Carousel } from 'primereact/carousel';
var axios = require('axios');

class MultipleVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            serviceId: 0,
            videoName: "",
            videoUrl: "",
            videoList: "",
            errorMessage: "",
            successMessage: "",
        }
    }
    componentDidMount = () => {
        this.setState({
            serviceId: this.props.serviceId,
        })
        if (this.props.serviceId != 0) {
            this.getVideoDetails(this.props.serviceId, 'Video')
        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    checkValidation = () => {
        if (this.state.videoName == "" || this.state.videoName == null) {
            this.setState({
                inputTextNameErrorClass: true,
                errorMessageName: "Please enter video name "
            });
            setTimeout(() => {
                this.setState({
                    inputTextNameErrorClass: false,
                    errorMessageName: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.videoUrl == "" || this.state.videoUrl == null) {
            this.setState({
                inputTextUrlErrorClass: true,
                errorMessageUrl: "Please enter video url"
            });
            setTimeout(() => {
                this.setState({
                    inputTextUrlErrorClass: false,
                    errorMessageUrl: "",
                });
            }, 3000);

            return false;
        }
        return true
    }
    onSaveVideoClicked = () => {
        if (this.checkValidation()) {
            this.setState({
                btnloading: true
            })
            var axios = require('axios');
            var token = CommonValues.GetToken();
            var config = {
                method: 'post',

                url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertscreenshotdocument?id=${this.props.serviceId}&Url=${this.state.videoUrl}&Name=${this.state.videoName}&DocumentType=Video`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            };

            axios(config)
                .then((response) => {

                    this.setState({
                        btnloading: false,
                        successMessage: "Video saved successfully.",
                        videoName:"",
                        videoUrl:""
                    })
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.getVideoDetails(this.props.serviceId, 'Video')
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false
                    })
                });

        }
    }
    getVideoDetails = (Val, Type) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getservicedocuments?serviceId=${Val}&DocumentType=${Type}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {

                this.setState({
                    video: response.data,
                    // videoList: response.data[0].documentURL,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();     
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    videoBodyTemplate = (rowData) => {
        return (

            <div className="" style={{ width: "200px", height: "100px" }} >
                <ReactPlayer width="100%"
                    height="100%"
                    url={rowData.documentURL}
                    controls={true}
                />
            </div>
        )
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="fa fa-trash " aria-hidden="true" onClick={(e) => this.onConfirmDeleteClick(e, rowData.id)} style={{ cursor: "pointer" }}></i>
            </React.Fragment>
        );
    }
    onConfirmDeleteClick = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Do you want to delete this video?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.onDeleteServiceDocuments(id),
            reject: this.reject
        });
    }
    onDeleteServiceDocuments = (id) => {
        this.setState({
            loading: true,
        })
        var token = CommonValues.GetToken();
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/deleteservicedocument?id=${id}&serviceId=${this.props.serviceId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    loading: false,
                    successMessage: "video deleted successfully."
                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getVideoDetails(this.props.serviceId, 'Video')

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }



    render() {

        return (
            <div>
                <div >
                    <label className="form-label">
                        Video Name
                        <span style={{ color: "red" }}>*</span>
                    </label>
                    <input type="text"
                        id="videoName"
                        name="videoName"
                        value={this.state.videoName}
                        className={this.state.inputTextNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                        onChange={this.onChange}
                    />
                    <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                </div>
                <div >
                    <label className="form-label">
                        Enter Url Of Video
                        <span style={{ color: "red" }}>*</span>
                    </label>
                    <input type="text"
                        id="videoUrl"
                        name="videoUrl"
                        value={this.state.videoUrl}
                        className={this.state.inputTextUrlErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                        onChange={this.onChange}
                    />
                    <small id="username-help" className="p-error">{this.state.errorMessageUrl}</small>
                </div>
<br/>
               

               

                <br />
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <div className="">
                            {
                                this.state.video != null && this.state.video.length > 0 ?
                                    <div className="col-12" >
                                        <DataTable value={this.state.video} paginator
                                            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                            rows={5} responsiveLayout="scroll" >
                                            <Column field="name" header=" Name" sortable></Column>
                                            <Column body={this.videoBodyTemplate} header="Video" sortable></Column>
                                            <Column body={this.actionBodyTemplate} header="Action" sortable></Column>
                                        </DataTable>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }



                <br />

                <div className="row">
                    <div className="col-10">
                        {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                            : null}
                        {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                            : null}
                    </div>
                    <div className="col-2">

                        {
                            this.state.serviceId == 0 ?
                                <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveVideoClicked} style={{ float: "right" }} />
                                :
                                <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveVideoClicked} style={{ float: "right" }} />
                        }


                    </div>
                </div>


            </div>
        )
    }
}
export default MultipleVideo;
