import React, { Component } from 'react';
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Footer from '../../Common/Footer';
import CommonValues from '../../Common/Util';

export default class UserMaster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            innerWidth: 1024,
            loading: false,
            userList: [],
            searchText:""

        }
    }
    componentDidMount = () => {
        this.getAllUserList('');
        window.addEventListener("resize", this.updateWindowSize);
        this.updateWindowSize();
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    checkAndApplySideBar = (innerWidth) => {

        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    checkAndApplySideBar = (innerWidth) => {

        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    getAllUserList = (searchText) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/user?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                console.log("User list: ", response.data);
                this.setState({
                    userList: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
   
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button p-mr-2 editbtn" onClick={() => this.editUserDetails(rowData.userId)} />
                &emsp;
                {/* <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.confirmDeleteService(rowData)} /> */}
            </React.Fragment>
        );
    }

    editUserDetails = (userId) => {
        this.props.history.push(`/user/${userId}`)
       
    }
    onUserNewBtnClicked = () => {
        this.props.history.push("/user/0")
    }
    onRefreshClick = ()=>{
        this.setState({
            searchText:"",
        })
        this.getAllUserList('');
    }
    onKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.onSearchClick();
        }
    }
    onSearchClick = () => {
        this.getAllUserList(this.state.searchText);
    }
    render() {
      
        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.Logout();
        }
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <PageHeader PageTitle="User Master"></PageHeader>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                                                        <div
                                                            className="input-group input-group-sm"
                                                        >
                                                            <input
                                                                type="text"
                                                                name="searchText"
                                                                className="form-control float-right"
                                                                placeholder="Search"
                                                                value={this.state.searchText}
                                                                onChange={this.onChange}
                                                                onKeyDown={this.onKeyClick}
                                                            />
                                                            <div className="input-group-append">
                                                                <button type="submit" className="btn btn-default" onClick={this.onSearchClick}>
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 servicemain">
                                                        <Button label="Refresh" className='p-button-sm' icon="pi pi-sync" onClick={this.onRefreshClick} />
                                                        &nbsp;
                                                        {/* <Button label="New User" className='p-button-sm' icon="pi pi-plus" onClick={this.onUserNewBtnClicked} /> */}
                                                    </div>

                                                </div>

                                                <div className="card-tools">

                                                </div>
                                            </div>
                                            {
                                                this.state.loading ?
                                                    <>
                                                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                                        <div style={{ height: "600px" }}></div>
                                                    </>

                                                    :
                                                    <div className="card-body table-responsive p-0">
                                                        <div className="d-none d-md-block">
                                                            <DataTable value={this.state.userList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">
                                                              
                                                                <Column field="fullName" header="NAME" sortable></Column>
                                                                <Column field="workEmail" header="Work Email" sortable></Column>
                                                                <Column field="companyName" header="Company Name" sortable></Column>
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>

                                                        <div className="d-block d-md-none">
                                                            <DataTable value={this.state.userList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">

                                                                <Column field="fullName" header="NAME" sortable></Column>
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        )
    }
}
