import React, { Component } from 'react'
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Footer from '../../Common/Footer';
import CommonValues from '../../Common/Util';
import moment from 'moment';

var axios = require('axios');

export default class BlogList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarShow: true,
      searchText: "",
      innerWidth: 1024,

    }
  }
  componentDidMount = () => {
    this.getBlogs("");
  }
  getBlogs = (searchText) => {
    this.setState({
      loading: true,

    });
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_Community_Url}/api/blogs/blogs?count=-1&search=${searchText}`,

    };
    axios(config)
      .then((response) => {

        this.setState({
          loading: false,
          blogList: response.data
        })

      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false
        });
      });
  }
  onRefreshClick = () => {
    this.setState({
      searchText: ""
    })
    this.getBlogs("");
  }
  onKeyClick = (e) => {
    if (e.keyCode == 13) {
      this.onSearchClick();
    }
  }
  onSearchClick = () => {
    this.getBlogs(this.state.searchText);
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  dateTemplate = (rowData) => {
    return (
      <React.Fragment >

        <span>{moment(rowData.publishDate).format("YYYY-MM-DD")} </span>
      </React.Fragment>
    );
  }
  thumbnailImageTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img src={rowData.thumbnailImage} width="70" height="70" alt="No Image uploaded" />
      </React.Fragment>
    );
  }
  backGroundImageTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img src={rowData.backGroundImage} width="100" height="70" alt="No Image uploaded" />
      </React.Fragment>
    );
  }
  actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.editBlogDetails(rowData.id)} />
      </React.Fragment>
    );
  }
  editBlogDetails = (blogId) => {
    this.props.history.push(`/blog/${blogId}`);
  }
  addNewBlog = () => {
    this.props.history.push(`/blog/0`);
  }
  descriptionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <p dangerouslySetInnerHTML={{ __html: rowData.shortDescription }} />
      </React.Fragment>
    );
  }
  render() {
    const token = CommonValues.GetToken();
    if (token == null) {
      CommonValues.Logout();
    }
    return (
      <div onClick={this.onBodyClick}>
        <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open wrapper" : " wrappersidebar-mini sidebar-closed sidebar-collapse"}>
          <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
          <Sidebar></Sidebar>
          <div className="content-wrapper">
            <PageHeader PageTitle="Blog Master"></PageHeader>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                            <div
                              className="input-group input-group-sm"
                            >
                              <input
                                type="text"
                                name="searchText"
                                className="form-control float-right"
                                placeholder="Search"
                                value={this.state.searchText}
                                onChange={this.onChange}
                                onKeyDown={this.onKeyClick}
                              />
                              <div className="input-group-append">
                                <button type="submit" className="btn btn-default" onClick={this.onSearchClick}>
                                  <i className="fas fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 servicemain">
                            <Button label="Refresh" className='p-button-sm' icon="pi pi-sync" onClick={this.onRefreshClick} />
                            &nbsp;
                            <Button label="New Blog" className='p-button-sm' icon="pi pi-plus" onClick={this.addNewBlog} />
                          </div>

                        </div>

                        <div className="card-tools">

                        </div>
                      </div>
                      {
                        this.state.loading ?
                          <>
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                            <div style={{ height: "600px" }}></div>
                          </>

                          :
                          <div className="card-body table-responsive p-0">
                            <div className="d-none d-md-block">
                              <DataTable value={this.state.blogList} paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={7} responsiveLayout="scroll">

                                <Column field="title" header=" Blog Name" sortable></Column>
                                <Column field={this.descriptionBodyTemplate} header=" Description" ></Column>
                                <Column body={this.thumbnailImageTemplate} header="Thumbnail"  ></Column>
                                <Column body={this.backGroundImageTemplate} header="Background"  ></Column>
                                <Column body={this.dateTemplate} header="Publish Date" headerStyle={{ width: '10rem' }} ></Column>
                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                              </DataTable>
                            </div>

                            <div className="d-block d-md-none">
                              <DataTable value={this.state.eventList} paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={7} responsiveLayout="scroll">

                                <Column field="title" header="Event Name" sortable></Column>
                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                              </DataTable>
                            </div>
                          </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer></Footer>
        </div>
      </div>
    )
  }
}

