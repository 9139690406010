import React, { Component } from 'react';
import CommonValues from '../Common/Util';

class TopNavBar extends Component {
    onBarsClick = () => {
      this.props.BarsClick();
    }
    onLogoutClick=()=>{
        CommonValues.Logout();
    }

    render() {
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i class="fas fa-bars"></i></a>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-widget="control-sidebar"
                            data-slide="true"
                           onClick={this.onLogoutClick}
                            role="button"
                        >
                            Logout
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}
export default TopNavBar;
