import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import GigDetailsPanel from './GigDetailsPanel';
import EndpointDetailsPanel from './EndpointDetailsPanel';
import CommonValues from '../../Common/Util';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

export default class GIGDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: true,
            innerWidth: 1024,
            loading: false,
            gigEndpoints: [],
            errorMessage: "",
            successMessage: "",
            addEndpointShow: false,
            eploading: false,
            gigCode:""
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            gigId: params.index,
        });
        if (params.index != 0) {
            this.getGIGDetailsById(params.index, 'getbyid', '')
        }
    }
    getGIGDetailsById = (id, flag, searchText) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow?id=${id}&flag=${flag}&searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    gigDetails: response.data[0],
                    gigCode: response.data[0].gigCode,
                    loading: false
                });
                this.getGIGEndpointsByGIGCode(response.data[0].gigCode);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getGIGEndpointsByGIGCode = (gigCode) => {
        this.setState({
            eploading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/GIGWorkflow/gigendpointbygigid?gigCode=${gigCode}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    eploading: false,
                    gigEndpoints: response.data.sort((a, b) => (a.sequenceNo > b.sequenceNo) ? 1 : -1)
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    eploading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    GIGWorkflowTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    GIG Flow Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    EndpointTemplate(options, val) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    GIGBlock - {val != undefined ? val.endpointCode+' ('+ val.name +')' : 'Add New'}
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    onAddEnpointClick = () => {
        this.setState({
            addEndpointShow: !this.state.addEndpointShow
        })
    }
    onEndpointGetAddedOrUpdated = () => {
        this.setState({
            addEndpointShow: false
        })
        this.getGIGEndpointsByGIGCode(this.state.gigCode)
    }
    
    render() {
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <PageHeader PageTitle=""></PageHeader>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <Panel headerTemplate={this.GIGWorkflowTemplate} toggleable>
                                                        <GigDetailsPanel
                                                            gigDetails={this.state.gigDetails}></GigDetailsPanel>
                                                    </Panel>
                                                </div>
                                                <br />

                                                {
                                                    this.state.eploading ?
                                                        <div style={{ textAlign: "center" }}>
                                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                this.state.gigEndpoints.length > 0 ? this.state.gigEndpoints.map((endpoint, i, arr) => {
                                                                    return (
                                                                        <>
                                                                            <div className="row">
                                                                                <Panel headerTemplate={(options) => this.EndpointTemplate(options, endpoint)} toggleable collapsed>
                                                                                    <EndpointDetailsPanel
                                                                                        endpoint={endpoint}
                                                                                        gigCode={this.state.gigCode}
                                                                                        onEndpointGetAddedOrUpdated={this.onEndpointGetAddedOrUpdated}></EndpointDetailsPanel>
                                                                                </Panel>
                                                                            </div>
                                                                            {arr.length - 1 != i ?
                                                                                <div style={{ textAlign: "center" }}>
                                                                                    <i className="pi pi-arrow-down"></i>
                                                                                </div>
                                                                                : null}
                                                                        </>
                                                                    )
                                                                })
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                }
                                                {
                                                    this.state.addEndpointShow ?

                                                        <>
                                                            <div style={{ textAlign: "center" }}>
                                                                <i className="pi pi-arrow-down"></i>
                                                            </div>
                                                            <div className="row">
                                                                <Panel headerTemplate={this.EndpointTemplate} toggleable >
                                                                    <EndpointDetailsPanel
                                                                        onCancelClick={this.onAddEnpointClick}
                                                                        gigCode={this.state.gigCode}
                                                                        onEndpointGetAddedOrUpdated={this.onEndpointGetAddedOrUpdated}></EndpointDetailsPanel>
                                                                </Panel>
                                                            </div>
                                                        </>

                                                        :
                                                        null
                                                }

                                                <br />
                                                <div className="row">
                                                    <div className="col-10">
                                                        {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                                            : null}
                                                        {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                                            : null}
                                                    </div>
                                                    {
                                                        this.state.addEndpointShow ? null :
                                                            <div className="col-2">
                                                                <Button label="Add Endpoint" icon="pi pi-plus" style={{ float: "right" }} onClick={this.onAddEnpointClick} />
                                                            </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        )
    }
}
