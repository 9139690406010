import React, { Component } from 'react';
import Footer from '../../Common/Footer';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import Sidebar from '../../Common/Sidebar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CommonValues from '../../Common/Util';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CanvasJSReact from '../../assets/canvasjs.react';
import { Redirect } from "react-router-dom";
import { AutoComplete } from 'primereact/autocomplete';
import { ProgressSpinner } from 'primereact/progressspinner';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class WorkflowDashboard extends Component {
  constructor(props) {
    super(props);
    const previousMonth = new Date();
    previousMonth.setMonth(previousMonth.getMonth() - 1)

    this.state = {
      sidebarShow: true,
      loading: true,
      innerWidth: 1024,
      fromDate: previousMonth,
      toDate: new Date(),
      searchData: false,
      noData: false,
      costInDoller: "",
      WorkflowDashboardData: []
    };
    this.searchCountry = this.searchCountry.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
  }
  componentDidMount = () => {
    this.onSearchClick();


  }
  onBarsClick = () => {
    this.setState({
      sidebarShow: !this.state.sidebarShow
    });
  }




  onFromDateChage = (e) => {
    this.setState({ fromDate: e.value });
  }
  onToDateChage = (e) => {
    this.setState({ toDate: e.value });
  }


  isValid = () => {

    if (this.state.fromDate == null || this.state.fromDate == '') {
      this.setState({
        inputTextFromDateErrorClass: true,
        fromDateError: 'Please select from date',
        emptyDateError: 'Please select from date'
      });
      setTimeout(() => {
        this.setState({
          inputTextFromDateErrorClass: false,
          fromDateError: "",
          emptyDateError: "",
        });
      }, 3000);
      return false;
    }
    if (this.state.toDate == null || this.state.toDate == '') {
      this.setState({
        inputTextToDateErrorClass: true,
        toDateError: 'Please select to date',
        emptyDateError: 'Please select to date'
      });
      setTimeout(() => {
        this.setState({
          inputTextToDateErrorClass: false,
          toDateError: "",
          emptyDateError: "",
        });
      }, 3000);
      return false;
    }
    if (this.state.fromDate > this.state.toDate) {
      this.setState({
        inputTextToDateErrorClass: true,
        toDateError: 'To date must be greater than from date'
      });
      setTimeout(() => {
        this.setState({
          inputTextToDateErrorClass: false,
          toDateError: "",
        });
      }, 3000);
      return false;
    }
    return true
  }

  onSearchClick = () => {
    if (this.isValid()) {
      this.setState({
        btnloading: true
      });

      var axios = require('axios');
      var token = CommonValues.GetToken();
      var data = JSON.stringify({

        "fromDate": moment(this.state.fromDate).format('yyyy-MM-DD'),
        "toDate": moment(this.state.toDate).format('yyyy-MM-DD')
      })
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_Report_Url}/api/report/workflowdashboard?`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then((response) => {

          this.setState({
            btnloading: false
          });

          this.setState({
            WorkflowDashboardData: response.data
          });

        })
        .catch((error) => {
          console.log(error);
          this.setState({
            btnloading: false
          });
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            }
          }
        });
    }
  }



  itemTemplate = (item) => {
    return (
      <div className="country-item">
        <div>{item.name}</div>
      </div>
    );
  }
  searchCountry = (event) => {
    setTimeout(() => {
      let filteredCompany;
      if (!event.query.trim().length) {
        filteredCompany = [...this.state.companyListOption];
      }
      else {
        filteredCompany = this.state.companyListOption.filter((company) => {
          return company.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      this.setState({ filteredCompany });
    }, 250);
  }

  formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  constInCentsBodyTemplate = (rowData) => {
    if (rowData.constInCents != null) {
      return this.formatCurrency(rowData.constInCents/100.0);
    }
  }

  totalCostInCentsBodyTemplate =(rowData)=>{
    if (rowData.totalCostInCents != null) {
      return this.formatCurrency(rowData.totalCostInCents/100.0);
    }
  }


  totalDevCostWorkflowBodyTemplate=(rowData)=>{
    if (rowData.totalDevCostWorkflow != null) {
      return this.formatCurrency(rowData.totalDevCostWorkflow);
    }
  }


  render() {

    const options = {
      animationEnabled: true,
      exportEnabled: false,
      dataPointWidth: 40,


      title: {
        text: ""
      },
      axisX: {
        title: "Dates",
        valueFormatString: "MM/DD/YYYY",
        interval: 1,
        intervalType: "day"
      },
      axisY: {
        title: "No. of Requests",
      },

      data: this.state.dataListForChart
    }

    const token = CommonValues.GetToken();
    if (token == null) {
      CommonValues.Logout();
    }


    return (
      <div onClick={this.onBodyClick}>
        <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
          <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
          <Sidebar></Sidebar>
          <div className="content-wrapper">
            <PageHeader PageTitle="Workflow Dashboard"></PageHeader>
            <section className="content">
              <div className="container-fluid">

                <div className="row">
                  <div className="col-12">
                    <div className="card">

                      <div className="card-header">
                        <div className="row">

                          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                            <div
                              className="input-group input-group-sm"
                            >
                              <Calendar
                                className={this.state.inputTextFromDateErrorClass ? " p-invalid p-mr-2" : ""}
                                value={this.state.fromDate}
                                onChange={this.onFromDateChage}
                                errorMessage={this.state.fromDateError}
                                placeholder="From Date"
                              />
                              <small id="username-help" className="p-error">{this.state.fromDateError}</small>

                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
                            <div
                              className="input-group input-group-sm"
                            >
                              <Calendar
                                className={this.state.inputTextToDateErrorClass ? " p-invalid p-mr-2" : ""}
                                value={this.state.toDate}
                                onChange={this.onToDateChage}
                                errorMessage={this.state.toDateError}
                                placeholder="To Date"
                              />
                              <small id="username-help" className="p-error">{this.state.toDateError}</small>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 servicemain">
                            <Button label="Search" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSearchClick} />

                          </div>

                        </div>

                      </div>

                      {
                        this.state.btnloading ?
                          <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                          </div>
                          :
                          <>
                            {this.state.WorkflowDashboardData.length == 0 ?

                              <div className="pt-4 pb-4">
                                <div className="">
                                  <h3 style={{ textAlign: "center" }}>No requests found</h3>
                                </div>
                              </div>
                              :

                              <div className="col-md-12">
                                <div className="">
                                  <div className="card-header d-flex align-items-center ">
                                    <h6 className="font-weight-medium pb-1 pt-1">Summary</h6>
                                    <Button label="Export" onClick={this.onExportClick} style={{ position: "absolute", right: "0" }} />
                                  </div>
                                  <div className="card-body" style={{ fontSize: "12px" }}>
                                    <DataTable value={this.state.WorkflowDashboardData}
                                       className='demo' scrollable scrollDirection="both" scrollHeight="600px" >
                                      <Column field="status" header="Status" style={{ flexGrow: 1, flexBasis: '150px' }}></Column>
                                      <Column field="workflowType" header="Category"   style={{ flexGrow: 1, flexBasis: '50px' }}></Column>
                                      <Column field="title" header="Name"   style={{ flexGrow: 1, flexBasis: '200px' }}></Column>
                                      <Column field="shortDescription" header="Description" style={{ flexGrow: 1, flexBasis: '350px' }}></Column>
                                      <Column field="constInCents" header="Average $ Cost Per Workflow Transaction" style={{ flexGrow: 1, flexBasis: '150px' }} body={this.constInCentsBodyTemplate}></Column>
                                      <Column field="totalDevCostWorkflow" header="Engineering Cost to Develop" style={{ flexGrow: 1, flexBasis: '150px' }} body={this.totalDevCostWorkflowBodyTemplate}></Column>
                                      <Column field="totalCostInCents" header="12 Month ARR" style={{ flexGrow: 1, flexBasis: '150px' }} body={this.totalCostInCentsBodyTemplate}></Column> 

                                      {/* <Column field="seller" header="Seller" style={{ flexGrow: 1, flexBasis: '200px' }}></Column> */}
                                      {/* <Column field="buyer" header="Buyer" style={{ flexGrow: 1, flexBasis: '200px' }}></Column>
                                      <Column field="numberOfRequest" header="# Transactions" style={{ flexGrow: 1, flexBasis: '200px' }}></Column>                                   
                                      <Column field="igComission" header="IG Fee %" style={{ flexGrow: 1, flexBasis: '150px' }}></Column> */}
                                      {/* <Column field="totalCostInCents" header="IG Revenue" style={{ flexGrow: 1, flexBasis: '150px' }} body={this.totalCostInCentsBodyTemplate}></Column> */}
                                      {/* <Column field="tShirtSize" header="TShirt Size" style={{ flexGrow: 1, flexBasis: '150px' }}></Column>
                                      <Column field="additionalHourPerImpelemenation" header="Add'l Implementation Dev Hours / Workflow (each)" style={{ flexGrow: 1, flexBasis: '200px' }}></Column>
                                      <Column field="totalGrowthHours" header="Total Growth Hours" style={{ flexGrow: 1, flexBasis: '150px' }}></Column> */}
                                      {/* <Column field="totalHours" header="Total Hours" style={{ flexGrow: 1, flexBasis: '150px' }}></Column> */}
                                      {/* <Column field="devCostPerHourInDollar" header="Dev Cost/Hours" style={{ flexGrow: 1, flexBasis: '200px' }}></Column> */}
                                    
                                    </DataTable>

                                  </div>
                                </div>

                              </div>

                            }
                          </>
                      }
                    </div>
                  </div>
                </div>

              </div>
            </section>
          </div>
          <Footer></Footer>
        </div >
      </div >
    )
  }
}

