import React, { Component } from 'react'
import Sidebar from '../../Common/Sidebar';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button'
import CommonValues from '../../Common/Util';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Ripple } from 'primereact/ripple';
import { Message } from 'primereact/message';

export default class GigCodeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: true,
            innerWidth: 1024,
            pageText: 'Generate Coupoun page',
            coupouncodedata: null,
            pfcompanies: [],
            checkedStatus: false,
            inputTextErrorClassUname: "",
            errorMessageUname: "",
            id: 0,
            pfdata: [],
            saveclick: false,
            coupounPartnerids: "",
            matchingValues: [],
            inputTextErrorClassUname: "",
            inputTextErrorClasspfUname: "",
            errorMessagepfUname: "",
            isEdit: false,
            errorMessage: "",
            logoimage: "",
            errorMessageforcoupon: "",
            errorMessage: "",
            successMessage: "",
            issaved: false,
            gigcodeupdatemessage: ""

        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            id: params.index,
        });
        if (params.index == 0) {
            this.setState({ pageText: 'Add GigCode ' })

        } else {
            this.setState({ pageText: 'Edit GigCode' })
        }
        this.getAllPfCompanies();
        window.addEventListener("resize", this.updateWindowSize);
        this.updateWindowSize();
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.id != this.props.id) {

            this.getAllCoupounDetailsById(this.props.id);
        }
    }

    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    checkAndApplySideBar = (innerWidth) => {
        if (this.state.sidebarShow) {
            if (innerWidth < 1024) {
                this.setState({
                    sidebarShow: !this.state.sidebarShow
                });
            }
        }
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    onBodyClick = () => {
        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }

    getAllPfCompanies = () => {
        this.setState({
            isEdit: true
        })
        var token = CommonValues.GetToken();

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/premiumsetting`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({

                    pfcompanies: response.data
                })

                if (this.state.id == 0) {
                    this.setState({
                        isEdit: false
                    })
                } else {
                    this.getAllCoupounDetailsById(this.state.id)
                }
            })
            .catch((error) => {
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }


            });

    }

    getAllCoupounDetailsById = (id) => {
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/coupondetailsbyid?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    isEdit: false
                })

                this.setState({
                    pfdata: response.data[0],
                    coupounid: response.data[0].id,
                    coupouncodedata: response.data[0].couponCode,
                    checkedStatus: response.data[0].isActive,
                    logoimage: response.data[0].fileUrl,
                    coupounPartnerids: response.data[0].pfPartnerId,


                })
                if (response.data[0].isActive == "Yes") {
                    this.setState({
                        checkedStatus: true
                    })
                }
                this.state.coupounPartnerids = this.state.coupounPartnerids.split(",")

                const companyType = []
                if (this.state.pfcompanies != null && response.data[0] != null) {
                    this.state.pfcompanies.forEach(item1 => {
                        this.state.coupounPartnerids.forEach(item2 => {
                            if (item2 === item1.id.toString()) {
                                companyType.push(item1)
                            }
                        });
                    });
                }
                this.setState({
                    matchingValues: companyType,
                    isEdit: false

                })

            })
            .catch((error) => {

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            isEdit: false
                        })
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }

                }

            });
    }


    onCompanyTypeChange = (e) => {
        this.setState({
            inputTextErrorClasspfUname: "",
            errorMessagepfUname: " "
        });

        this.setState({
            matchingValues: e.value
        })
        this.setState({
            coupounPartnerids: e.value.map(element => element.id)
        });
    }


    isValid = () => {

        this.setState({
            inputTextErrorClassUname: "",
            errorMessageUname: "",
            inputTextErrorClasspfUname: "",
            errorMessagepfUname: "",
            errorMessage: "",
            errorMessageforcoupon: "",
            successMessage: "",
            gigcodeupdatemessage: ""

        });
        let valid = true;
        if (this.state.matchingValues.length == 0) {
            this.setState({
                inputTextErrorClasspfUname: "p-invalid p-mr-2",
                errorMessagepfUname: "Please Enter PF Company Name "
            });
            valid = false;
        }


        if (this.state.coupouncodedata == "" || this.state.coupouncodedata == null) {
            this.setState({
                inputTextErrorClassUname: "form-control p-invalid p-mr-2",
                errorMessageUname: "Please Enter GigCode ",
                errorMessageforcoupon: ""

            });

            valid = false;
        }
        else {
            const regex = /^[a-zA-Z\d]{1,50}$/
            if (regex.test(this.state.coupouncodedata) === false) {
                this.setState({

                    errorMessageforcoupon: "Please Enter Valid GigCode"
                });
                valid = false;
            }

        }

        return valid

    }

    onSaveClicked = () => {
        if (this.isValid()) {

            this.setState({
                issaved: true
            });

            var token = CommonValues.GetToken();
            var axios = require('axios');

            var data = JSON.stringify({
                "id": this.state.coupounid,
                "couponCode": this.state.coupouncodedata,
                "isActive": ((this.state.checkedStatus).toString() == "true") ? "Yes" : "No",
                "pfPartnerId": this.state.coupounPartnerids.toString(),
                "fileUrl": this.state.logoimage

            })

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/insertcoupondetails`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`

                },
                data: data
            };
            axios(config).then((response) => {
                if (this.state.id == 0) {
                    this.setState({
                        issaved: false,

                        successMessage: "GigCode Added successfully...!!!",
                    });
                }
                else {
                    this.setState({
                        issaved: false,
                        gigcodeupdatemessage: "GigCode Updated successfully...!!!",

                    })
                }

                setTimeout(
                    () => {
                        this.setState({ successMessage: "" })
                        this.setState({ gigcodeupdatemessage: "" })
                    }
                    ,
                    6000
                );
                window.location = `/gigcodedetails/${response.data}`;


            }).catch((error) => {
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if (error.response.status == 409) {
                        this.setState({
                            errorMessagesamecoupon: "GigCode is already exist",
                            issaved: false

                        })
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong",
                            issaved: false
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );


                    }
                }

            });
        }
    }

    onUploadFile = (e) => {
        this.setState({
            logoLoading: true,
            couponfileurl: e.target.files[0].name

        });

        var axios = require('axios');
        var token = CommonValues.GetToken();
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('logofile', e.target.files[0]);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/uploadlogo?couponcode=${this.state.coupouncodedata}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    logoimage: response.data,
                    logoLoading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    logoLoading: false,
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }


            });
    }


    onChangeCoupounCode = (e) => {
        this.setState({
            inputTextErrorClassUname: "",
            errorMessageUname: " ",
            errorMessageforcoupon: "",
            errorMessagesamecoupon: "",
            issaved: false

        });

        this.setState({
            coupouncodedata: e.target.value,
        });

    }
    detailsTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    GigCode Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    render() {

        return (

            <div onClick={this.onBodyClick}>

                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item hamburgericon">
                                <a className="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i className="fas fa-bars"></i></a>
                            </li>

                        </ul>
                        <ul className="navbar-nav ml-auto">

                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-widget="control-sidebar"
                                    data-slide="true"
                                    href="/"
                                    role="button"
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">



                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>{this.state.pageText}</h1>
                                    </div>

                                </div>
                            </div>

                        </section>

                        <section className="content">
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>



                                                <div className="col-md-10">
                                                    <Panel headerTemplate={this.detailsTemplate} toggleable>

                                                        {
                                                            this.state.isEdit ?
                                                                <div style={{ textAlign: "center" }}>
                                                                    <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                </div>

                                                                :
                                                                <>
                                                                    <div>
                                                                        <label className="form-label">
                                                                            GigCode
                                                                        </label><span style={{ color: "red" }}>*</span>
                                                                        <div className='text-box-sizing' style={{ display: 'flex', alignItems: 'center' }}>

                                                                            <input type="text" icon="pi pi-check" placeholder='Enter GigCode Here' className={this.state.inputTextErrorClassUname ? "form-control p-invalid p-mr-2" : "form-control"} value={this.state.coupouncodedata} onChange={this.onChangeCoupounCode}
                                                                            />
                                                                        </div>
                                                                        <small id="username-help" className="p-error">{this.state.errorMessageUname}</small>
                                                                        <small id="username-help" className="p-error">{this.state.errorMessagesamecoupon}</small>

                                                                        <small id="username-help" className="p-error">{this.state.errorMessageforcoupon}</small>

                                                                    </div><br />



                                                                    <div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <label className="form-label">
                                                                            Is Active

                                                                        </label>
                                                                        <InputSwitch style={{ height: '25px', marginLeft: "10px" }}
                                                                            checked={this.state.checkedStatus}

                                                                            onChange={(e) => this.setState({ checkedStatus: e.value })}
                                                                        />
                                                                    </div><br />



                                                                    <div className="row">
                                                                        <div className='col-6'>
                                                                            <label className="form-label">Logo File</label><br />
                                                                            {
                                                                                this.state.logoLoading ?
                                                                                    <div style={{ textAlign: "center" }}>
                                                                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                                    </div>
                                                                                    :
                                                                                    (

                                                                                        this.state.logoimage ?
                                                                                            <>
                                                                                                <img src={this.state.logoimage}

                                                                                                    width="150" height="150"


                                                                                                />
                                                                                            </>

                                                                                            :
                                                                                            <>

                                                                                                <span>No Image uploaded</span><br />
                                                                                                <></>
                                                                                            </>
                                                                                    )
                                                                            }
                                                                        </div>


                                                                        <div className="col-6">
                                                                            <br />
                                                                            <label htmlFor="file" className="input input-file">
                                                                                <div className="button">
                                                                                    <input type="file" className="form-control form-control-sm" onChange={this.onUploadFile}
                                                                                        name="choosedFile" accept="image/png,image/jfif, image/jpeg,image/jpg" /> Browse
                                                                                </div>

                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder="No Files Selected"
                                                                                    readOnly
                                                                                    value={this.state.couponfileurl} />
                                                                            </label>
                                                                        </div>
                                                                    </div><br />

                                                                    <div >
                                                                        <label className="form-label">

                                                                            PF Companies
                                                                            <span style={{ color: "red" }}>*</span>
                                                                        </label>

                                                                        <MultiSelect
                                                                            value={this.state.matchingValues}
                                                                            onSelect={this.handleSelectSuggestion}
                                                                            options={this.state.pfcompanies}
                                                                            onChange={this.onCompanyTypeChange}
                                                                            optionLabel="name"
                                                                            placeholder="Please Enter PF Company Name"
                                                                            maxSelectedLabels={5}
                                                                            style={{ width: "100%" }}
                                                                            className={this.state.inputTextErrorClasspfUname ? " p-invalid p-mr-2" : ""}

                                                                        />
                                                                        <small id="username-help" className="p-error">{this.state.errorMessagepfUname}</small>

                                                                    </div><br />


                                                                    <div className="row">

                                                                        <div className="col-10">
                                                                            {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                                                                : null}
                                                                            {this.state.gigcodeupdatemessage != "" ? <Message severity="success" text={this.state.gigcodeupdatemessage} style={{ float: "right" }} />
                                                                                : null}

                                                                            {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                                                                : null}

                                                                        </div>
                                                                        <div className="col-2">

                                                                            {
                                                                                this.state.id == 0 ?
                                                                                    <>
                                                                                        {

                                                                                            this.state.issaved ? (
                                                                                                <div style={{ textAlign: "center" }}>
                                                                                                    <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                                                </div>)

                                                                                                :
                                                                                                <Button label="Save" icon="pi pi-check" onClick={() => this.onSaveClicked()} style={{ float: "right" }} />

                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {

                                                                                            this.state.issaved ? (
                                                                                                <div style={{ textAlign: "center" }}>
                                                                                                    <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                                                </div>)
                                                                                                :
                                                                                                < Button label="Update" icon="pi pi-check" onClick={this.onSaveClicked} style={{ float: "right", marginLeft: "200px" }} />
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </>

                                                        }

                                                    </Panel>
                                                    <div className="col-md-1">
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>

                    </div>
                </div >
            </div >

        )
    }
}
