import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import Sidebar from '../../Common/Sidebar';
import UserDetails from '../UserMaster/UserDetails';
import { Ripple } from 'primereact/ripple';
import { AutoComplete } from 'primereact/autocomplete';
import CommonValues from '../../Common/Util';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
var axios = require('axios');
export default class RTGNewUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            userId: 0,
            innerWidth: 1024,
            companyListOption: [],
            companyListId: [],
            companyName: "",
            companyNameId: "",
            companyNameTest: "",
            serviceType: "",
            filteredCompany: null,
            serviceName: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            email: "",
            activeUser: false,
            supportUser: false,
            allowUserTotakeControl: false,
            companySettings: "",
            typeOfWidget: "",
            widgetTypeList: [],

            selectedWidgetType: [],
            typeOfWidget: "",
            widgetTypeIds: [],
            searchText: "",
            loading: false,
            loadingWidget: false,
            loadingSave: false,
            showAddCompanyPopup: false,
            errorMessageCompanyName: "",
            inputHeaderTextErrorClass: "",
            company: "",
            npn: "",
            agencyname: "",
            loadingNPN: false,
            showNPNPop: false,
            NPNMessageForOK: "",
            NPNMessageForNotOK: "",

            filters1: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'representative': { value: null, matchMode: FilterMatchMode.IN },
                'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
            }

        }
    }
    componentDidMount = () => {
        this.getAllRTGCompanyList('');
        this.getAllCompanyList('');

        const {
            match: { params },
        } = this.props;
        this.setState({
            userId: params.index,
        });
        this.getAllWidgetList();
    }
    OnSaveClick = () => {
        if (!this.checkValidation()) {
            return false;
        }
        var token = CommonValues.GetToken();


        let list = [];
        this.state.widgetTypeList.forEach(item => {
            if (item.isAssigned == true) {
                list.push({
                    "widgetId": item.widgetId
                })
            }
        })


        const stateValueWidgets =
            list.map((element) => {
                return element.widgetId;
            }).join(',');

        this.setState({ loadingSave: true })

        var axios = require('axios');
        var data = JSON.stringify({
            "FirstName": this.state.firstName,
            "Lastname": this.state.lastName,
            "CompanyName": this.state.companyName.name,
            "FullName": "",
            "Password": "",
            "Email": this.state.email,
            "Active": true,
            "IsSupportUser": false,
            "AllowIGSupportUserToTakeControl": false,
            "CompanySettingsId": "",
            "SlugURL": "",
            "WidgetSelected": stateValueWidgets,
            "npn": this.state.npn,
            "AgencyName": this.state.agencyname,
            "UserRoleId":3
        });

        var config = {
            method: 'post',
            // url: 'https://localhost:44394/api/user/addp2puser',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/addp2puser`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
             
                this.setState({
                    loadingSave: false,
                    companyName: "",
                    typeOfWidget: "",
                    firstName: "",
                    lastName: "",
                    email: ""
                })
                this.toast.show(
                    {
                        severity: 'success',
                        summary: 'Success message ',
                        detail: 'User is added successfully and set password link is sent to the registered email address.',
                        life: 5000
                    });
                setTimeout(
                    () => this.props.history.push("/userlist"),
                    7000
                );
                this.clearFilter('filters1');
            })
            .catch((error) => {
                console.log(error);

                this.setState({ loadingSave: false })

                if (error.response != null) {
                    if
                        (error.response.status == 409) {
                        this.toast.show(
                            {
                                severity: 'error',
                                summary: 'Error message ',
                                detail: 'User already exists, please log in.',
                                life: 5000
                            });
                        setTimeout(
                            () => this.setState({ loadingSave: false }),
                            7000
                        );
                    }
                }
            });


    }

    userTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    User Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    OnshowNPNPopUpClick = () => {
        this.setState({
            showNPNPop: true
        })
    }

    onHidePopUp(name) {
        this.setState({
            [`${name}`]: false
        });

        this.setState({
        });
    }

    checkValidationfoNPN = () => {
        if (this.state.email == "" || this.state.email == null) {
            this.setState({
                inputTextEmailErrorClass: true,
                errorMessageEmail: "Please enter email"
            });
            setTimeout(() => {
                this.setState({
                    inputTextEmailErrorClass: false,
                    errorMessageEmail: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.email.trim() !== "") {
            const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (regex.test(this.state.email) === false) {
                this.setState({
                    inputTextEmailErrorClass: true,
                    errorMessageEmail: "Please enter valid Email."
                });
                setTimeout(() => {
                    this.setState({
                        inputTextEmailErrorClass: false,
                        errorMessageEmail: "",
                    });
                }, 3000);

                return false;
            };

            if (this.state.npn == "" || this.state.npn == null) {
                this.setState({
                    inputTextNPNErrorClass: true,
                    errorMessageNPN: "Please enter npn."
                });
                setTimeout(() => {
                    this.setState({
                        inputTextNPNErrorClass: false,
                        errorMessageNPN: "",
                    });
                }, 3000);

                return false;
            }
        }
        return true
    }

    checkNPNInfo = () => {
        if (!this.checkValidationfoNPN()) {
            return false;
        }

        var axios = require('axios');
        var data = '';
        this.setState({
            loadingNPN: true,
            NPNMessageForOK: "",
            NPNMessageForNotOK: ""
        });

        let UserId = CommonValues.GetUserId();
        var token = CommonValues.GetToken();

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/verifynpn?userId=${UserId}&npn=${this.state.npn}&user_email=${this.state.email}`,

            headers: {
                'Authorization': `Bearer ${token}`
            }
            , data: data
        };

        axios(config)
            .then((response) => {
                if (response.data == false) {

                    this.setState({
                        NPNMessageForNotOK: "User is not registered in semsee."
                    });
                }
                else {
                    this.setState({
                        NPNMessageForOK: "User is registered in semsee."
                    });
                }
                this.setState({
                    loadingNPN: false,
                    showNPNPop: true
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingNPN: false,
                    showNPNPop: true
                });
            });
    }

    getAllWidgetList = (searchText) => {

        var token = CommonValues.GetToken();
        var axios = require('axios');
        this.setState({
            loadingWidget: true
        });

        var config = {
            method: 'get',
            //url: `https://localhost:44394/api/user/rtgwidget?searchText=${this.state.searchText}`,
            url: `${process.env.REACT_APP_UserService_Url}/api/user/rtgwidget?searchText=${this.state.searchText}`,

            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    widgetTypeList: response.data,
                    searchText: ""
                });


                this.setState({
                    loadingWidget: false
                });

                let widgetType = [];
                if (response.data != null) {
                    this.state.widgetTypeList.forEach(widgetTypeId => {
                        let isInserted = false;
                        response.data.forEach(item => {
                            if (item.widgetId == widgetTypeId.widgetId) {
                                widgetType.push({
                                    "name": widgetTypeId.name,
                                    "isAssigned": true,
                                    "widgetId": widgetTypeId.widgetId,
                                    "description": widgetTypeId.description,
                                    "providerCompanyName": widgetTypeId.providerCompanyName
                                });
                                isInserted = true;
                            }
                        });
                        if (!isInserted) {
                            widgetType.push({
                                "name": widgetTypeId.name,
                                "isAssigned": false,
                                "widgetId": widgetTypeId.widgetId,
                                "description": widgetTypeId.description,
                                "providerCompanyName": widgetTypeId.providerCompanyName
                            });

                        }
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingWidget: false,
                    searchText: ""
                });


                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    onIsAssignedChange = (widgetId, e) => {
        let list = [];
        this.state.widgetTypeList.forEach(item => {
            if (item.widgetId == widgetId) {
                list.push({
                    "name": item.name,
                    "isAssigned": !item.isAssigned,
                    "widgetId": item.widgetId,
                    "description": item.description
                })
            }
            else {
                list.push(item)
            }
        })
        this.setState({
            widgetTypeList: list
        });
        this.setState({ typeOfWidget: e.value });
    }

    actionBodyTemplateForIsAssigned = (rowdata) => {
        return (
            <React.Fragment>
                <InputSwitch checked={rowdata.isAssigned} onChange={(e) => this.onIsAssignedChange(rowdata.widgetId, e)} />
            </React.Fragment>
        );

    }
    onIsAssignedChange = (widgetId, e) => {
        let list = [];
        this.state.widgetTypeList.forEach(item => {
            if (item.widgetId == widgetId) {
                list.push({
                    "name": item.name,
                    "isAssigned": !item.isAssigned,
                    "widgetId": item.widgetId,
                    "description": item.description
                })
            }
            else {
                list.push(item)
            }
        })
        this.setState({
            widgetTypeList: list
        });
        this.setState({ typeOfWidget: e.value });
    }

    renderHeader(filtersKey) {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)} placeholder="Search widget" />
            </span>
        );
    }
    clearFilter(filtersKey) {

        this.state[`${filtersKey}`]['global'].value = ""
    }

    onGlobalFilterChange(event, filtersKey) {
        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    } d
    onWidgetTypeChange = (e) => {
        this.setState({ typeOfWidget: e.value });
        this.setState({
            widgetTypeIds: e.value.map(element => element.id)
        });
    }
    getAllRTGCompanyList = () => {

        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/rtgcompany?searchText=`,
            //url:  "https://localhost:44328/api/company/rtgcompany?searchText=",

            headers: {
                'Authorization': `Bearer ${token}`
            }
        };


        axios(config)
            .then((response) => {

                this.setState({
                    companyListOption: response.data,

                });
                if (this.props.serviceId != 0) {
                    this.getOverviewDetails(this.props.serviceId)
                }
                else {
                    this.setState({
                        loading: false
                    });

                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getAllCompanyList = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };


        axios(config)
            .then((response) => {
                this.setState({
                    companyList: response.data,
                });
                this.setState({
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    checkValidation = () => {
        if (this.state.companyName == "" || this.state.companyName == null) {
            this.setState({
                inputTextCompanyNameErrorClass: true,
                errorMessageCompanyname: "Please select company name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextCompanyNameErrorClass: false,
                    errorMessageCompanyname: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.firstName == "" || this.state.firstName == null) {
            this.setState({
                inputTextFirstNameErrorClass: true,
                errorMessageFirstName: "Please enter first name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextFirstNameErrorClass: false,
                    errorMessageFirstName: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.lastName == "" || this.state.lastName == null) {
            this.setState({
                inputTextLastNameErrorClass: true,
                errorMessageLastName: "Please enter last name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextLastNameErrorClass: false,
                    errorMessageLastName: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.email == "" || this.state.email == null) {
            this.setState({
                inputTextEmailErrorClass: true,
                errorMessageEmail: "Please enter email"
            });
            setTimeout(() => {
                this.setState({
                    inputTextEmailErrorClass: false,
                    errorMessageEmail: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.email.trim() !== "") {
            const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (regex.test(this.state.email) === false) {
                this.setState({
                    inputTextEmailErrorClass: true,
                    errorMessageEmail: "Please enter valid Email."
                });
                setTimeout(() => {
                    this.setState({
                        inputTextEmailErrorClass: false,
                        errorMessageEmail: "",
                    });
                }, 3000);

                return false;
            };

        }
        if (this.state.typeOfWidget == "" || this.state.typeOfWidget == null) {
            this.setState({
                inputTextWidgetNameErrorClass: true,
                errorMessageWidgetName: "Please select Widget name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextWidgetNameErrorClass: false,
                    errorMessageWidgetName: "",
                });
            }, 3000);

            return false;
        }

        // if (this.state.serviceType == "" || this.state.serviceType == null) {
        //     this.setState({
        //         inputTextServiceTypeErrorClass: true,
        //         errorMessageServiceType: "Please select service type"
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             inputTextServiceTypeErrorClass: false,
        //             errorMessageServiceType: "",
        //         });
        //     }, 3000);

        //     return false;
        // }
        // if (this.state.tagline == "" || this.state.tagline == null) {
        //     this.setState({
        //         inputTextTagErrorClass: true,
        //         errorMessageTagline: "Please enter tagline"
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             inputTextTagErrorClass: false,
        //             errorMessageTagline: "",
        //         });
        //     }, 3000);

        //     return false;
        // }

        // if (this.state.description == "" || this.state.description == null) {
        //     this.setState({
        //         inputTextDescErrorClass: true,
        //         errorMessageDesc: "Please enter description"
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             inputTextDescErrorClass: false,
        //             errorMessageDesc: "",
        //         });
        //     }, 3000);

        //     return false;
        // }

        return true
    }
    onCompanyNameChange = (e) => {
        this.setState({
            companyName: e.value,
        })


    }
    itemTemplate = (item) => {
        return (
            <div className="company-item">
                <div>{item.name}</div>
            </div>
        );
    }
    itemTemplate = (item) => {
        return (
            <div className="company-item">
                <div>{item.name}</div>
            </div>
        );
    }
    searchCountry = (event) => {
        setTimeout(() => {
            let filteredCompany;
            if (!event.query.trim().length) {
                filteredCompany = [...this.state.companyListOption];
            }
            else {
                filteredCompany = this.state.companyListOption.filter((company) => {
                    return company.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({ filteredCompany });
        }, 250);
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    OnAddCompanyClick = () => {
        this.setState({
            showAddCompanyPopup: !this.state.showAddCompanyPopup
        })
    }
    onCompanyChange = (e) => {
        this.setState({
            company: e.value
        });
    }
    checkValidationAddRTG = () => {
        let valid = true;
        this.setState({
            successMessage: "",
            infoMessage: "",
            errorMessage: "",
            errorMessageCompanyName: "",
            inputHeaderTextErrorClass: ""
        })
        if (this.state.company == null || this.state.company == '') {
            this.setState({
                errorMessageCompanyName: "Please select company."
            })
            valid = false;
        }
        if (this.state.headerText == "" || this.state.headerText == null) {
            this.setState({
                inputHeaderTextErrorClass: "Please enter header text."
            });
            valid = false;
        }
        return valid;
    }
    OnAddRTGCompanyClick = () => {
        if (this.checkValidationAddRTG()) {
            this.setState({

                loadingRTGSave: true
            });

            var token = CommonValues.GetToken();

            var data = JSON.stringify({
                "SlugURL": this.state.slugURL,
                "HeaderText": this.state.headerText,
                "IconURL": this.state.company.iconURL,
                "CompanyId": this.state.company.companyId

            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_CompanyService_Url}/api/Company/addrtgcompany`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)

                .then((response) => {
                    this.setState({
                        loadingRTGSave: false,
                        showAddCompanyPopup: false,
                        headerText: "",
                        slugURL: "",
                        company: "",
                    });
                    this.getAllRTGCompanyList('');
                })

                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loadingRTGSave: false
                    });

                    let errorMessage = '';
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else {
                            errorMessage = "Unknown error while adding the company";
                        }
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        errorMessage = "Unknown error while adding the company";
                    }
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                    this.setState({ errorMessage: errorMessage });
                });
        }
    }
    render() {
        const header1 = this.renderHeader('filters1');

        return (

            <div onClick={this.onBodyClick}>
                <Toast ref={(el) => this.toast = el} />
                <div className="wrapper" class={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" data-widget="pushmenu" role="button" onClick={this.onBarsClick}><i className="fas fa-bars"></i></a>
                            </li>

                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-widget="control-sidebar"
                                    data-slide="true"
                                    href="/"
                                    role="button"
                                >
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Add New User</h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10 panelHeight">

                                                    <Panel headerTemplate={this.userTemplate} toggleable>
                                                        {/* <UserDetails userId={this.state.userId}></UserDetails> */}
                                                        <div className="row" >
                                                            <div className="col-md-2">
                                                                <label className="form-label d-block">
                                                                    Tenant Name
                                                                    <span style={{ color: "red" }}>*</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row" >
                                                            <div className="col-md-9">
                                                                {
                                                                    this.state.loading ?

                                                                        <ProgressSpinner className="d-flex align-items-center justify-content-start" style={{ width: '40px', height: '40px', margin: 'inherit' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                        :
                                                                        <div >
                                                                            <AutoComplete
                                                                                className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2 w-100" : "w-100"}

                                                                                autocomplete
                                                                                value={this.state.companyName}
                                                                                suggestions={this.state.filteredCompany}
                                                                                completeMethod={this.searchCountry}
                                                                                field="name" dropdown forceSelection
                                                                                onChange={this.onCompanyNameChange}
                                                                                placeholder="Select tenant"
                                                                            />
                                                                            <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Button label="Add Tenant" icon="pi pi-plus" onClick={this.OnAddCompanyClick} style={{ float: "right" }} />
                                                            </div>
                                                        </div>

                                                        {/* <label className="form-label">
                                                            Widget Name
                                                        </label><span style={{ color: "red" }}>*</span>
                                                        {
                                                            this.state.loadingWidget ?
                                                                <ProgressSpinner className="d-flex align-items-center justify-content-start" style={{ width: '40px', height: '40px', margin: 'inherit' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                :
                                                                <div >
                                                                    <MultiSelect value={this.state.typeOfWidget}
                                                                        className={this.state.inputTextWidgetNameErrorClass ? " p-invalid p-mr-2 w-100 multiselectnew" : "w-100 multiselectnew"}
                                                                        options={this.state.widgetTypeList}
                                                                        onChange={this.onWidgetTypeChange}
                                                                        optionLabel="name"
                                                                        placeholder="Select a Widget Name"
                                                                        maxSelectedLabels={5}
                                                                        style={{ width: "100%" }}
                                                                    // className="multiselectnew"
                                                                    />
                                                                    <small id="username-help" className="p-error">{this.state.errorMessageWidgetName}</small>
                                                                </div>
                                                        } */}

                                                        <div >
                                                            <label className="form-label">
                                                                First Name
                                                            </label><span style={{ color: "red" }}>*</span>
                                                            <input type="text"
                                                                id="firstName"
                                                                name="firstName"
                                                                value={this.state.firstName}
                                                                className={this.state.inputTextFirstNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                onChange={this.onChange}
                                                                placeholder="Enter First Name"
                                                            />
                                                            <small id="username-help" className="p-error">{this.state.errorMessageFirstName}</small>
                                                        </div>
                                                        <div >
                                                            <label className="form-label">
                                                                Last Name
                                                            </label><span style={{ color: "red" }}>*</span>
                                                            <input type="text"
                                                                id="lastName"
                                                                name="lastName"
                                                                value={this.state.lastName}
                                                                className={this.state.inputTextLastNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                onChange={this.onChange}
                                                                placeholder="Enter Last Name"
                                                            />
                                                            <small id="username-help" className="p-error">{this.state.errorMessageLastName}</small>
                                                        </div>
                                                        <div >
                                                            <label className="form-label">
                                                                Email
                                                            </label><span style={{ color: "red" }}>*</span>
                                                            <input type="text"
                                                                id="email"
                                                                name="email"
                                                                value={this.state.email}
                                                                className={this.state.inputTextEmailErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                onChange={this.onChange}
                                                                placeholder="Enter Email"
                                                            />
                                                            <small id="username-help" className="p-error">{this.state.errorMessageEmail}</small>
                                                        </div>
                                                        <div className="row align-items-center" >
                                                            <div className="col-md-9">
                                                                <div >
                                                                    <label className="form-label">
                                                                        NPN
                                                                    </label>
                                                                    <input type="text"
                                                                        id="npn"
                                                                        name="npn"
                                                                        value={this.state.npn}
                                                                        className={this.state.inputTextNPNErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                        // className="form-control p-mr-2"
                                                                        onChange={this.onChange}
                                                                        placeholder="Enter NPN"
                                                                    />
                                                                    <small id="username-help" className="p-error">{this.state.errorMessageNPN}</small>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 mt-4">
                                                                <Button label="Verify NPN in Semsee" loading={this.state.loadingNPN} onClick={this.checkNPNInfo} style={{ float: "right", height: "40px" }} />
                                                            </div>

                                                        </div>



                                                        <div className="row align-items-center" >
                                                            <div className="col-md-12">
                                                                <label className="form-label">
                                                                    Agency Name
                                                                </label>
                                                                <input type="text"
                                                                    id="agencyname"
                                                                    name="agencyname"
                                                                    value={this.state.agencyname}
                                                                    className="form-control p-mr-2"
                                                                    onChange={this.onChange}
                                                                    placeholder="Enter Agency Name"
                                                                />
                                                            </div>

                                                            {/* <div className="col-md-3 mt-4">
                                                                <Button label="Verify NPN" loading={this.state.loadingNPN} onClick={this.checkNPNInfo} style={{ float: "right" }} />
                                                            </div> */}
                                                        </div>
                                                        <label className="form-label">
                                                            Widget Name
                                                        </label><span style={{ color: "red" }}>*</span>
                                                        <small id="username-help" className="p-error">{this.state.errorMessageWidgetName}</small>
                                                        <div className="card-body table-responsive p-0">
                                                            <div className="d-none d-md-block">
                                                                <DataTable value={this.state.widgetTypeList}
                                                                    filters={this.state.filters1}
                                                                    paginator
                                                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                    rows={10}
                                                                    header={header1}

                                                                    responsiveLayout="scroll">

                                                                    <Column field={this.actionBodyTemplateForIsAssigned} header="Is Assigned"></Column>
                                                                    <Column field="name" header="Widget Name" sortable></Column>
                                                                    {/* <Column field={this.descriptionBodyTemplate} header="Description" ></Column> */}
                                                                    <Column field="description" header="Description" ></Column>
                                                                    <Column field="providerCompanyName" header="Provider" sortable></Column>
                                                                    {/* <Column field="startingPrice" header="Starting Price" sortable></Column> */}
                                                                    {/* <Column field="availaibleIntegrations" header="Available Integrations"></Column> */}

                                                                </DataTable>
                                                            </div>
                                                            <div className="mt-3">
                                                                <Button label="Save" icon="pi pi-check" loading={this.state.loadingSave} onClick={this.OnSaveClick} style={{ float: "right" }} />
                                                            </div>
                                                        </div>

                                                    </Panel><br />


                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <footer className="main-footer">
                        <strong>
                            Copyright © 2021 <a href="https://adminlte.io">InsuranceGIG</a>.
                        </strong>{" "}
                        All rights reserved.
                    </footer>
                    <aside className="control-sidebar control-sidebar-dark">
                    </aside>
                </div>

                <Dialog header="Add Tenant" visible={this.state.showAddCompanyPopup} style={{ width: '70vw' }} onHide={() => this.OnAddCompanyClick()} draggable={false}>
                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Tenant</label>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className='col-10'>
                            <Dropdown value={this.state.company}
                                options={this.state.companyList}
                                onChange={this.onCompanyChange}
                                optionLabel="name"
                                placeholder="Select tenant"
                                style={{ width: "100%" }}
                                filter
                                className={this.state.errorMessageCompanyName.length > 0 ? "p-invalid p-mr-2" : ""}
                            />
                            <small id="username-help" className="p-error">{this.state.errorMessageCompanyName}</small>
                        </div>
                    </div>

                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Slug URL</label>
                        </div>
                        <div className='col-10'>
                            <input type="text"
                                id="slugURL"
                                name="slugURL"
                                value={this.state.slugURL}
                                className="form-control"
                                onChange={this.onChange}
                                placeholder="Enter slug url"
                            />
                        </div>
                    </div>


                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Header Text</label>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className='col-10'>
                            <input type="text"
                                id="headerText"
                                name="headerText"
                                value={this.state.headerText}
                                className={this.state.inputHeaderTextErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                onChange={this.onChange}
                                placeholder="Enter header text"
                            />
                            <small id="username-help" className="p-error">{this.state.inputHeaderTextErrorClass}</small>
                        </div>
                    </div>

                    <div className="row p-2" >
                        <div className='col-10'>

                        </div>
                        <div className='col-2'>
                            <Button label="Save" icon="pi pi-check" loading={this.state.loadingRTGSave} onClick={this.OnAddRTGCompanyClick} style={{ float: "right" }} />
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Result :" visible={this.state.showNPNPop} style={{ width: '30vw' }} onHide={() => this.onHidePopUp('showNPNPop')} draggable={false}>
                    <div className=''>
                        {
                            this.state.NPNMessageForOK.length > 0 ?
                                <label className=''>{this.state.NPNMessageForOK}</label>
                                :
                                null
                        }
                        {
                            this.state.NPNMessageForNotOK.length > 0 ?
                                <label className='text-danger'>{this.state.NPNMessageForNotOK}</label>
                                :
                                null
                        }
                    </div>
                </Dialog>
            </div>
        )
    }
}

