import React, { Component, startTransition } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonValues from '../../Common/Util';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';

export default class EndpointDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCustomeHeader: false,
            showHeaderPassthrough: false,
            customHeaderList: [],
            headerPassthroughList: [],
            fieldMappingList: [],
            serviceList: [],
            service: "",
            httpMethod: "",
            bodyType: "",
            authType: "",
            bodySubType: "",
            jsonbody: "",
            response: "",
            fieldFormula: "",
            uname: "",
            pass: "",
            successStatusCode: "200",
            errorStatusCode: "400,401,404,500",
            errorMessageName: "",
            errorMessageEndpointCode: "",
            errorMessageHttpMethod: "",
            errorMessageAuthType: "",
            errorMessageBodyType: "",
            errorMessageBodyTypeSubType: "",
            errorMessageUrl: "",
            errorMessageJSONBody: "",
            errorMessage: "",
            successMessage: "",
            errorMessageUsername: "",
            errorMessagePassword: "",
            errorMessageIgField: "",
            errorMessagefieldFormula: "",
            errorMessageCustHeaderKey: "",
            errorMessageCustHeaderValue: "",
            errorMessageIgFieldText: "",
            errorMessageResponse: "",
            errorMessageService: "",
            errorMessageErrorStatusCode: "",
            errorMessageSuccessStatusCode: "",
            errorMessageDescription:"",
            showIGFieldMappingPopup: false,
            showFieldMappingPopup: false,
            isBillable:true
        }
        this.httpMethodList = [
            { name: "GET" },
            { name: "POST" },
            { name: "PUT" },
            { name: "DELETE" },
        ]
        this.AuthTypeList = [
            { name: "Basic" },
            { name: "NoAuth" }
        ]
        this.BodyTypeList = [
            { name: "None" },
            { name: "FormData" },
            { name: "Raw" },
            { name: "Binary" },
            { name: "GraphQL" },
            { name: "x-www-form-urlencoded" }
        ]
        this.BodySubTypeList = [
            { name: "Text" },
            { name: "JSON" },
            { name: "XML" }
        ]
        this.xFormUrlencodedPlaceHolder = "Rows are seperated by ; \nKeys and values are seperated by :"
    }
    componentDidMount = () => {
        this.getIgFields();
        if (this.props.endpointId != 0) {
            this.getEndPointDetails(this.props.endpointId, 'getbyid');
            this.getCustomHeaders(this.props.endpointId);
            this.getFieldMapping(this.props.endpointId);
            this.getPassThrough(this.props.endpointId);

        }
        else {

            this.getAllServiceList(0);
        }
    }
    getAllServiceList = (serviceId) => {

        this.setState({
            serviceLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/service?searchText=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    serviceList: response.data,
                    serviceLoading: false
                });
                if (serviceId != 0) {
                    response.data.forEach(element => {
                        if (element.serviceId == serviceId) {
                            this.setState({
                                service: element
                            })
                        }
                    })
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    serviceLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong while getting services."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getEndPointDetails = (id, flag) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints?id=${id}&flag=${flag}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false,
                    name: response.data[0].name,
                    endpointcode: response.data[0].apiEndPointCode,
                    endpointurl: response.data[0].url,
                    jsonbody: response.data[0].jsonBody,
                    uname: response.data[0].username,
                    pass: response.data[0].password,
                    response: response.data[0].response,
                    successStatusCode: response.data[0].successCodes,
                    errorStatusCode: response.data[0].errorCodes,
                    description:response.data[0].description,
                    isBillable:response.data[0].isBillable
                });
                this.httpMethodList.forEach(element => {
                    if (element.name == response.data[0].httpMethod) {
                        this.setState({
                            httpMethod: element
                        })
                    }
                })
                this.AuthTypeList.forEach(element => {
                    if (element.name == response.data[0].authenticationType) {
                        this.setState({
                            authType: element
                        })
                    }
                })
                this.BodyTypeList.forEach(element => {
                    if (element.name == response.data[0].bodyType) {
                        this.setState({
                            bodyType: element
                        })
                    }
                })
                this.BodySubTypeList.forEach(element => {
                    if (element.name == response.data[0].bodySubType) {
                        this.setState({
                            bodySubType: element
                        })
                    }
                });
                this.getAllServiceList(response.data[0].serviceId);

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getCustomHeaders = (id) => {
        this.setState({
            customHeaderloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndPointCustomHeaders/customheaderbyapiid?EndpointId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    customHeaderloading: false,
                    customHeaderList: response.data
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    customHeaderloading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getFieldMapping = (id) => {
        this.setState({
            igFieldsLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIFileMappingRequests/apifieldmappingbyendpointid?EndpointId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    igFieldsLoading: false,
                    fieldMappingList: response.data

                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    igFieldsLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getIgFields = () => {
        this.setState({
            igFieldsLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIFileMappingRequests/igfields`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    igFieldsLoading: false,
                    igFieldList: response.data
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    igFieldsLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getPassThrough = () => {
        this.setState({
            addpassThroughKeyloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints/getheaderpassthrough?EndpointId=${this.props.endpointId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    addpassThroughKeyloading: false,
                    passThroughKeyList: response.data
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addpassThroughKeyloading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    EditorHeader = () => {
        return (null)
    }
    onhttpMethodChange = (e) => {
        this.setState({
            httpMethod: e.value
        })
    }
    onigFieldChange = (e) => {
        this.setState({
            igField: e.value
        })
    }
    onAuthTypeChange = (e) => {
        this.setState({
            authType: e.value
        })
    }
    onBodyTypeChange = (e) => {
        this.setState({
            bodyType: e.value
        })
        if (e.value !== "Raw") {
            this.setState({
                bodySubType: "",
                jsonbody: ""
            })
        }

    }
    onBodySubTypeChange = (e) => {
        this.setState({
            bodySubType: e.value
        })
    }
    onServiceChange = (e) => {
        this.setState({
            service: e.value
        })
    }
    onCustomHeaderClicked = () => {
        this.setState({
            showCustomeHeader: !this.state.showCustomeHeader
        })
    }
    onHeaderPassthroughClicked = () => {
        this.setState({
            showHeaderPassthrough: !this.state.showHeaderPassthrough
        })
    }
    onFiledMappingClicked = () => {

        this.setState({
            igField: "",
            fieldMapingName: "",
            hasFormula: false,
            fieldFormula: "",
            showFieldMappingPopup: !this.state.showFieldMappingPopup
        })
    }
    onIGFieldMappingClicked = () => {

        this.setState({
            igFieldText: "",
            showIGFieldMappingPopup: !this.state.showIGFieldMappingPopup
        })
    }
    onAddHeaderPassthroughClick = () => {
        this.setState({
            errorMessageCustHeaderKey: "",

        })
        if (this.state.passThroughKey == "" || this.state.passThroughKey == null) {
            this.setState({
                errorMessageCustHeaderKey: "Please enter key."
            })
            return;
        }
        this.setState({
            addpassThroughKeyloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints/insertheaderpassthrough?EndpointId=${this.props.endpointId}&Value=${this.state.passThroughKey}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },

        };

        axios(config)
            .then((response) => {

                this.setState({
                    addpassThroughKeyloading: false,
                    passThroughKey: ""
                })
                this.getPassThrough();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addpassThroughKeyloading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    onDeleteHeaderPassthroughClick = (id) => {
        this.setState({
            errorMessageCustHeaderKey: "",
        })

        this.setState({
            addpassThroughKeyloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints/deletebyid?Id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },

        };

        axios(config)
            .then((response) => {

                this.setState({
                    addpassThroughKeyloading: false,
                    passThroughKey: ""
                })
                this.getPassThrough();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addpassThroughKeyloading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onAddCustomHeaderClick = () => {
        this.setState({
            errorMessageCustHeaderKey: "",
            errorMessageCustHeaderValue: ""
        })
        if (this.state.custHeaderKey == "" || this.state.custHeaderKey == null) {
            this.setState({
                errorMessageCustHeaderKey: "Please enter key."
            })
            return;
        }

        if (this.state.custHeaderValue == "" || this.state.custHeaderValue == null) {
            this.setState({
                errorMessageCustHeaderValue: "Please enter Value."
            })
            return;
        }

        this.setState({
            addCustomHeaderloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        const data = JSON.stringify({
            "APIEndPointid": this.props.endpointId,
            "Name": this.state.custHeaderKey,
            "Value": this.state.custHeaderValue
        })
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndPointCustomHeaders`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.getCustomHeaders(this.props.endpointId);
                this.setState({
                    addCustomHeaderloading: false,
                    custHeaderKey: "",
                    custHeaderValue: ""
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addCustomHeaderloading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onDeleteCustomHeaderClicked = (id) => {

        this.setState({
            addCustomHeaderloading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndPointCustomHeaders?HeaderId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },

        };

        axios(config)
            .then((response) => {
                this.getCustomHeaders(this.props.endpointId);
                this.setState({
                    addCustomHeaderloading: false,
                    custHeaderKey: "",
                    custHeaderValue: ""
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addCustomHeaderloading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onDeleteFieldMappingClicked = (id) => {
        this.setState({
            igFieldsLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIFileMappingRequests?requestId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },

        };

        axios(config)
            .then((response) => {

                this.setState({
                    igFieldsLoading: false,
                    igField: "",
                    fieldMapingName: "",
                    fieldFormula: "",
                });
                this.getFieldMapping(this.props.endpointId);


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    igFieldsLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    // onAddCustomHeaderClick = () => {

    //     let custheader = [];
    //     custheader = this.state.customHeaderList
    //     custheader.push({
    //         "Key": this.state.custHeaderKey,
    //         "Value": this.state.custHeaderValue
    //     }

    //     )
    //     this.setState({
    //         customHeaderList: custheader,
    //         custHeaderKey: "",
    //         custHeaderValue: ""
    //     })

    // }
    // onAddHeaderPassthroughClick = () => {
    //     let passthrough = [];
    //     passthrough = this.state.headerPassthroughList
    //     passthrough.push({
    //         "Key": this.state.passThroughKey
    //     })
    //     this.setState({
    //         headerPassthroughList: passthrough,
    //         passThroughKey: "",

    //     })
    // }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.onDeleteHeaderPassthroughClick(rowData.id)} />
            </React.Fragment>
        );
    }
    actionBodyTemplateCustomeHeader = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.onDeleteCustomHeaderClicked(rowData.id)} />
            </React.Fragment>
        );
    }
    actionBodyTemplateFieldMapping = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.onDeleteFieldMappingClicked(rowData.id)} />
            </React.Fragment>
        );
    }
    isValid = () => {
        this.setState({
            errorMessage: "",
            errorMessageName: "",
            errorMessageEndpointCode: "",
            errorMessageHttpMethod: "",
            errorMessageAuthType: "",
            errorMessageBodyType: "",
            errorMessageBodyTypeSubType: "",
            errorMessageUrl: "",
            errorMessageJSONBody: "",
            errorMessageUsername: "",
            errorMessagePassword: "",
            errorMessageIgField: "",
            errorMessageResponse: "",
            errorMessageService: "",
            errorMessageErrorStatusCode: "",
            errorMessageSuccessStatusCode: "",
        })
        let valid = true;
        if (this.state.name == "" || this.state.name == null) {
            this.setState({
                errorMessageName: "Please enter name"
            });
            valid = false;
        }
        if (this.state.endpointcode == "" || this.state.endpointcode == null) {
            this.setState({
                errorMessageEndpointCode: "Please enter endpoint code"
            });
            valid = false;
        }
        if (this.state.httpMethod == "" || this.state.httpMethod == null) {
            this.setState({
                errorMessageHttpMethod: "Please select HTTP method"
            });
            valid = false;
        }
        if (this.state.authType == "" || this.state.authType == null) {
            this.setState({
                errorMessageAuthType: "Please select auth type"
            });
            valid = false;
        }
        if (this.state.authType != null && this.state.authType != "" && this.state.authType.name == "Basic") {
            if (this.state.uname == "" || this.state.uname == null) {
                this.setState({
                    errorMessageUsername: "Please enter username"
                });
                valid = false;
            }
            if (this.state.pass == "" || this.state.pass == null) {
                this.setState({
                    errorMessagePassword: "Please enter password"
                });
                valid = false;
            }
        }
        if (this.state.bodyType == "" || this.state.bodyType == null) {
            this.setState({
                errorMessageBodyType: "Please select body type"
            });
            valid = false;
        }

        if (this.state.bodyType.name != undefined && this.state.bodyType.name == "Raw") {
            if (this.state.bodySubType.name == undefined || this.state.bodySubType.name == "") {
                this.setState({
                    errorMessageBodyTypeSubType: "Please select body sub type for raw body type"
                });
            }
        }
        if (this.state.endpointurl == "" || this.state.endpointurl == null) {
            this.setState({
                errorMessageUrl: "Please enter endpoint url"
            });
            valid = false;
        }
        if (this.state.bodySubType.name != undefined && this.state.bodySubType.name == "JSON" && this.state.jsonbody == "") {
            this.setState({
                errorMessageJSONBody: "Please enter json body"
            });
            valid = false;
        }
        if (this.state.response == "" || this.state.response == null) {
            this.setState({
                errorMessageResponse: "Please enter default response"
            });
            valid = false;
        }
        if (this.state.service == "" || this.state.service == null) {
            this.setState({
                errorMessageService: "Please select service"
            });
            valid = false;
        }
        if (this.state.successStatusCode == "" || this.state.successStatusCode == null) {
            this.setState({
                errorMessageSuccessStatusCode: "Please enter success codes"
            });
            valid = false;
        }
        if (this.state.errorStatusCode == "" || this.state.errorStatusCode == null) {
            this.setState({
                errorMessageErrorStatusCode: "Please enter error codes"
            });
            valid = false;
        }
        return valid;
    }
    onSaveUpdateEndpointClicked = () => {
        if (this.isValid()) {
            this.setState({
                loading: true
            });

            var token = CommonValues.GetToken();
            var axios = require('axios');
            const data = JSON.stringify({
                "Name": this.state.name,
                "APIEndPointCode": this.state.endpointcode,
                "HTTPMethod": this.state.httpMethod.name,
                "AuthenticationType": this.state.authType.name,
                "BodyType": this.state.bodyType.name,
                "BodySubType": this.state.bodySubType.name,
                "URL": this.state.endpointurl,
                "JSONBody": this.state.jsonbody,
                "APIid": this.props.apiId,
                "ID": this.props.endpointId,
                "Username": this.state.uname,
                "Password": this.state.pass,
                "Response": this.state.response,
                "ServiceId": this.state.service.serviceId,
                "SuccessCodes": this.state.successStatusCode,
                "ErrorCodes": this.state.errorStatusCode,
                "Description":this.state.description,
                "IsBillable":this.state.isBillable


            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    this.setState({
                        loading: false,
                        successMessage: "Endpoint details added successfully...!!!"
                    })
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.props.onEndpointAdded()
                    this.props.showHideAddEndpoint(false, 0);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });

                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "Endpoint name or endpoint code already exist."
                            })
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp's something went wrong."
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                    }
                });
        }
    }
    onAddFieldMappingClick = () => {
        this.setState({
            errorMessageIgField: "",
            errorMessageName: "",
            errorMessagefieldFormula: ""
        })
        if (this.state.igField == "" || this.state.igField == null) {
            this.setState({
                errorMessageIgField: "Please select IG Field."
            })
            return;
        }

        if (this.state.fieldMapingName == "" || this.state.fieldMapingName == null) {
            this.setState({
                errorMessageName: "Please enter Value."
            })
            return;
        }
        if (this.state.hasFormula == true) {
            if (this.state.fieldFormula == "" || this.state.fieldFormula == null) {
                this.setState({
                    errorMessagefieldFormula: "Please enter formula."
                })
                return;
            }


        }
        this.setState({
            addFieldLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        const data = JSON.stringify({
            "IGIGFieldid": this.state.igField.id,
            "JSONPath": this.state.fieldMapingName,
            "Formula": this.state.fieldFormula,
            "APIEndpointid": this.props.endpointId
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIFileMappingRequests`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            data: data
        };

        axios(config)
            .then((response) => {

                this.setState({
                    addFieldLoading: false,
                    igField: "",
                    fieldMapingName: "",
                    fieldFormula: "",
                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getFieldMapping(this.props.endpointId);

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addFieldLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onAddIgFieldClick = () => {
        if (this.state.errorMessageIgFieldText == "" || this.state.errorMessageIgFieldText == null) {
            this.setState({
                errorMessageIgFieldText: "Please enter Ig Field."
            })
            return;
        }
        this.setState({
            addFieldLoading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints/insertapiendpointigfield?IgField=${this.state.igFieldText}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    addFieldLoading: false,
                    showIGFieldMappingPopup: false
                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getIgFields();

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    addFieldLoading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Name</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <input
                                        type="text"
                                        name="name"
                                        className={this.state.errorMessageName.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                        value={this.state.name}
                                        onChange={this.onChange}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                                </div>

                                <div className='col-2'>
                                    <label>Endpoint Code</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <input
                                        type="text"
                                        name="endpointcode"
                                        className={this.state.errorMessageEndpointCode.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                        value={this.state.endpointcode}
                                        onChange={this.onChange}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageEndpointCode}</small>
                                </div>
                            </div>
                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Description</label>
                                </div>
                                <div className='col-10'>
                                    <Editor style={{ height: '150px' }}
                                        value={this.state.description}
                                        className={this.state.errorMessageDescription.length>0 ? " p-invalid p-mr-2" : null}
                                        onTextChange={(e) => this.setState({ description: e.htmlValue })}
                                    />                                    
                                    <small id="username-help" className="p-error">{this.state.errorMessageDescription}</small>
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>HTTP Method</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <Dropdown value={this.state.httpMethod}
                                        options={this.httpMethodList}
                                        onChange={this.onhttpMethodChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        className={this.state.errorMessageHttpMethod.length > 0 ? "p-invalid p-mr-2" : ""}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageHttpMethod}</small>
                                </div>

                                <div className='col-2'>
                                    <label>Authentication Type</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <Dropdown value={this.state.authType}
                                        options={this.AuthTypeList}
                                        onChange={this.onAuthTypeChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        className={this.state.errorMessageAuthType.length > 0 ? "p-invalid p-mr-2" : ""}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageAuthType}</small>
                                </div>
                            </div>
                            {this.state.authType != null && this.state.authType != "" && this.state.authType.name == "Basic" ?

                                <div className="row p-2" >
                                    <div className='col-2'>
                                        <label>Username</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-4'>
                                        <input
                                            type="text"
                                            name="uname"
                                            className={this.state.errorMessageUsername.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                            value={this.state.uname}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorMessageUsername}</small>
                                    </div>

                                    <div className='col-2'>
                                        <label>Password</label>
                                        <span style={{ color: "red" }}>*</span>
                                    </div>
                                    <div className='col-4'>
                                        <input
                                            type="text"
                                            name="pass"
                                            className={this.state.errorMessagePassword.length ? "form-control p-invalid p-mr-2" : "form-control"}
                                            value={this.state.pass}
                                            onChange={this.onChange}
                                        />
                                        <small id="username-help" className="p-error">{this.state.errorMessagePassword}</small>
                                    </div>
                                </div>
                                :
                                null
                            }

                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Body Type</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <Dropdown value={this.state.bodyType}
                                        options={this.BodyTypeList}
                                        onChange={this.onBodyTypeChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        className={this.state.errorMessageBodyType.length > 0 ? "p-invalid p-mr-2" : ""}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageBodyType}</small>
                                </div>

                                <div className='col-2'>
                                    <label>Body Sub Type</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <Dropdown value={this.state.bodySubType}
                                        options={this.BodySubTypeList}
                                        onChange={this.onBodySubTypeChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        disabled={this.state.bodyType.name == "Raw" ? false : true}
                                        className={this.state.errorMessageBodyTypeSubType.length > 0 ? "p-invalid p-mr-2" : ""}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageBodyTypeSubType}</small>
                                </div>

                            </div>

                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Success Status Codes</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <input
                                        type="text"
                                        name="successStatusCode"
                                        className={this.state.errorMessageSuccessStatusCode.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                        value={this.state.successStatusCode}
                                        onChange={this.onChange}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageSuccessStatusCode}</small>
                                </div>

                                <div className='col-2'>
                                    <label>Error Status Codes</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    <input
                                        type="text"
                                        name="errorStatusCode"
                                        className={this.state.errorMessageErrorStatusCode.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                        value={this.state.errorStatusCode}
                                        onChange={this.onChange}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageErrorStatusCode}</small>
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className='col-2'>
                                </div>
                                <div className='col-8'>
                                    <small id="username-help" style={{ "color": "#6f2d80" }}>Note :Multiple success / error status codes can be added by comma seperated, for example (code1,code2,code3,... )</small>
                                </div>
                            </div>

                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Service</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                    {
                                        this.state.serviceLoading ?
                                            <div style={{ textAlign: "center" }}>
                                                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            </div>
                                            :
                                            <>

                                                <Dropdown value={this.state.service}
                                                    options={this.state.serviceList}
                                                    onChange={this.onServiceChange}
                                                    optionLabel="serviceName"
                                                    placeholder="Select Service"
                                                    style={{ width: "100%" }}
                                                    filter
                                                    className={this.state.errorMessageService.length > 0 ? "p-invalid p-mr-2" : ""}
                                                />
                                                <small id="username-help" className="p-error">{this.state.errorMessageService}</small>

                                            </>
                                    }
                                </div>
                                <div className='col-2'>
                                    <label>Is Billable</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-4'>
                                <InputSwitch checked={this.state.isBillable} onChange={(e) => this.setState({ isBillable: e.value })}></InputSwitch>
                                </div>
                            </div>
                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>URL</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <input
                                        type="text"
                                        name="endpointurl"
                                        className={this.state.errorMessageUrl.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                        value={this.state.endpointurl}
                                        onChange={this.onChange}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessageUrl}</small>
                                </div>
                            </div>
                            {this.props.endpointId != 0 ?
                                <div className="row p-2" >
                                    <div className='col-2'></div>
                                    <div className='col-3'>
                                        <Button label="Field Mapping" loading={this.state.btnloadingsave} onClick={this.onFiledMappingClicked} style={{ float: 'right' }} />
                                    </div>
                                    <div className='col-3'>
                                        <Button label="Custom Headers" loading={this.state.btnloadingsave} onClick={this.onCustomHeaderClicked} style={{ float: 'right' }} />
                                    </div>
                                    <div className='col-4'>
                                        <Button label="Headers Passthrough" loading={this.state.btnloadingsave} onClick={this.onHeaderPassthroughClicked} style={{ float: 'right' }} />
                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>{this.state.bodyType.name == "Raw" ? 'JSON Body' : 'Body'}</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>

                                    {/* <Editor
                                        headerTemplate={this.EditorHeader}
                                        style={{ height: '400px' }}
                                        value={this.state.jsonbody}
                                        onTextChange={(e) => this.setState({ jsonbody: e.htmlValue })}
                                    /> */}
                                    <textarea
                                        placeholder={this.state.bodyType.name == "x-www-form-urlencoded" ? this.xFormUrlencodedPlaceHolder : ""}
                                        onChange={this.onChange}
                                        name="jsonbody"
                                        value={this.state.jsonbody}
                                        style={{ height: '400px' }}
                                        disabled={this.state.bodyType.name == "Raw" || this.state.bodyType.name == "x-www-form-urlencoded" ? false : true}
                                        className={this.state.errorMessageJSONBody.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"} />
                                    <small id="username-help" className="p-error">{this.state.errorMessageJSONBody}</small>
                                </div>


                            </div>

                            <div className="row p-2" >
                                <div className='col-2'>
                                    <label>Response</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <textarea
                                        onChange={this.onChange}
                                        name="response"
                                        value={this.state.response}
                                        style={{ height: '400px' }}
                                        className={this.state.errorMessageResponse.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"} />
                                    <small id="username-help" className="p-error">{this.state.errorMessageResponse}</small>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-8">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">
                                    <Button label="Save" loading={this.state.btnloadingsave} onClick={() => this.onSaveUpdateEndpointClicked()} style={{ float: 'right' }} />

                                </div>
                                <div className='col-2'>
                                    <Button label="Cancel" loading={this.state.btnloadingsave} onClick={() => this.props.showHideAddEndpoint(false, 0)} style={{ float: "right" }} />
                                </div>
                            </div>


                            <Dialog header="Custome Header" visible={this.state.showCustomeHeader} style={{ width: '53vw' }} onHide={() => this.onCustomHeaderClicked()} draggable={false}>
                                <>{
                                    this.state.customHeaderloading ?
                                        <div style={{ textAlign: "center" }}>
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                        </div>
                                        :
                                        <>
                                            <div className="row p-2" >
                                                <div className='col-1'>
                                                    <label>Key</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                </div>
                                                <div className='col-4'>
                                                    <input
                                                        type="text"
                                                        name="custHeaderKey"
                                                        className={this.state.errorMessageCustHeaderKey.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                                        value={this.state.custHeaderKey}
                                                        onChange={this.onChange}
                                                    />
                                                    <small id="username-help" className="p-error">{this.state.errorMessageCustHeaderKey}</small>
                                                </div>

                                                <div className='col-1'>
                                                    <label>Value</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                </div>
                                                <div className='col-4'>
                                                    <input
                                                        type="text"
                                                        name="custHeaderValue"
                                                        className={this.state.errorMessageCustHeaderValue.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                                        value={this.state.custHeaderValue}
                                                        onChange={this.onChange}
                                                    />
                                                    <small id="username-help" className="p-error">{this.state.errorMessageCustHeaderValue}</small>
                                                </div>
                                                <div className='col-2'>
                                                    <Button label="ADD" loading={this.state.addCustomHeaderloading} onClick={this.onAddCustomHeaderClick} />
                                                </div>
                                            </div>
                                            <div>
                                                <DataTable value={this.state.customHeaderList}
                                                    rows={5} >

                                                    <Column field="name" header="Key" ></Column>
                                                    <Column field="value" header="Value" ></Column>
                                                    <Column body={this.actionBodyTemplateCustomeHeader} header="Action"></Column>
                                                </DataTable>
                                            </div>
                                        </>
                                }
                                </>
                            </Dialog>

                            <Dialog header="Header Passthrough" visible={this.state.showHeaderPassthrough} style={{ width: '50vw' }} onHide={() => this.onHeaderPassthroughClicked()} draggable={false}>
                                {
                                    this.state.addpassThroughKeyloading ?
                                        <div style={{ textAlign: "center" }}>
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                        </div>
                                        :
                                        <>
                                            <div className="row p-2" >
                                                <div className='col-1'>
                                                    <label>Key</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                </div>
                                                <div className='col-4'>
                                                    <input
                                                        type="text"
                                                        name="passThroughKey"
                                                        className={this.state.errorMessageCustHeaderKey.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                                        value={this.state.passThroughKey}
                                                        onChange={this.onChange}
                                                    />
                                                    <small id="username-help" className="p-error">{this.state.errorMessageCustHeaderKey}</small>
                                                </div>


                                                <div className='col-2'>
                                                    <Button label="ADD" loading={this.state.btnloadingsave} onClick={this.onAddHeaderPassthroughClick} />
                                                </div>
                                            </div>
                                            <div>
                                                <DataTable value={this.state.passThroughKeyList}
                                                    rows={5} >

                                                    <Column field="value" header="Value" ></Column>
                                                    <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                </DataTable>
                                            </div>
                                        </>
                                }

                            </Dialog>

                            <Dialog header="Field Mapping" visible={this.state.showFieldMappingPopup} style={{ width: '70vw' }} onHide={() => this.onFiledMappingClicked()} draggable={false}>
                                {
                                    this.state.igFieldsLoading ?
                                        <div style={{ textAlign: "center" }}>
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                        </div>
                                        :
                                        <>
                                            <div className="row p-2" >
                                                <div className='col-2'>
                                                    <label>IG Field</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                </div>
                                                <div className='col-3'>
                                                    <Dropdown value={this.state.igField}
                                                        options={this.state.igFieldList}
                                                        onChange={this.onigFieldChange}
                                                        optionLabel="igigField"
                                                        placeholder="Select"
                                                        style={{ width: "100%" }}
                                                        filter
                                                        className={this.state.errorMessageIgField.length > 0 ? "p-invalid p-mr-2" : ""}
                                                    />
                                                    <small id="username-help" className="p-error">{this.state.errorMessageIgField}</small>
                                                </div>
                                                <div className='col-2'>
                                                    <Button label="ADD IG FIELD" loading={this.state.addFieldLoading} onClick={this.onIGFieldMappingClicked} />
                                                </div>
                                                <div className='col-1'>
                                                    <label>Value</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                </div>
                                                <div className='col-2'>
                                                    <input
                                                        type="text"
                                                        name="fieldMapingName"
                                                        className={this.state.errorMessageName.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                                        value={this.state.fieldMapingName}
                                                        onChange={this.onChange}
                                                    />
                                                    <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                                                </div>
                                                <div className='col-2'>
                                                    <Button label="ADD" loading={this.state.addFieldLoading} onClick={this.onAddFieldMappingClick} />
                                                </div>
                                            </div>
                                            <div className="row p-2" >
                                                <div className='col-2'>
                                                    <label>Add Formula</label>
                                                </div>
                                                <div className='col-2'>
                                                    <InputSwitch checked={this.state.hasFormula} onChange={(e) => this.setState({ hasFormula: e.value })} />
                                                </div>
                                                {
                                                    this.state.hasFormula ?
                                                        <>
                                                            <div className='col-1'></div>
                                                            <div className='col-1'>
                                                                <label>Formula</label>
                                                                <span style={{ color: "red" }}>*</span>
                                                            </div>
                                                            <div className='col-4'>
                                                                <input
                                                                    type="text"
                                                                    name="fieldFormula"
                                                                    className={this.state.errorMessagefieldFormula.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                                                    value={this.state.fieldFormula}
                                                                    onChange={this.onChange}
                                                                />
                                                                <small id="username-help" className="p-error">{this.state.errorMessagefieldFormula}</small>
                                                            </div>
                                                        </>
                                                        :
                                                        null}
                                            </div>

                                            <div>
                                                <DataTable value={this.state.fieldMappingList}
                                                    rows={5} >

                                                    <Column field="igigField" header="IG Field" ></Column>
                                                    <Column field="jsonPath" header="Value" ></Column>
                                                    <Column field="formula" header="Formula" ></Column>
                                                    <Column body={this.actionBodyTemplateFieldMapping} header="Action"></Column>
                                                </DataTable>
                                            </div>
                                        </>
                                }
                            </Dialog>

                            <Dialog header="IG Field" visible={this.state.showIGFieldMappingPopup} style={{ width: '70vw' }} onHide={() => this.onIGFieldMappingClicked()} draggable={false}>

                                {
                                    this.state.addFieldLoading ?
                                        <div style={{ textAlign: "center" }}>
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                        </div>
                                        :
                                        <div className="row">
                                            <div className='col-2'>
                                                <label>IG Field</label>
                                                <span style={{ color: "red" }}>*</span>
                                            </div>
                                            <div className='col-4'>
                                                <input
                                                    type="text"
                                                    name="igFieldText"
                                                    className={this.state.errorMessageIgFieldText.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                                    value={this.state.igFieldText}
                                                    onChange={this.onChange}
                                                />
                                                <small id="username-help" className="p-error">{this.state.errorMessageIgFieldText}</small>
                                            </div>


                                            <div className='col-6'>
                                                <Button label="ADD" loading={this.state.btnloadingsave} onClick={this.onAddIgFieldClick} style={{ float: "right" }} />
                                            </div>
                                        </div>
                                }
                            </Dialog>
                        </>
                }
            </>
        )
    }
}
