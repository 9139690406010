import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import { confirmPopup } from 'primereact/confirmpopup';
var axios = require('axios');

export default class Screenshot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            serviceId: 0,
            ssURL: "",
            ssName: "",
            screenshot: "",
            selectScreenshot: "",
            errorMessage: "",
            successMessage: "",
        }
    }
    componentDidMount = () => {
        this.setState({
            serviceId: this.props.serviceId,
        })
        if (this.props.serviceId != 0) {
            this.getScreenshotDetails(this.props.serviceId, 'Screenshot')
        }
    }
    checkValidation = () => {

        if (this.state.ssName == "" || this.state.ssName == null) {
            this.setState({
                inputTextNameErrorClass: true,
                errorMessageName: "Please enter screenshot name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextNameErrorClass: false,
                    errorMessageName: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.selectScreenshot == "" || this.state.selectScreenshot == null) {
            this.setState({
                inputTextSelectSshotErrorClass: true,
                errorMessageSelectSS: "Please select screenshot "
            });
            setTimeout(() => {
                this.setState({
                    inputTextSelectSshotErrorClass: false,
                    errorMessageSelectSS: "",
                });
            }, 3000);

            return false;
        }

        return true
    }
    imageBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img src={rowData.documentURL} style={{ width: "100px" }} />
            </React.Fragment>
        )
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="fa fa-trash " aria-hidden="true" onClick={(e) => this.onConfirmDeleteClick(e, rowData.id)} style={{ cursor: "pointer" }}></i>
            </React.Fragment>
        );
    }
    onConfirmDeleteClick = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Do you want to delete this screenshot?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => this.onDeleteServiceDocuments(id),
            reject: this.reject
        });
    }
    onDeleteServiceDocuments = (id) => {
        this.setState({
            loading: true,
        })
        var token = CommonValues.GetToken();
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/deleteservicedocument?id=${id}&serviceId=${this.props.serviceId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    loading: false,
                    successMessage: "Screenshot deleted successfully."
                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getScreenshotDetails(this.props.serviceId, 'Screenshot')

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    getScreenshotDetails = (Val, Type) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getservicedocuments?serviceId=${Val}&DocumentType=${Type}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
         
                this.setState({
                    screenshot: response.data,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onScreenshotSelect = (e) => {
        this.setState({ selectScreenshot: e.target.files[0] });
    }
    onSaveServiceScreenshotClicked = () => {

        if (this.checkValidation()) {
            this.setState({
                btnloading: true
            })
            var axios = require('axios');
            var token = CommonValues.GetToken();
            var FormData = require('form-data');
            var fs = require('fs');
            var data = new FormData();
            data.append('sourcefile', this.state.selectScreenshot);
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ServicesService_Url}/api/services/upload/screenshot?serviceId=${this.props.serviceId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({
                        ssURL: response.data
                    })
                    this.onSaveClicked();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false,
                        errorMessage: "Unknown error while uploading screenshot."
                    })
                    if (error.response != null) {

                    }
                });
        }
    };
    onSaveClicked = () => {
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertscreenshotdocument?DocumentType=Screenshot&id=${this.props.serviceId}&Url=&Name=${this.state.ssName}&json=${this.state.ssURL}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    selectScreenshot: "",
                    ssName: "",
                    btnloading: false,
                    successMessage: "Screenshot uploaded successfully."
                })
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getScreenshotDetails(this.props.serviceId, 'Screenshot')
              
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    btnloading: false
                })
                if (error.response != null) {

                }
            });

    }

    render() {
        return (
            <div>
              
                        <>
                            <div>
                                <label className="form-label">
                                    Screenshot Name
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="text"
                                    id="ssName"
                                    name="ssName"
                                    value={this.state.ssName}
                                    className={this.state.inputTextNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageName}</small>

                            </div>
                            <div>
                                <label className="form-label">
                                    Select Screenshot
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="file"
                                    className={this.state.inputTextSelectSshotErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    // name="file"
                                    onChange={this.onScreenshotSelect}
                                    name="selectScreenshot"
                                    accept="image/png,  image/jpeg"
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageSelectSS}</small>

                            </div>
                            <br />
                            {
                                this.state.loading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    :
                                    <div className="form-row">
                                        {
                                            this.state.screenshot != null && this.state.screenshot.length > 0 ?
                                                <div className="col-12" >
                                                    <DataTable value={this.state.screenshot} paginator
                                                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                                        rows={5} responsiveLayout="scroll" >
                                                        <Column field="name" header=" Name" sortable></Column>
                                                        <Column body={this.imageBodyTemplate} header="Screenshot" sortable></Column>
                                                        <Column body={this.actionBodyTemplate} header="Action" sortable></Column>
                                                    </DataTable>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                            }
                            <br/>
                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">
                                    {/* <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onScreenshotSaveClick} style={{ float: "right" }} /> */}

                                    {
                                        this.state.serviceId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveServiceScreenshotClicked} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSaveServiceScreenshotClicked} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>

                        </>
            

            </div>
        );
    }
}
