import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import CommonValues from '../../Common/Util';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { FileUpload } from 'primereact/fileupload';

export default class Agreement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: "",
            successMessage: "",
            documentStatus: "",
            viewDocumentMessage: "",
            showViewCreatedDocument: false,
            isDocRequested: false,
            selectedfile: "",
            uploaddocumentloading: false
        }

    }
    componentDidMount = () => {

        this.getCompanyDocument();
    }

   
    getCompanyDocument = () => {
        this.setState({
            companyDocumentLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/signeddocuments?CompanyId=${this.props.companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)

            .then((response) => {
                this.setState({
                    companyDocumentLoading: false
                })
             
              //  this.getContactListDetails("");
              
                if (response.data.length > 0) {

                    this.setState({
                        documentUrl: response.data[0].documentURL,
                        envelopeId: response.data[0].envelopeId,
                        documentStatus: response.data[0].status
                    })
                    const status = response.data[0].status.split("-");

                    if (status[1] != null && status[1] != "") {
                        this.setState({
                            documentStatus: status[1]
                        });
                    }
                    if (response.data[0].status != "" && response.data[0].status != null) {
                        this.setState({
                            isDocRequested: true
                        })
                    }
                }
                else {
                    this.setState({
                        documentStatus: "Not Signed"
                    })
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyDocumentLoading: false,
                    companyDocumentLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getContactListDetails = (searchText) => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/searchcontactdetails?companyId=${this.props.companyId}&searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        this.setState({
            companyDocumentLoading: true,
        })
        axios(config)
            .then((response) => {

                this.setState({
                    contactList: response.data,
                    companyDocumentLoading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    companyDocumentLoading: false,
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }

    closeDialog = () => {
        this.setState({
            showViewCreatedDocument: false
        })
    }
    onViewDocumentClicked = (envelopeId) => {
        this.setState({
            getDocumentLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "EnvelopeId": envelopeId

        });

        var config = {
            method: 'post',
            responseType: 'blob',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/document/getdocumentbyenvelopeid`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    getDocumentLoading: false
                })

                if (response.data.size > 0) {
                    const a = document.createElement('a');
                    a.style.display = "none";
                    document.body.appendChild(a);
                    const blobFile = new Blob([response?.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blobFile);

                    window.open(url, "_blank");
                }
                else {
                    this.setState({
                        showViewCreatedDocument: true,
                        viewDocumentMessage: "Signed document will be available shortly."
                    })
                }



            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getDocumentLoading: false
                })
            });

    }

    onViewSentDocumentClick = () => {
        this.setState({
            viewLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            responseType: 'blob',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/document/viewdocument`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)

            .then((response) => {

                this.setState({
                    viewLoading: false
                });

                const a = document.createElement('a');
                a.style.display = "none";
                document.body.appendChild(a);
                const blobFile = new Blob([response?.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blobFile);

                window.open(url, "_blank");
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    viewLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
   
    onStartSigningClicked = () => {
        this.setState({
            errorMessageContact: ""
        })
        if (this.state.contact == null || this.state.contact == "") {
            this.setState({
                errorMessageContact: "Please select an contact"
            })
            return;
        }

        this.setState({
            startDocumentSigningLoading: true
        })

        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "FullName": this.state.contact.fullName,
            "UserName": this.state.contact.workEmail,
            "UserId": this.state.contact.userId,
            "CompanyId": parseInt(this.props.companyId),
            "WorkEmail": this.state.contact.workEmail
        })

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/document/sendsigningrequest`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)

            .then((response) => {
                this.setState({
                    startDocumentSigningLoading: false,
                })

                this.getCompanyDocument();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    startDocumentSigningLoading: false,
                })

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
                else {

                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onDocumentUploadChange = (e) => {
        this.setState({ selectedfile: e.target.files[0] });
    }
    onDocumentUploadClick = () => {
        if(this.checkValidation()){
        this.setState({
            uploaddocumentloading: true
        })
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('sourcefile', this.state.selectedfile);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/document/upload/signeddocument?CompanyId=${this.props.companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    uploaddocumentloading: false,
                    successMessage: "Document uploaded successfully!"
                })
                this.getCompanyDocument();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    uploaddocumentloading: false
                })

            });
        }
    }
    checkValidation = () => {

        if (this.state.selectedfile == "" || this.state.selectedfile == null) {
          this.setState({
            inputFileSelectClass: true,
            errorMessageFileSelect: "Please select file"
          });
          setTimeout(() => {
            this.setState({
                inputFileSelectClass: false,
                errorMessageFileSelect: "",
            });
          }, 3000);
    
          return false;
        }
    
        return true
      }
    render() {

        return (
            <div>
                {
                    this.state.companyDocumentLoading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>InsuranceGIG - Mutual Confidentiality Agreement</td>
                                        <td>{this.state.documentStatus == "delivered" ? "Processing" : this.state.documentStatus.charAt(0).toUpperCase() + this.state.documentStatus.slice(1)}</td>
                                        
                                        <td style={{ width: "300px"}}>

                                            {
                                                this.state.documentStatus == 'Not Signed' ?
                                                    <>
                                                        {
                                                            this.state.startDocumentSigningLoading || this.state.viewLoading ?
                                                                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                :
                                                                <>
                                                                    {this.state.getDocumentLoading ? <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" /> :
                                                                     <Button label="View" onClick={this.onViewSentDocumentClick} width="200px"></Button>}
                                                                    &emsp;
                                                                    {/* <Button label="Initiate Signing" onClick={this.onStartSigningClicked} /> */}
                                                                </>
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        {this.state.documentStatus == "completed" ?
                                                            <>
                                                                {
                                                                    this.state.getDocumentLoading ?
                                                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                                        :
                                                                        <Button label="View Signed Document" onClick={() => this.onViewDocumentClicked(this.state.envelopeId)} />
                                                                }


                                                            </>

                                                            :
                                                            <>
                                                                {this.state.viewLoading ? <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" /> : <Button label="View Document" onClick={this.onViewSentDocumentClick} width="200px"></Button>}
                                                            </>
                                                        }
                                                    </>

                                            }
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <>
                                {this.state.documentStatus == "completed" ?
                                    null
                                    :
                                    <>
                                        <h4 className='text-center'>OR</h4>
                                        {this.state.uploaddocumentloading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px', display: "flex", alignitems: "center" }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <div className='row align-items-center'>
                                                <div className='col-3'>
                                                    <label>Select document to upload</label>
                                                </div>
                                                <div className='col-6'>
                                                    <input type="file"
                                                        className={this.state.inputFileSelectClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                                        onChange={this.onDocumentUploadChange}
                                                        name="selectedfile"
                                                        accept="application/pdf"
                                                    />
                                                    <small id="username-help" className="p-error">{this.state.errorMessageFileSelect}</small>

                                                </div>
                                                <div className='col-3 text-right'>
                                                    <Button label="Upload" onClick={this.onDocumentUploadClick} />
                                                </div>
                                            </div>

                                        }
                                    </>
                                }
                            </>
                            <div className='row'>
                                <div className="col-4"></div>
                                <div className="col-4 mt-2">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "left" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "left" }} />
                                        : null}
                                </div>
                            </div>
                        </>

                }
                <Dialog header="Document Information!" visible={this.state.showViewCreatedDocument} style={{ width: '50vw' }} onHide={this.closeDialog} draggable={false}>
                    <p>{this.state.viewDocumentMessage}</p>
                </Dialog>
            </div >
        )
    }
}
