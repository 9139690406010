import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import CommonValues from '../../Common/Util';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';

class Seller extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            workflowId: 0,
            errorMessage: "",
            errorMessagepp: "",
            successMessage: "",
            showPanel: false,
            partnerName: "",
            partnerType: "Seller",
            status: "",
            costType: "Per Call",
            description: "",
            sellerId: 0,
            errorMessagePartnerName: "",
            errorMessageStatus: "",
            errorMessageUnit: "",
            Unit: "",
            cost: "",
            percent: "",
            igfee: "",
            costUnit: "",
            errorMessageCent: "",
            errorMessagePercent: "",
            errorMessageIgFee: "",
            errorMessagePlanName: "",
            priceplanlist: [],
            addPricePlanPopup: false,

            costTypepp: "",
            Unitpp: "",
            costpp: "",
            percentpp: "",
            igfeepp: "",
            PricePlanName: "",
            errorMessageCostUnit: ""
        }
        this.unitOfMeasureList = [
            { "name": "Per Each" },
            { "name": "Per X Volume" }

        ]
    }
    componentDidMount = () => {

        this.getAllCompanyList('');
        // this.getAllWorkflowStatus();

    }
    getAllCompanyList = (searchText) => {

        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyList: response.data,

                });
                this.getAllPricePlan();


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getAllWorkflowStatus = () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/workflowstatus`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    statusList: response.data,

                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({

                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getAllPricePlan = () => {
        this.setState({
            loading: true
        })
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/priceplan`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    priceplanlist: response.data,
                    loading: false
                });
                this.getWorkflowImplementationSeller();

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    getWorkflowImplementationSeller = () => {

        if (this.props.implementationSeller != undefined) {
            this.setState({
                sellerId: this.props.implementationSeller.workflowImplementationSellerId,

            });
            this.state.companyList.forEach(element => {
                if (element.name == this.props.implementationSeller.seller) {
                    this.setState({
                        partnerName: element
                    })
                }
            });

            this.state.priceplanlist.forEach(element => {
                if (element.id == this.props.implementationSeller.pricePlanId) {
                    this.setState({
                        priceplan: element,
                        costTypepp: element.costType,
                        Unitpp: element.unit,
                        costpp: element.costInCents,
                        percentpp: element.costInPercentage,
                        igfeepp: element.igComission
                    })
                    this.unitOfMeasureList.forEach(unit => {
                        if (unit.name == element.unitOfMeasure) {
                            this.setState({
                                costUnitpp: unit
                            })
                        }

                    });
                }
            })

        }





    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onPartnerChange = (e) => {
        this.setState({
            partnerName: e.value
        })
    }
    onStatusChange = (e) => {
        this.setState({
            status: e.value
        })
    }
    onCostUnitChange = (e) => {
        this.setState({
            costUnit: e.value
        })
    }
    onPriceplanChange = (e) => {
        this.setState({
            priceplan: e.value,
            costTypepp: e.value.costType,
            Unitpp: e.value.unit,
            costpp: e.value.costInCents,
            percentpp: e.value.costInPercentage,
            igfeepp: e.value.igComission
        })
        this.unitOfMeasureList.forEach(element => {
            if (element.name == e.value.unitOfMeasure) {
                this.setState({
                    costUnitpp: element
                })
            }

        });
    }
    isValidPricePlan = () => {
        let valid = true;
        this.setState({
            successMessage: "",
            errorMessage: "",
            errorMessageUnit: "",
            errorMessageCent: "",
            errorMessagePercent: "",
            errorMessageIgFee: "",
            errorMessagePlanName: "",
            errorMessageCostUnit: "",
            errorMessagepp:""
        })
        if (this.state.PricePlanName == "") {
            this.setState({
                errorMessagePlanName: "Please enter a price plan name."
            });
            valid = false
        }

        if (this.state.costUnit == undefined || this.state.costUnit == null || this.state.costUnit == "") {
            this.setState({
                errorMessageCostUnit: "Please select unit of measure."
            });
            valid = false;
        }
        if (this.state.Unit == undefined || this.state.Unit == null || this.state.Unit == "") {
            this.setState({
                errorMessageUnit: "Please enter unit."
            });
            valid = false;
        }
        const regex = /^(?!0\d)\d*(\.\d+)?$/;
        if (this.state.Unit != "" && this.state.Unit != null) {
            if (regex.test(this.state.Unit) === false) {
                this.setState({
                    errorMessageUnit: "Please enter a valid unit."
                })
                valid = false
            }
        }
        if (this.state.costType == "Per Call") {
            if (this.state.cost == undefined || this.state.cost == null || this.state.cost == "") {
                this.setState({
                    errorMessageCent: "Please enter cent."
                });
                valid = false;
            }
        }
        if (this.state.cost != "" && this.state.cost != null) {
            if (regex.test(this.state.cost) === false) {
                this.setState({
                    errorMessageCent: "Please enter a valid cent."
                })
                valid = false
            }
        }
        if (this.state.costType == "% Based") {
            if (this.state.percent == undefined || this.state.percent == null || this.state.percent == "") {
                this.setState({
                    errorMessagePercent: "Please enter percent."
                });
                valid = false;
            }
        }
        if (this.state.percent != "" && this.state.percent != null) {
            if (regex.test(this.state.percent) === false) {
                this.setState({
                    errorMessagePercent: "Please enter a valid percent."
                })
                valid = false
            }
        }
        if (this.state.igfee == undefined || this.state.igfee == null || this.state.igfee == "") {
            this.setState({
                errorMessageIgFee: "Please enter fee percent."
            });
            valid = false;
        }
        if (this.state.igfee != "" && this.state.igfee != null) {
            if (regex.test(this.state.igfee) === false) {
                this.setState({
                    errorMessageIgFee: "Please enter a valid fee percent."
                })
                valid = false
            }
        }

        return valid;
    }
    isValid = () => {
        let valid = true;
        this.setState({
            errorMessagePartnerName: "",
            errorMessageStatus: "",
            successMessage: "",
            errorMessage: "",
            errorMessageUnit: "",
            errorMessageCent: "",
            errorMessagePercent: "",
            errorMessageIgFee: "",
            errorMessagepp:""

        })
        if (this.state.partnerName.companyId == undefined) {
            this.setState({
                errorMessagePartnerName: "Please select seller",
            });
            valid = false;
        }


        // if (this.state.status.id == undefined) {
        //     this.setState({
        //         errorMessageStatus: "Please select status",
        //     });
        //     valid = false;
        // }
        return valid;
    }
    onSaveSellerClicked = (e) => {
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true
            });
            let priceplanid = 0;
            console.log(this.state.priceplan)
            if (this.state.priceplan != undefined) {
                priceplanid = this.state.priceplan.id
            }
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "WorkflowImplementation": this.props.implementationId.toString(),
                "Seller": this.state.partnerName.companyId.toString(),
                //"Status": this.state.status.id.toString(),

                "PricePlanId": parseInt(priceplanid)

            });
            console.log(data)
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/addseller`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    //this.props.onSaveSellerAndPageRefresh();
                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Seller saved successfully...!!!",
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.props.onSaveSellerAndPageRefresh()
                    //                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    });

                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "Seller with the same name already exists."
                            })
                            setTimeout(
                                () => this.setState({ successMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp's something went wrong."
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                    }
                });
        }
    }
    onUpdateSellerClicked = (e) => {
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true
            });
            let priceplanid = 0;
            if (this.state.priceplan != undefined) {
                priceplanid = this.state.priceplan.id
            }
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "WorkflowImplementation": this.props.implementationId.toString(),
                "Seller": this.state.partnerName.companyId.toString(),
                // "Status": this.state.status.id.toString(),
                "WorkflowImplementationSellerId": this.state.sellerId,
                "PricePlanId":parseInt(priceplanid)
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/updateseller`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Seller updated successfully...!!!",

                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.props.onSaveSellerAndPageRefresh()
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    });

                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "Seller with the same name already exists."
                            })
                            setTimeout(
                                () => this.setState({ successMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp's something went wrong."
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                    }
                });
        }
    }
    onAddNewPricePlanClicked = () => {
        this.setState({
            addPricePlanPopup: !this.state.addPricePlanPopup,
            successMessage: "",
            errorMessage: "",
            errorMessagepp:"",
            errorMessageUnit: "",
            errorMessageCent: "",
            errorMessagePercent: "",
            errorMessageIgFee: "",
            errorMessagePlanName: "",
            errorMessageCostUnit: "",
            cost: "",
            percent: "",
            costType: "Per Call",
            igfee: "",
            Unit: "",
            costUnit: "",
            PricePlanName: ""
        });
    }
    onSavePricePlanClicked = () => {
        if (this.isValidPricePlan()) {

            this.setState({
                btnloadingsavepp: true
            });
            let costUnit = "";
            if (this.state.costUnit != undefined) {
                costUnit = this.state.costUnit.name
            }
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "CostInCents": this.state.cost.toString(),
                "CostInPercentage": this.state.percent.toString(),
                "CostType": this.state.costType,
                "IGComission": this.state.igfee,
                "Unit": this.state.Unit,
                "UnitOfMeasure": costUnit,
                "PlanName": this.state.PricePlanName

            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/addpriceplan`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({
                        btnloadingsavepp: false,
                        cost: "",
                        percent: "",
                        costType: "",
                        igfee: "",
                        Unit: "",
                        costUnit: "",
                        PricePlanName: "",
                        addPricePlanPopup: false

                    });
                    this.getAllPricePlan();
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsavepp: false
                    });

                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else if (error.response.status == 409) {
                            this.setState({
                                errorMessagepp: "Price Plan with the same name already exists."
                            })
                            setTimeout(
                                () => this.setState({ successMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessagepp: "Opp's something went wrong."
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                    }
                });
        }
    }
    render() {
        
        return (
            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div className='row'>
                                <div className='col-2'>
                                    <label>Partner Name</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <Dropdown value={this.state.partnerName}
                                        options={this.state.companyList}
                                        onChange={this.onPartnerChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        filter
                                        className={this.state.errorMessagePartnerName.length > 0 ? "p-invalid p-mr-2" : ""}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessagePartnerName}</small>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-2'>
                                    <label>Partner Type</label>
                                </div>
                                <div className='col-10'>
                                    <RadioButton disabled inputId="Buyer" name="partnerType" value="Buyer" onChange={(e) => this.setState({ partnerType: e.value })} checked={this.state.partnerType === 'Buyer'} />
                                    <label htmlFor="Buyer" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Consumer</label>
                                    <RadioButton disabled inputId="Seller" name="partnerType" value="Seller" onChange={(e) => this.setState({ partnerType: e.value })} checked={this.state.partnerType === 'Seller'} />
                                    <label htmlFor="Seller" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Seller</label>
                                </div>
                            </div>

                            {/* <div className='row'>
                                <div className='col-2'>
                                    <label>Status</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <Dropdown value={this.state.status}
                                        options={this.state.statusList}
                                        onChange={this.onStatusChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        className={this.state.errorMessageStatus.length > 0 ? "p-invalid p-mr-2" : ""} />
                                    <small id="username-help" className="p-error">{this.state.errorMessageStatus}</small>

                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col-2'> <label>Price Plan</label></div>
                                <div className='col-8'>
                                    <Dropdown value={this.state.priceplan}
                                        options={this.state.priceplanlist}
                                        onChange={this.onPriceplanChange}
                                        optionLabel="planName"
                                        placeholder="Select"
                                        style={{ width: "100%" }} />
                                </div>
                                <div className='col-2'>
                                    <Button label="Add New" icon="pi pi-check" onClick={this.onAddNewPricePlanClicked} style={{ float: "right" }} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>
                                    <label>Cost Type</label>
                                </div>
                                <div className='col-10'>
                                    <RadioButton inputId="Per Call" name="costType" value="Per Call" onChange={(e) => this.setState({ costTypepp: e.value })} checked={this.state.costTypepp === 'Per Call'} disabled />
                                    <label htmlFor="Per Call" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Per Call</label>
                                    <RadioButton inputId="% Based" name="costType" value="% Based" onChange={(e) => this.setState({ costTypepp: e.value })} checked={this.state.costTypepp === '% Based'} disabled />
                                    <label htmlFor="% Based" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>% Based</label>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-2'>
                                    <label>Cost Structure</label>
                                </div>
                                <div className='col-10'>
                                    <table >
                                        <tr>
                                            <td style={{ border: "1px double black" }}>
                                                Unit of measure
                                            </td>
                                            <td style={{ border: "1px double black" }}>
                                                <Dropdown value={this.state.costUnitpp}
                                                    options={this.unitOfMeasureList}
                                                    onChange={this.onCostUnitChange}
                                                    optionLabel="name"
                                                    placeholder="Select"
                                                    style={{ width: "100%" }}
                                                    disabled />
                                            </td>

                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px double black" }}>
                                                Unit
                                            </td>
                                            <td style={{ border: "1px double black" }}>
                                                <input
                                                    type="text"
                                                    name="Unit"
                                                    className={"disabled-white-color form-control"}
                                                    value={this.state.Unitpp}
                                                    onChange={this.onChange}
                                                    placeholder="Enter Unit"
                                                    disabled
                                                />
                                            </td>


                                        </tr>
                                        <tr >
                                            {
                                                this.state.costTypepp == "Per Call" ?
                                                    <>
                                                        <td style={{ border: "1px double black" }}>
                                                            Cost in cent
                                                        </td>
                                                        <td style={{ border: "1px double black" }}>
                                                            <input
                                                                type="text"
                                                                name="cost"
                                                                className={"disabled-white-color form-control"}
                                                                value={this.state.costpp}
                                                                onChange={this.onChange}
                                                                placeholder="Enter Cent"
                                                                disabled
                                                            />
                                                        </td>

                                                    </>
                                                    :
                                                    <>
                                                        <td style={{ border: "1px double black" }}>
                                                            Cost in percent
                                                        </td>
                                                        <td style={{ border: "1px double black" }}>
                                                            <input
                                                                type="text"
                                                                name="percent"
                                                                className={"disabled-white-color form-control"}
                                                                value={this.state.percentpp}
                                                                onChange={this.onChange}
                                                                placeholder="Enter Percent"
                                                                disabled
                                                            />
                                                        </td>
                                                    </>
                                            }


                                        </tr>


                                    </table>
                                </div>
                            </div><br />

                            <div className='row'>
                                <div className='col-2'>
                                    <label>IG Fee (%)</label>
                                </div>
                                <div className='col-3'>
                                    <input
                                        type="text"
                                        name="igfee"
                                        className={"form-control disabled-white-color"}
                                        value={this.state.igfeepp}
                                        onChange={this.onChange}
                                        placeholder="Enter IG Fee"
                                        disabled
                                    />

                                </div>

                            </div>


                            {/* <div className='row'>
                    <div className='col-2'>
                        <label>Description</label>
                    </div>
                    <div className='col-10'>
                        <textarea
                            type="text"
                            name="description"
                            className="form-control"
                            value={this.state.description}
                            onChange={this.onChange}
                        />
                    </div>
                </div><br/> */}
                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.sellerId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onSaveSellerClicked} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onUpdateSellerClicked} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>
                        </>
                }
                <Dialog header="Price Plan" visible={this.state.addPricePlanPopup} style={{ width: '75vw' }} onHide={() => this.onAddNewPricePlanClicked()} draggable={false}>
                    <>
                        <div className='row'>
                            <div className='col-2'>
                                <label>Price Plan Name</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    name="PricePlanName"
                                    className={this.state.errorMessagePlanName.length > 0 ? "p-invalid p-mr-2 form-control" : " form-control"}
                                    value={this.state.PricePlanName}
                                    onChange={this.onChange}
                                    placeholder="Enter Price Plan Name"
                                />
                                <div className="validation-error-msg">
                                    <span>{this.state.errorMessagePlanName}</span>
                                </div>
                                {/* <small id="username-help" className="validation-error-msg">{this.state.errorMessageIgFee}</small> */}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-2'>
                                <label>Cost Type</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <RadioButton inputId="Per Call" name="costType" value="Per Call" onChange={(e) => this.setState({ costType: e.value })} checked={this.state.costType === 'Per Call'} />
                                <label htmlFor="Per Call" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Per Call</label>
                                <RadioButton inputId="% Based" name="costType" value="% Based" onChange={(e) => this.setState({ costType: e.value })} checked={this.state.costType === '% Based'} />
                                <label htmlFor="% Based" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>% Based</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-2'>
                                <label>Cost Structure</label>
                                
                            </div>
                            <div className='col-10'>
                                <table >
                                    <tr>
                                        <td >
                                            Unit of measure<span style={{ color: "red" }}>*</span>
                                        </td>
                                        <td></td>
                                        <td >
                                            <Dropdown value={this.state.costUnit}
                                                options={this.unitOfMeasureList}
                                                onChange={this.onCostUnitChange}
                                                optionLabel="name"
                                                placeholder="Select"
                                                style={{ width: "100%" }}
                                                className={this.state.errorMessageCostUnit.length > 0 ? "p-invalid p-mr-2" : ""}
                                            />
                                            <div className="validation-error-msg">
                                                <span>{this.state.errorMessageCostUnit}</span>
                                            </div>
                                          

                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Unit<span style={{ color: "red" }}>*</span>
                                        </td>
                                        <td style={{ width: "20px" }}></td>
                                        <td >
                                            <input
                                                type="text"
                                                name="Unit"
                                                className={this.state.errorMessageUnit.length > 0 ? "p-invalid p-mr-2 form-control" : " form-control"}
                                                value={this.state.Unit}
                                                onChange={this.onChange}
                                                placeholder="Enter Unit"

                                            />
                                            <div className="validation-error-msg">
                                                <span>{this.state.errorMessageUnit}</span>
                                            </div>
                                        </td>
                                        <td>
                                            
                                        </td>

                                    </tr>
                                    <tr >
                                        {
                                            this.state.costType == "Per Call" ?
                                                <>
                                                    <td >
                                                        Cost in cent<span style={{ color: "red" }}>*</span>
                                                    </td>
                                                    <td style={{ width: "20px" }}></td>
                                                    <td >
                                                        <input
                                                            type="text"
                                                            name="cost"
                                                            className={this.state.errorMessageCent.length > 0 ? "p-invalid p-mr-2 form-control" : " form-control"}
                                                            value={this.state.cost}
                                                            onChange={this.onChange}
                                                            placeholder="Enter Cent"

                                                        />
                                                         <div className="validation-error-msg">
                                                            <span>{this.state.errorMessageCent}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                       
                                                    </td>
                                                </>
                                                :
                                                <>
                                                    <td >
                                                        Cost in percent<span style={{ color: "red" }}>*</span>
                                                    </td>
                                                    <td style={{ width: "20px" }}></td>
                                                    <td >
                                                        <input
                                                            type="text"
                                                            name="percent"
                                                            className={this.state.errorMessagePercent.length > 0 ? "p-invalid p-mr-2 form-control" : " form-control"}
                                                            value={this.state.percent}
                                                            onChange={this.onChange}
                                                            placeholder="Enter Percent"

                                                        />
                                                        <div className="validation-error-msg">
                                                            <span>{this.state.errorMessagePercent}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        
                                                    </td></>
                                        }


                                    </tr>


                                </table>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className='col-2'>
                                <label>IG Fee (%)</label>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    name="igfee"
                                    className={this.state.errorMessageIgFee.length > 0 ? "p-invalid p-mr-2 form-control" : " form-control"}
                                    value={this.state.igfee}
                                    onChange={this.onChange}
                                    placeholder="Enter IG Fee"
                                />
                                <div className="validation-error-msg">
                                    <span>{this.state.errorMessageIgFee}</span>
                                </div>
                                {/* <small id="username-help" className="validation-error-msg">{this.state.errorMessageIgFee}</small> */}
                            </div>
                        </div>
                        <br/>
                        <div className='row'>
                            <div className='col-10'>
                                {this.state.errorMessagepp != "" ? <Message severity="error" text={this.state.errorMessagepp} style={{ float: "right" }} />
                                    : null}
                            </div>
                            <div className='col-2'>
                                <Button label="Add" icon="pi pi-check" loading={this.state.btnloadingsavepp} onClick={this.onSavePricePlanClicked} style={{ float: "right" }} />
                            </div>
                        </div>
                    </>
                </Dialog>
            </>
        )
    }
}
export default withRouter(Seller)
