import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Editor } from 'primereact/editor';
import CommonValues from '../../Common/Util';
import { Dropdown } from 'primereact/dropdown';
import { withRouter } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';

class APIDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiId: 0,
            loading: false,
            name: "",
            description: "",
            company: "",
            version: "",
            errorMessageCompanyName: "",
            errorMessageName:"",
            errorMessageVersion:"",
            companyList: [],
            errorMessage:"",
            successMessage:""
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            apiId: params.index,
        });
        this.getAllCompanyList('');
    }
    getAllCompanyList = (searchText) => {

        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyList: response.data,
                    loading: false
                });
                this.getAPIDetails(this.state.apiId, 'getbyid');
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getAPIDetails = (id, flag) => {

        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIMasters?id=${id}&flag=${flag}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false
                });
                if (response.data != null && response.data != "") {
                    this.setState({
                        name: response.data[0].name,
                        description: response.data[0].description,
                        version: response.data[0].versionName
                    })
                    this.state.companyList.forEach(element => {
                        if (element.name == response.data[0].company) {
                            this.setState({
                                company: element
                            })
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    onCompanyChange = (e) => {
        this.setState({
            company: e.value
        });
     
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }; 
   
    isValid = () => {
        let valid = true;
        this.setState({
            inputTextErrorClass: false,
            errorMessageName: "",
            errorMessageCompanyName: "",
            errorMessageVersion:"",
            errorMessage:""
        })
        if (this.state.name == "" || this.state.name == null) {
            this.setState({
                errorMessageName: true,
                errorMessageName: "Please enter  name"
            });
         
            valid = false;
        }
        
        if (this.state.company == "" || this.state.company == null) {
            this.setState({
                errorMessageCompanyName: true,
                errorMessageCompanyName: "Please select company name"
            });
         
            valid = false;
        }
        if (this.state.version == "" || this.state.version == null) {
            this.setState({
                errorMessageVersion: true,
                errorMessageVersion: "Please enter version"
            });
         
            valid = false;
        }


        return valid;
    } 
    onApiSaveUpdateClick=()=>{
        if (this.isValid()) {
        this.setState({
            btnloadingsave: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        const data=JSON.stringify({
            "Id":this.state.apiId,
            "Name":this.state.name,
            "Description":this.state.description,
            "VersionName":this.state.version,
            "companyid":this.state.company.companyId
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIMasters`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            data: data
        };

        axios(config)
            .then((response) => {
                
                this.setState({
                    btnloadingsave: false,
                    successMessage: "API details added successfully...!!!"
                })
                //this.getAPIDetails(response.data, 'getbyid');
              
                    window.location = `/datafabric/${response.data}`;
               
               
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    btnloadingsave: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if(error.response.status == 409)
                    {
                        this.setState({
                            errorMessage: "API with same name already exist."
                        })
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
        }
    }
    render() {

        return (
            this.state.loading ?
                <div style={{ textAlign: "center" }}>
                    <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
                :
                <>
                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Name</label>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className='col-10'>
                            <input
                                type="text"
                                name="name"
                                className={this.state.errorMessageName.length>0? "form-control p-invalid p-mr-2" : "form-control"}
                                value={this.state.name}
                                onChange={this.onChange}
                            />
                            <small id="username-help" className="p-error">{this.state.errorMessageName}</small>
                        </div>
                    </div>
                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Description</label>

                        </div>
                        <div className='col-10'>
                            <Editor style={{ height: '150px' }}
                                value={this.state.description}
                                onTextChange={(e) => this.setState({ description: e.htmlValue })}
                            />
                        </div>
                    </div>
                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Company</label>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className='col-10'>
                            <Dropdown value={this.state.company}
                                options={this.state.companyList}
                                onChange={this.onCompanyChange}
                                optionLabel="name"
                                placeholder="Select"
                                style={{ width: "100%" }}
                                filter
                                className={this.state.errorMessageCompanyName.length>0? "p-invalid p-mr-2" : ""}
                            />
                            <small id="username-help" className="p-error">{this.state.errorMessageCompanyName}</small>
                        </div>
                    </div>
                    <div className="row p-2" >
                        <div className='col-2'>
                            <label>Version</label>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className='col-10'>
                            <input
                                type="text"
                                name="version"
                                className={this.state.errorMessageVersion.length>0 ? "form-control p-invalid p-mr-2" : "form-control"}
                                value={this.state.version}
                                onChange={this.onChange}
                            />
                            <small id="username-help" className="p-error">{this.state.errorMessageVersion}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-10">
                            {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                : null}
                            {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                : null}
                        </div>
                        <div className="col-2">

                            {
                                this.state.apiId == 0 ?
                                    <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onApiSaveUpdateClick} style={{ float: "right" }} />
                                    :
                                    <Button label="Update" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onApiSaveUpdateClick} style={{ float: "right" }} />
                            }


                        </div>
                    </div>
                </>
        )
    }
}
export default withRouter(APIDetails)
