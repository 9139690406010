import React, { Component } from 'react';
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import APIDetails from './APIDetails';
import APIEndpoint from './APIEndpoint';
import EndpointDetails from './EndpointDetails';

export default class APIMaster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            apiId: 0,
            endpointId:0,
            endpointRefresh:true,
            hideEditOrAddBtn:true
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            apiId: params.index,
        });

    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    onBackButtonClick = () => {
        this.props.history.push("/datafabric")
    }
    apiDetailsTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    API Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    apiEndPointTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    API Endpoints
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    apiEndpointTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;
        
        return (
            
            <div className={className} style={{ padding: "10px" }}>
              
                    <h4 className={titleClassName} style={{ display: "inline-block" }}>Endpoint Details</h4>

                
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    showHideAddEndpoint = (show, id) => {
        this.setState({
            addEndpointPanel: show,
            endpointId: id,
            hideEditOrAddBtn:show
        })
    }
    onEndpointAdded=()=>{
        let endpointRefresh = this.state.endpointRefresh;
        endpointRefresh = !endpointRefresh;
        this.setState({endpointRefresh:endpointRefresh});

    }
    render() {
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>

                    {/* <PageHeader PageTitle={this.state.workflowId==0?"Add Workflow" : "Update Workflow"}></PageHeader> */}

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <i className="fa fa-arrow-left" aria-hidden="true" style={{ paddingRight: "5px", fontSize: "25px", cursor: "pointer" }} onClick={this.onBackButtonClick}></i>
                                        {this.state.apiId == 0 ? <h1 style={{ display: "inline-block" }}>Add New API</h1> : <h1 style={{ display: "inline-block" }}>Edit API</h1>}

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">

                                                    <Panel headerTemplate={this.apiDetailsTemplate} toggleable>
                                                        <APIDetails workflowId={this.state.apiId}></APIDetails>
                                                    </Panel><br />
                                                    {
                                                        this.state.apiId == 0 ?
                                                            null
                                                            :
                                                            <Panel headerTemplate={this.apiEndPointTemplate} toggleable collapsed>
                                                                <APIEndpoint
                                                                    showHideAddEndpoint={this.showHideAddEndpoint}
                                                                    endpointRefresh={this.state.endpointRefresh}
                                                                    hideEditOrAddBtn={this.state.hideEditOrAddBtn}
                                                                />
                                                            </Panel>
                                                    }<br />
                                                    {
                                                        this.state.addEndpointPanel ?
                                                            <Panel headerTemplate={this.apiEndpointTemplate} toggleable>
                                                                <EndpointDetails apiId={this.state.apiId} 
                                                                endpointId={this.state.endpointId} 
                                                                onEndpointAdded={this.onEndpointAdded}
                                                                showHideAddEndpoint={this.showHideAddEndpoint}/>
                                                                
                                                            </Panel>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

