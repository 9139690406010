import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dlogo from '../../assets/img/IGIGLogoFull.png';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import CommonValues from '../../Common/Util';
import { ProgressSpinner } from 'primereact/progressspinner';
var axios = require('axios');


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password: "",
            btnloading: false,
            errorMessage: "",
            inputTextErrorClassUname: "",
            errorMessageUname: "",
            inputTextErrorClassPass: "",
            errorMessagePass: "",
            loading: false
        }
    }
    componentDidMount = () => {
        var token = CommonValues.GetToken();
        if (token != null && token != undefined) {
            //this.props.history.push("/companylist");
            this.tryToken();
        }
    }


    tryToken = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=notfound`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false
                });
                this.props.history.push("/companylist");
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.ClearValues();
                    }
                }
            });
    }

    onSigninClicked = () => {
        if (this.isValid()) {

            this.setState({
                btnloading: true
            });
            var axios = require('axios');
            var data = JSON.stringify({
                "UserName": this.state.userName,
                "Password": this.state.password
            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Local_Url}/api/account/adminlogin?`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    CommonValues.SetValues(response.data.token, response.data.userId, response.data.orgName, response.data.userName, response.data.roleId, response.data.avatarName);

                    this.setState({
                        userName: "",
                        password: "",
                        btnloading: false
                    });
                    this.props.history.push("/companylist");
                })
                .catch((error) => {

                    console.log(error.response);

                    this.setState({
                        btnloading: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            this.setState({
                                errorMessage: "Invalid username or password"
                            });

                        }
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });


                    }
                });
        }
    }
    isValid = () => {

        this.setState({
            errorMessage: ""
        });

        this.setState({
            inputTextErrorClassUname: "",
            errorMessageUname: "",
            inputTextErrorClassPass: "",
            errorMessagePass: ""
        });

        if (this.state.userName == "" || this.state.userName == null) {
            this.setState({
                inputTextErrorClassUname: "p-invalid p-mr-2",
                errorMessageUname: "Please enter username"
            });
            return false;
        }
        if (this.state.password == "" || this.state.password == null) {
            this.setState({
                inputTextErrorClassPass: "p-invalid p-mr-2",
                errorMessagePass: "Please enter password"
            });
            return false;
        }
        return true
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.onSigninClicked();
        }
    }
    render() {
        return (
            <>
                <div className="hold-transition login-page">

                    <div className="login-box">
                        <div className="login-logo">
                            <a>
                                <img src={Dlogo} style={{ width: "300px" }} />
                            </a>
                        </div>
                        {/* /.login-logo */}
                        <div className="card">
                            {
                                this.state.loading ?
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                    :
                                    <div className="card-body login-card-body">
                                        <p className="login-box-msg">Sign in</p>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-group mb-3 input-group-login">
                                                            <div className="p-field p-fluid">
                                                                <label >Username</label>
                                                                <InputText
                                                                    aria-describedby="username-help"
                                                                    className={this.state.inputTextErrorClassUname}
                                                                    name="userName"
                                                                    onChange={this.onChange}
                                                                    value={this.state.userName}
                                                                    autoComplete="off"
                                                                />
                                                                <small id="username-help" className="p-error">{this.state.errorMessageUname}</small>
                                                            </div>
                                                        </div>
                                                        <div className="input-group mb-3 input-group-login">
                                                            <div className="p-field p-fluid">
                                                                <label >Password</label>
                                                                <InputText
                                                                    type="password"
                                                                    aria-describedby="username-help"
                                                                    className={this.state.inputTextErrorClassPass}
                                                                    name="password"
                                                                    onChange={this.onChange}
                                                                    value={this.state.password}
                                                                    autoComplete="off"
                                                                    onKeyDown={this.onKeyClick}
                                                                />
                                                                <small id="username-help" className="p-error">{this.state.errorMessagePass}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-7">
                                                        {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} />
                                                            : null}
                                                    </div>

                                                    <div className="col-5" style={{ textalign: "right" }}>
                                                        <Button label="Sign In" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSigninClicked} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="social-auth-links text-center mb-3">
                                        </div>

                                    </div>
                            }
                        </div>
                    </div>

                </div>
            </>
        )
    }
}
export default withRouter(Login);
