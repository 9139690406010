import React, { Component } from 'react'
import Footer from '../../Common/Footer';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import Sidebar from '../../Common/Sidebar';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import CommonValues from '../../Common/Util';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PartnerReport extends Component {
    constructor(props) {
        super(props);
        const previousMonth = new Date();
        previousMonth.setMonth(previousMonth.getMonth() - 1)

        this.state = {
            sidebarShow: true,
            loading: true,
            fromDate: previousMonth,
            toDate: new Date(),
            searchData: false,
            btnloading: false,
            exportLoading: false
        };

    }
    onFromDateChage = (e) => {
        this.setState({ fromDate: e.value });
    }
    onToDateChage = (e) => {
        this.setState({ toDate: e.value });
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }

    isValid = () => {
        if (this.state.fromDate == null || this.state.fromDate == '') {
            this.setState({
                inputTextFromDateErrorClass: true,
                fromDateError: 'Please select from date',
                emptyDateError: 'Please select from date'
            });
            setTimeout(() => {
                this.setState({
                    inputTextFromDateErrorClass: false,
                    fromDateError: "",
                    emptyDateError: "",
                });
            }, 3000);
            return false;
        }
        if (this.state.toDate == null || this.state.toDate == '') {
            this.setState({
                inputTextToDateErrorClass: true,
                toDateError: 'Please select to date',
                emptyDateError: 'Please select to date'
            });
            setTimeout(() => {
                this.setState({
                    inputTextToDateErrorClass: false,
                    toDateError: "",
                    emptyDateError: "",
                });
            }, 3000);
            return false;
        }
        if (this.state.fromDate > this.state.toDate) {
            this.setState({
                inputTextToDateErrorClass: true,
                toDateError: 'To date must be greater than from date'
            });
            setTimeout(() => {
                this.setState({
                    inputTextToDateErrorClass: false,
                    toDateError: "",
                });
            }, 3000);
            return false;
        }
        return true
    }
    onSearchClick = () => {
        if (this.isValid()) {
            this.setState({
                btnloading: true
            })
            var axios = require('axios');
            var token = CommonValues.GetToken();
            var data = JSON.stringify({
                "FromDate": this.state.fromDate,
                "ToDate": this.state.toDate
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Report_Url}/api/report/partnerreport?`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    if (response.data.listChartData.length > 0 && response.data.listData.length > 0) {
                        this.setState({
                            searchData: true,
                            btnloading: false,
                        })
                    }


                    let chartDataList = [];
                    if (response.data.listChartData.length > 1) {
                        response.data.listChartData.forEach(element => {
                            let dataPoints = [];

                            element.dataPoints.forEach(d => {
                                dataPoints.push({
                                    y: d.y,
                                    x: new Date(d.x)
                                });
                            });
                            chartDataList.push({
                                type: "stackedColumn",
                                showInLegend: "true",
                                legendText: element.legendText,
                                dataPoints: dataPoints
                            });
                        });
                    } else {
                        response.data.listChartData.forEach(element => {
                            let dataPoints = [];

                            element.dataPoints.forEach(d => {
                                dataPoints.push({
                                    y: d.y,
                                    x: new Date(d.x)
                                });
                            });
                            chartDataList.push({
                                color: "#4f81bc",
                                dataPoints: dataPoints
                            });
                        });
                    }
                    let Totalcost = 0;
                    response.data.listData.forEach(element => {
                        Totalcost = Totalcost + (element.costInCents);
                    });
                    Totalcost = ((Totalcost) / 100).toFixed(2);

                    let totalrequestmade = 0;
                    response.data.listData.forEach(element => {
                        totalrequestmade = totalrequestmade + parseInt(element.numberOfRequest);
                    });

                    response.data.listData.push({
                        "service": "Total",
                        "numberOfRequest": totalrequestmade,
                        "requestTime": "",
                        "company": "",
                        "costInDollar": '$'+ Totalcost
                    });
                    this.setState({
                        serviceConsumptionList: response.data.listData,
                        dataListForChart: chartDataList,
                        pageLoading: false,
                        totalRequest: totalrequestmade,
                        costInDoller: Totalcost
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false,
                    })
                });
        }
    }
    onExportClick = () => {
        if (this.isValid()) {
            this.setState({
                exportLoading: true
            })
            var axios = require('axios');
            var token = CommonValues.GetToken();
            var data = JSON.stringify({
                "FromDate": moment(this.state.fromDate).format('yyyy-MM-DD'),
                "ToDate": moment(this.state.toDate).format('yyyy-MM-DD')
            });

            var config = {
                method: 'post',
                responseType: 'blob',
                url: `${process.env.REACT_APP_Report_Url}/api/Report/partnerusageexport`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    this.setState({
                        exportLoading: false
                    })
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'parner_usage.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        exportLoading: false
                    })
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                    }
                });
        }
    }
    formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    balanceBodyTemplate = (rowData) => {
        if (rowData.costInDollar != null) {
            return this.formatCurrency(rowData.costInDollar);
        }

    }

    costInCentsForSellerBodyTemplate = (rowData) => {
        if (rowData.costInDollarForSeller != null) {
            return this.formatCurrency(rowData.costInDollarForSeller);
        }

    }

    igCommisionBodyTemplate = (rowData) => {
        if (rowData.igTotalCommisionInDollar != null) {
            return this.formatCurrency(rowData.igTotalCommisionInDollar);
        }

    }

    render() {
        const options = {
            animationEnabled: true,
            exportEnabled: false,
            dataPointWidth: 40,
            title: {
                text: ""
            },
            axisX: {
                title: "Dates",
                valueFormatString: "MM/DD/YYYY",
                interval: 1,
                intervalType: "day"
            },
            axisY: {
                title: "No. of Requests",
            },

            data: this.state.dataListForChart
        }

        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.Logout();
        }
     
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <PageHeader PageTitle=" All Partners Usage Report"></PageHeader>
                        <section className="content">
                            <div className="container-fluid">

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">

                                            <div className="card-header">
                                                <div className="row">

                                                    <div className="col-lg-1 col-md-3 col-sm-6 col-xs-6 "  style={{ textAlign:"center"}}>
                                                        <label  style={{fontWeight:"normal"}} >From Date</label>
                                                    </div>
                                                    <div className="col-lg-4 col-md-3 col-sm-6 col-xs-6 ">
                                                        <div
                                                            className="input-group input-group-sm"
                                                        >
                                                            <Calendar
                                                                className={this.state.inputTextFromDateErrorClass ? " p-invalid p-mr-2  w-100" : " w-100"}
                                                                value={this.state.fromDate}
                                                                onChange={this.onFromDateChage}
                                                                errorMessage={this.state.fromDateError}
                                                                placeholder="From Date"
                                                            />
                                                            <small id="username-help" className="p-error">{this.state.fromDateError}</small>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-3 col-sm-6 col-xs-6 "  style={{ textAlign:"center"}}>
                                                        <label style={{fontWeight:"normal"}}>To Date</label>
                                                    </div>
                                                    <div className="col-lg-4 col-md-3 col-sm-6 col-xs-6">
                                                        <div
                                                            className="input-group input-group-sm"
                                                        >
                                                            <Calendar
                                                                className={this.state.inputTextToDateErrorClass ? " p-invalid p-mr-2  w-100" : " w-100"}
                                                                value={this.state.toDate}
                                                                onChange={this.onToDateChage}
                                                                errorMessage={this.state.toDateError}
                                                                placeholder="To Date"
                                                            />
                                                            <small id="username-help" className="p-error">{this.state.toDateError}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 servicemain" style={{ textAlign:"center"}}>
                                                        {/* <Button label="Search" className='p-button-sm'  onClick={() => this.getAllCompanyList('')} /> */}
                                                        <Button label="Search" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onSearchClick}  />

                                                    </div>

                                                </div>

                                                <div className="card-tools">

                                                </div>
                                            </div>
                                            {
                                                this.state.totalRequest == 0 ?
                                                    <div className="pt-4 pb-4">
                                                        <div className="">
                                                            <h3 style={{ textAlign: "center" }}>No requests found</h3>
                                                        </div>
                                                    </div>
                                                    :
                                                    null}
                                            {
                                                this.state.exportLoading ?
                                                    <div style={{ textAlign: "center" }}>
                                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            this.state.searchData ? <><div className="col-md-12">
                                                                {
                                                                    this.state.totalRequest == 0 ?

                                                                        null
                                                                        :
                                                                        <>

                                                                            <h6 className=" mb-0 font-weight-bolder p-4" style={{ display: "inline-block" }}> <span className="text-12 text-muted">Total Requests Made:</span> {this.state.totalRequest}   </h6>
                                                                            <h6 className=" mb-0 font-weight-bolder p-4" style={{ display: "inline-block" }}> <span className="text-12 text-muted"> Cost:</span> ${this.state.costInDoller}   </h6>

                                                                            <CanvasJSChart options={options} className="ReportBar" /><hr />
                                                                        </>
                                                                }

                                                            </div>

                                                                {
                                                                    this.state.totalRequest == 0 ?

                                                                        null
                                                                        :

                                                                        <div className="col-md-12">
                                                                            <div className="">
                                                                                <div className="card-header d-flex align-items-center ">
                                                                                    <h6 className="font-weight-medium pb-1 pt-1">Summary</h6>
                                                                                    <Button label="Export" onClick={this.onExportClick} style={{ position: "absolute", right: "0" }} />
                                                                                </div>
                                                                                <div className="card-body" style={{ fontSize: "12px" }}>
                                                                                    <DataTable value={this.state.serviceConsumptionList}
                                                                                        responsiveLayout="scroll" className='demo'>
                                                                                        <Column field="buyerName" header="Buyer" ></Column>
                                                                                        <Column field="service" header="Service" ></Column>
                                                                                        <Column field="numberOfRequest" header="# of Request"   ></Column>
                                                                                        <Column field="requestTime" header="Request Date" ></Column>
                                                                                        <Column field="company" header="Service Provider" ></Column>
                                                                                        <Column field="costInDollar" header="Total Fee" body={this.balanceBodyTemplate} ></Column>
                                                                                        <Column field="costInDollarForSeller" header="Seller Cost" body={this.costInCentsForSellerBodyTemplate} ></Column>
                                                                                        <Column field="igTotalCommisionInDollar" header="IG Commision" body={this.igCommisionBodyTemplate} ></Column>

                                                                                    </DataTable>

                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                }
                                                            </>

                                                                : null
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                    <Footer></Footer>
                </div >
            </div >
        )
    }
}
export default PartnerReport;
