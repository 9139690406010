import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CommonValues from '../../Common/Util';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

class APIEndpoint extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            apiId: 0,
            apiEndpointList: "",
            addEndpointPanel: false,
            endpointId: 0,
            hideEditOrAddBtn: false,

        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            apiId: params.index,

        });
        this.getAllAPIEndpoint(params.index);
    }
    componentDidUpdate = (prevProps) => {

        if (prevProps.endpointRefresh != this.props.endpointRefresh) {
            this.getAllAPIEndpoint(this.state.apiId);
        }
        if (prevProps.hideEditOrAddBtn != this.props.hideEditOrAddBtn) {
            this.setState({
                hideEditOrAddBtn: this.props.hideEditOrAddBtn,
                endpointId: 0
            })
        }
    }

    getAllAPIEndpoint = (apiId) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIEndpoints/endpointbyapiid?APIid=${apiId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false
                });
                if (response.data != null && response.data != "") {
                    this.setState({
                        apiEndpointList: response.data
                    })

                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    actionBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                {this.state.hideEditOrAddBtn == false ?
                    <>
                        <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.showHideAddEndpoint(true, rowData.id)} />
                        &emsp;
                    </>
                    :
                    null
                }
                {/* <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.confirmDeleteService(rowData)} /> */}
            </React.Fragment>
        );
    }

    showHideAddEndpoint = (show, id) => {

        this.setState({
            endpointId: id,
            hideEditOrAddBtn: show,

        })
        this.props.showHideAddEndpoint(show, id);
    }
    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <div className="card-body table-responsive p-0">
                            <div className="d-none d-md-block">
                                <DataTable value={this.state.apiEndpointList} paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={7} responsiveLayout="scroll">

                                    <Column field="name" header="Name" sortable></Column>
                                    <Column field="apiEndPointCode" header="Endpoint Code" sortable></Column>
                                    <Column field="httpMethod" header="Method" sortable></Column>
                                    <Column field="bodyType" header="Body Type" sortable></Column>
                                    <Column field="bodySubType" header="Body Sub Type" sortable></Column>

                                    <Column body={this.actionBodyTemplate} header="Action"></Column>
                                </DataTable>
                            </div>
                        </div>

                }
                <br />
                <div className='row'>
                    <div className='col-12'>
                        {
                            this.state.endpointId == 0 && this.state.hideEditOrAddBtn == false ?
                                <Button label="Add Endpoint" loading={this.state.btnloadingsave} onClick={() => this.showHideAddEndpoint(true, 0)} style={{ float: "right" }} />
                                :
                                null
                        }

                    </div>

                </div>
            </>
        )
    }
}
export default withRouter(APIEndpoint)
