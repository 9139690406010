import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Editor } from 'primereact/editor';
import { withRouter } from 'react-router';
import { FileUpload } from 'primereact/fileupload';

var axios = require('axios');

class BlogDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      blogId: 0,
      errorMessage: "",
      successMessage: "",

      blogName: "",
      blogShortDescription: "",
      blogDetailedDescription: "",
      publishDate: new Date(),
      thumbnailImage: "",
      backgroundImage: "",

      errorMessageBlogName: "",
      errorMessageBlogDescription: "",
      errorMessageBlogDetailedDescription: "",
      errorMessagePublishDate: "",
      errorMessagethumbnailImage: "",
      errorMessagebackgroundImage: ""
    }
  }
  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.setState({
      blogId: params.index,
    });

    if (params.index != 0) {
      this.getBlogDetails(params.index)
    }
  }
  getBlogDetails = (id) => {
    this.setState({
      loading: true
    });


    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_Community_Url}/api/blogs/blog/${id}`,

    };
    axios(config)
      .then((response) => {

        this.setState({
          blog: response.data,
          blogName: response.data.title,
          blogShortDescription: response.data.shortDescription,
          blogDetailedDescription: response.data.description,
          thumbnailImage: response.data.thumbnailImage,
          backgroundImage: response.data.backGroundImage,
          publishDate: new Date(response.data.publishDate),
          loading: false
        });

      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false
        });
      });
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  isValid = () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      errorMessageBlogName: "",
      errorMessageBlogDescription: "",
      errorMessageBlogDetailedDescription: "",
      errorMessagePublishDate: "",
      errorMessagethumbnailImage: "",
      errorMessagebackgroundImage: ""
    })
    let valid = true;
    if (this.state.blogName == "" || this.state.blogName==null) {
      this.setState({
        errorMessageBlogName: "Please enter blog name."
      });
      valid = false;
    }
    if (this.state.blogShortDescription == "" || this.state.blogShortDescription == null) {
      this.setState({
        errorMessageBlogDescription: "Please enter blog short description."
      });
      valid = false;
    }
    
    if (this.state.blogDetailedDescription == "" || this.state.blogDetailedDescription == null) {
      this.setState({
        errorMessageBlogDetailedDescription: "Please enter blog detailed description."
      });
      valid = false;
    }
    if (this.state.publishDate == "" || this.state.publishDate == null) {
      this.setState({
        errorMessagePublishDate: "Please select publish date."
      });
      valid = false;
    }
    if (this.state.thumbnailImage == "") {
      this.setState({
        errorMessagethumbnailImage: "Please upload thumbnail image."
      });
      valid = false;
    }
    if (this.state.backgroundImage == "") {
      this.setState({
        errorMessagebackgroundImage: "Please upload background image."
      });
      valid = false;
    }
    return valid;
  }
  onAddBlogClicked = () => {
    
    if (this.isValid()) {
      this.setState({
        loading: true
      });
      var token = CommonValues.GetToken();
      var axios = require('axios');
      var data = JSON.stringify({
        "Title": this.state.blogName,
        "ShortDescription": this.state.blogShortDescription,
        "Description": this.state.blogDetailedDescription,
        "ThumbnailImage": this.state.thumbnailImage,
        "BackGroundImage": this.state.backgroundImage,
        "PublishDate": moment(this.state.publishDate).format('yyyy-MM-DD'),
        "Id": parseInt(this.state.blogId)
      });
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_Community_Url}/api/blogs/addblog`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: data

      };

      axios(config)
        .then((response) => {

          this.setState({
            blogId: response.data,
            loading: false
          });
          this.props.history.push(`/blog/${response.data}`);
          this.getBlogDetails(response.data)

        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false
          });
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout();
            }
            else if (error.response.status == 409) {
              this.setState({
                errorMessage: "Blog with same name already exist."
              })
            }
            else {
              this.setState({
                errorMessage: "Opp's something went wrong."
              })
              setTimeout(
                () => this.setState({ errorMessage: "" }),
                3000
              );
            }
          }
        });
    }
  }
  onUploadThumbnail = (e) => {

    this.setState({
      thumbnailLoading: true,
      selectedThumbnail: e.target.files[0].name
    });
    var axios = require('axios');
    var token = CommonValues.GetToken();
    var FormData = require('form-data');
    var fs = require('fs');
    var data = new FormData();
    data.append('sourcefile', e.target.files[0]);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_Community_Url}/api/blogs/uploadimages`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    axios(config)
      .then((response) => {
        this.setState({
          thumbnailImage: response.data,
          thumbnailLoading: false
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          thumbnailLoading: false
        });
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          }
        }
        else {
          this.setState({
            errorMessage: "Opp`s something went wrong"
          });
          setTimeout(
            () => this.setState({ errorMessage: "" }),
            3000
          );
        }

      });
  }
  onUploadBackground = (e) => {

    this.setState({
      bgimgLoading: true,
      selectedBackground: e.target.files[0].name
    });
    var axios = require('axios');
    var token = CommonValues.GetToken();
    var FormData = require('form-data');
    var fs = require('fs');
    var data = new FormData();
    data.append('sourcefile', e.target.files[0]);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_Community_Url}/api/blogs/uploadimages`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: data
    };

    axios(config)
      .then((response) => {
        this.setState({
          backgroundImage: response.data,
          bgimgLoading: false
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          bgimgLoading: false
        });
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout();
          }
        }
        else {
          this.setState({
            errorMessage: "Opp`s something went wrong"
          });
          setTimeout(
            () => this.setState({ errorMessage: "" }),
            3000
          );
        }

      });
  }
  onDateSelected = (e) => {
    this.setState({
      publishDate: e.value,
    })
  }
  render() {
    return (
      <div>
        {
          this.state.loading ?
            <div style={{ textAlign: "center" }}>
              <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
            :
            <>
              <div >
                <label className="form-label">
                  Blog Name
                </label><span style={{ color: "red" }}>*</span>
                <input type="text"
                  id="blogName"
                  name="blogName"
                  value={this.state.blogName}
                  className={this.state.errorMessageBlogName.length > 0 ? "form-control p-invalid p-mr-2" : "form-control"}
                  onChange={this.onChange}
                />
                <small id="username-help" className="p-error">{this.state.errorMessageBlogName}</small>
              </div><br />
              <div >
                <label className="form-label">
                  Short Description
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Editor style={{ height: '100px' }}
                  value={this.state.blogShortDescription}
                  onTextChange={(e) => this.setState({ blogShortDescription: e.htmlValue })}
                  className={this.state.errorMessageBlogDescription.length > 0 ? " p-invalid p-mr-2" : null}
                />
                <small id="username-help" className="p-error">{this.state.errorMessageBlogDescription}</small>

              </div><br />
              <div >
                <label className="form-label">
                  Detailed Description
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Editor style={{ height: '320px' }}
                  value={this.state.blogDetailedDescription}
                  onTextChange={(e) => this.setState({ blogDetailedDescription: e.htmlValue })}
                  className={this.state.errorMessageBlogDetailedDescription.length > 0 ? " p-invalid p-mr-2" : null}
                />
                <small id="username-help" className="p-error">{this.state.errorMessageBlogDetailedDescription}</small>

              </div><br />


              <div className="">
                <label className="form-label">
                  Publish Date
                  <span style={{ color: "red" }}>*</span>
                </label><br />
                <Calendar
                  className={this.state.errorMessagePublishDate.length > 0 ? " p-invalid p-mr-2 w-100" : "w-100"}
                  // name="eventEndDate"
                  value={this.state.publishDate}
                  onChange={this.onDateSelected}
                  viewDate={this.state.publishDate}
                  minDate={new Date()}
                  placeholder="Enter publish date"
                />
                <small id="username-help" className="p-error">{this.state.errorMessagePublishDate}</small>
              </div><br />

              <div className="row">
                <div className='col-6'>
                  <label className="form-label">
                    Thumbnail Image
                    <span style={{ color: "red" }}>*</span>
                  </label><br />
                  {
                    this.state.thumbnailLoading ?
                      <div style={{ textAlign: "center" }}>
                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                      </div>
                      :
                      <img src={this.state.thumbnailImage} width="150" height="150" alt="Thumbnail image not available" />
                  }
                </div>
                <div className='col-6'>
                  <br />

                  <label htmlFor="file" className="input input-file">
                    <div className="button">
                      <input type="file" className="form-control form-control-sm" onChange={this.onUploadThumbnail} name="choosedThumbnail"  accept="image/*"/> Browse

                    </div>

                    <input type="text"
                      className={this.state.errorMessagethumbnailImage.length > 0 ? "form-control form-control-sm p-invalid p-mr-2" : "form-control form-control-sm"}
                      placeholder="No Files Selected" readOnly value={this.state.selectedThumbnail} />
                    <small id="username-help" className="p-error">{this.state.errorMessagethumbnailImage}</small>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className='col-6'>
                  <div className="">
                    <label className="form-label">
                      Background Image
                      <span style={{ color: "red" }}>*</span>
                    </label><br />
                    {
                      this.state.bgimgLoading ?
                        <div style={{ textAlign: "center" }}>
                          <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <img src={this.state.backgroundImage} width="500" height="200" alt="Background image not available" />
                    }
                  </div>
                </div>
                <div className='col-6'>
                  <br /><br />
                  <label htmlFor="file" className="input input-file">
                    <div className="button">
                      <input type="file" className="form-control form-control-sm" onChange={this.onUploadBackground} name="choosedThumbnail" accept="image/*" /> Browse

                    </div>

                    <input type="text"
                      className={this.state.errorMessagebackgroundImage.length > 0 ? "form-control form-control-sm p-invalid p-mr-2" : "form-control form-control-sm"}
                      placeholder="No Files Selected"
                      readOnly
                      value={this.state.selectedBackground} />
                    <small id="username-help" className="p-error">{this.state.errorMessagebackgroundImage}</small>
                  </label>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-10">
                  {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                    : null}
                  {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                    : null}
                </div>

                <div className="col-2">

                  {
                    this.state.blogId == 0 ?
                      <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onAddBlogClicked} style={{ float: "right" }} />
                      :
                      <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={this.onAddBlogClicked} style={{ float: "right" }} />
                  }

                </div>
              </div>

            </>
        }

      </div>
    )
  }
}
export default withRouter(BlogDetails);
