import React, { Component } from 'react';

class PageHeader extends Component {
   
    render() {
        return (
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>{this.props.PageTitle}</h1>
                    </div>
                </div>
            </div>                         
        </section>
        )
    }
}
export default PageHeader;
