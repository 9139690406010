import React, { Component } from 'react';
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import PageHeader from '../../Common/PageHeader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonValues from '../../Common/Util';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { ConnectedOverlayScrollHandler } from 'primereact/utils';

export default class DatafabricMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: true,
            innerWidth: 1024,
            loading: false,
            apiList: [],
            choosedJSON: "",
            showUploadPopup: false,
            uploadPercentage: 0,
            searchText:""
        }
    }
    componentDidMount = () => {
        // window.addEventListener("resize", this.updateWindowSize);
        // this.updateWindowSize();
        // Custom Console log example


        this.getAllAPI(0, 'getall',this.state.searchText);
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
        this.checkAndApplySideBar(window.innerWidth);
    }
    onBodyClick = () => {

        if (this.state.innerWidth < 1024) {
            this.setState({
                sidebarShow: !this.state.sidebarShow
            });
        }
    }
    onBarsClick = () => {
        this.setState({
            sidebarShow: !this.state.sidebarShow
        });
    }
    getAllAPI = (id, flag, searchText) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/APIMasters?id=${id}&flag=${flag}&searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {

                this.setState({
                    apiList: response.data,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    editApiDetails = (id) => {
        this.props.history.push(`/datafabric/${id}`)
    }
    onAddNewAPIClicked = () => {
        this.props.history.push(`/datafabric/0`)
    }
    onUploadAPIButtonClicked = () => {

        this.setState({
            showUploadPopup: !this.state.showUploadPopup
        })
    }

    onUploadAPISpecClicked = (e) => {
        let val = this.state.uploadPercentage;
        this.interval = setInterval(() => {
            val += Math.floor(Math.random() * 10) + 1;
            if (val >= 100) {
                val = 100;
                clearInterval(this.interval);
            }
            this.setState({
                uploadPercentage: val
            });
        }, 1000);
        this.setState({
            uploadLoading: true
        });
        var axios = require('axios');
        var FormData = require('form-data');
        var fs = require('fs');
        var data = new FormData();
        data.append('sourcefile', e.files[0]);

        // const options = {
        //     onUploadProgress: progressEvent => {
        //         let percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        //         this.setState({
        //             uploadPercentage: percent
        //         })
        //     }
        // }


        axios.post(`${process.env.REACT_APP_DataFabric_Url}/api/APIMasters/uploadjson`, data)
            .then(response => {
                this.setState({
                    uploadLoading: false,
                    choosedJSON: "",
                    uploadPercentage: 100
                });
                this.props.history.push(`/datafabric/${response.data}`)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    uploadLoading: false,
                    choosedJSON: ""
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button p-mr-2 editbtn" onClick={() => this.editApiDetails(rowData.id)} />
                &emsp;
                {/* <Button icon="pi pi-trash" className="p-button p-button-danger editbtn" onClick={() => this.confirmDeleteService(rowData)} /> */}
            </React.Fragment>
        );
    }
    descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p dangerouslySetInnerHTML={{ __html: rowData.description }} />
            </React.Fragment>
        );
    }
    onSearchClick = () => {
        this.getAllAPI(0, 'getall', this.state.searchText);
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onKeyClick = (e) => {
        if (e.keyCode == 13) {
            this.getAllAPI(0, 'getall',this.state.searchText);
        }
    }

    render() {
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>
                    <div className="content-wrapper">
                        <PageHeader PageTitle="Data Fabric Master"></PageHeader>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                                                        <div
                                                            className="input-group input-group-sm"
                                                        >
                                                            <input
                                                                type="text"
                                                                name="searchText"
                                                                className="form-control float-right"
                                                                placeholder="Search"
                                                                value={this.state.searchText}
                                                                onChange={this.onChange}
                                                                onKeyDown={this.onKeyClick}
                                                            />
                                                            <div className="input-group-append">
                                                                <button type="submit" className="btn btn-default" onClick={this.onSearchClick}>
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 servicemain">
                                                        <Button label="Refresh" className='p-button-sm' icon="pi pi-sync" onClick={() => this.getAllAPI(0, 'getall', '')} />
                                                        &nbsp;
                                                        <Button label="New API" className='p-button-sm' icon="pi pi-plus" onClick={this.onAddNewAPIClicked} />
                                                        &nbsp;
                                                        <Button label="Upload API" className='p-button-sm' icon="pi pi-upload" onClick={this.onUploadAPIButtonClicked} />


                                                    </div>

                                                </div>

                                                <div className="card-tools">

                                                </div>
                                            </div>
                                            {
                                                this.state.loading ?
                                                    <>
                                                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                                        <div style={{ height: "600px" }}></div>
                                                    </>
                                                    :
                                                    <div className="card-body table-responsive p-0">
                                                        <div className="d-none d-md-block">
                                                            <DataTable value={this.state.apiList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">

                                                                <Column field="name" header="Name" sortable></Column>
                                                                <Column body={this.descriptionBodyTemplate} header="Description" sortable></Column>
                                                                <Column field="company" header="Company Name" sortable></Column>
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>

                                                        <div className="d-block d-md-none">
                                                            <DataTable value={this.state.apiList} paginator
                                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                                rows={7} responsiveLayout="scroll">

                                                                <Column field="name" header="NAME" sortable></Column>
                                                                <Column body={this.actionBodyTemplate} header="Action"></Column>
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Dialog header="Upload open API specification /  swagger JSON" visible={this.state.showUploadPopup} style={{ width: '50vw' }} onHide={this.onUploadAPIButtonClicked} draggable={false}>
                    <>
                        {
                            this.state.uploadLoading ?
                                <ProgressBar value={this.state.uploadPercentage} />
                                :
                                <FileUpload name="choosedJSON"
                                    uploadHandler={this.onUploadAPISpecClicked}
                                    customUpload={true}

                                    auto={true}
                                    emptyTemplate={
                                        <div className="flex align-items-center flex-column" style={{ textAlign: "center" }}>
                                            <i className="pi pi-image mt-3 p-5" style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                                            <br></br>
                                            <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop PDF file Here</span>
                                        </div>
                                    }
                                    chooseLabel="Browse"
                                />
                        }

                    </>
                </Dialog>
            </div>

        )
    }
}

