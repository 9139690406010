import React, { Component } from 'react';

class Footer extends Component {
   
    render() {
        return (
            <footer className="main-footer">
            <div className="float-right d-none d-sm-block">

            </div>
            <strong>
                Copyright © <a href="https://www.insurancegig.com">InsuranceGIG</a>.
            </strong>
            All rights reserved.
        </footer>
        )
    }
}
export default Footer;
