import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import CommonValues from '../../Common/Util';
import { ProgressSpinner } from 'primereact/progressspinner';

class Buyer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            buyerId: 0,
            workflowId: 0,
            errorMessage: "",
            successMessage: "",
            showPanel: false,
            partnerName: "",
            partnerType: "Buyer",
            status: "",
            costType: "",
            description: "",
            partnerName: {},
            errorMessagePartnerName:"",
            errorMessageStatus:""
        }

    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            workflowId: params.index,
        });
        this.getAllCompanyList('');
        this.getAllWorkflowStatus();
    }
    getWorkflowImplementationBuyer = () => {
        this.setState({
            loading: true
        });
        let implementationId=[];
        if(this.props.implementationId!=0)
        {
            implementationId.push(this.props.implementationId);
        }
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "WorkflowImplementationId":implementationId,
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/buyer`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {

                this.setState({
                  
                    loading: false
                });
                if (response.data.length > 0) {
                    this.setState({
                        buyerId: response.data[0].workflowImplementationBuyerId,
                    })
                    this.state.companyList.forEach(element => {
                        if (element.name == response.data[0].buyer) {
                            this.setState({
                                partnerName: element
                            })
                        }
                    });
                    this.state.statusList.forEach(element => {
                        if (response.data[0].status == element.name) {
                            this.setState({
                                status: element
                            })
                        }
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    getAllCompanyList = (searchText) => {

        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=${searchText}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    companyList: response.data,
                    loading: false
                });
                this.getWorkflowImplementationBuyer();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getAllWorkflowStatus = () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/workflowstatus`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    statusList: response.data,

                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({

                })
                if (error.response.status == 401) {
                    CommonValues.Logout();
                }
                else {
                    this.setState({
                        errorMessage: "Opp's something went wrong."
                    })
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onPartnerChange = (e) => {
        this.setState({
            partnerName: e.value
        })
    }
    onStatusChange = (e) => {
        this.setState({
            status: e.value
        })
    }
    isValid = () => {
        let valid = true;
        this.setState({
            inputTextErrorClass: false,
            errorMessagePartnerName: "",
            errorMessageStatus: "",
            successMessage:"",
            errorMessage:""

        })
        if (this.state.partnerName.companyId == undefined) {
            this.setState({
                inputTextErrorClass: true,
                errorMessagePartnerName: "Please select buyer",
            });
            valid = false;
        }
        // if (this.state.status.id == undefined) {
        //     this.setState({
        //         inputTextErrorClass: true,
        //         errorMessageStatus: "Please select status",
        //     });
        //     valid = false;
        // }
        return valid;
    }
    onSaveBuyerClicked = () => {
        if (this.isValid()) {
            this.setState({
                btnloadingsave: true
            });
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "WorkflowImplementation": this.props.implementationId.toString(),
                "Buyer": this.state.partnerName.companyId.toString(),
                //"Status": this.state.status.id.toString(),
                "Description": this.state.description
            });
           
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/addbuyer`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({
                        btnloadingsave: false,
                        successMessage: "Buyer saved successfully...!!!",

                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.getWorkflowImplementationBuyer();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    });

                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else if (error.response.status == 409) {
                            this.setState({
                                errorMessage: "Buyer with the same name already exists."
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                        else {
                            this.setState({
                                errorMessage: "Opp's something went wrong."
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );
                        }
                    }
                });
        }
    }
    onUpdateBuyerClicked = () => {
        this.setState({
            btnloadingsave: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var data = JSON.stringify({
            "WorkflowImplementation": this.props.implementationId.toString(),
            "Buyer": this.state.partnerName.companyId.toString(),
            //"Status": this.state.status.id.toString(),
            "WorkflowImplementationBuyerId": this.state.buyerId
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Workflow_Url}/api/workflow/updatebuyer`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    btnloadingsave: false,
                    successMessage: "Buyer updated successfully...!!!",
                });
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
                this.getWorkflowImplementationBuyer();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    btnloadingsave: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else if (error.response.status == 409) {
                        this.setState({
                            errorMessage: "Buyer with the same name already exists."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });
    }
    render() {
        return (

            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>

                            <div className='row'>
                                <div className='col-2'>
                                    <label>Partner Name</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <Dropdown value={this.state.partnerName}
                                        options={this.state.companyList}
                                        onChange={this.onPartnerChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        filter
                                        className={this.state.errorMessagePartnerName.length>0 ? "p-invalid p-mr-2" : ""}
                                    />
                                    <small id="username-help" className="p-error">{this.state.errorMessagePartnerName}</small>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-2'>
                                    <label>Partner Type</label>
                                </div>
                                <div className='col-10'>
                                    <RadioButton
                                        inputId="Buyer"
                                        name="partnerType"
                                        value="Buyer"
                                        onChange={(e) => this.setState({ partnerType: e.value })}
                                        checked={this.state.partnerType === 'Buyer'}
                                        disabled
                                    />
                                    <label
                                        htmlFor="Buyer"
                                        style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}
                                    >Consumer</label>
                                    <RadioButton
                                        inputId="Seller"
                                        name="partnerType"
                                        value="Seller"
                                        onChange={(e) => this.setState({ partnerType: e.value })}
                                        checked={this.state.partnerType === 'Seller'}
                                        disabled
                                    />
                                    <label htmlFor="Seller" style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "20px" }}>Seller</label>
                                </div>
                            </div>

                            {/* <div className='row'>
                                <div className='col-2'>
                                    <label>Status</label>
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className='col-10'>
                                    <Dropdown value={this.state.status}
                                        options={this.state.statusList}
                                        onChange={this.onStatusChange}
                                        optionLabel="name"
                                        placeholder="Select"
                                        style={{ width: "100%" }}
                                        className={this.state.errorMessageStatus.length>0 ? "p-invalid p-mr-2" : ""} />
                                    <small id="username-help" className="p-error">{this.state.errorMessageStatus}</small>
                                </div>
                            </div><br /> */}


                            {/* <div className='row'>
                    <div className='col-2'>
                        <label>Description</label>
                    </div>
                    <div className='col-10'>
                        <textarea
                            type="text"
                            name="description"
                            className="form-control"
                            value={this.state.description}
                            onChange={this.onChange}
                        />
                    </div>
                </div><br /> */}
                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.buyerId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onSaveBuyerClicked} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onUpdateBuyerClicked} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>
                        </>
                }
            </>
        )
    }
}
export default withRouter(Buyer)
