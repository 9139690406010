import React, { Component } from 'react'
import Sidebar from '../../Common/Sidebar';
import TopNavBar from '../../Common/TopNavBar';
import { Button } from 'primereact/button';
import Footer from '../../Common/Footer';
import CommonValues from '../../Common/Util';
import PageHeader from '../../Common/PageHeader';
import { ProgressBar } from 'primereact/progressbar'
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import WorkflowDetails from './WorkflowDetails';
import WorkflowImplementation from './WorkflowImplementation';


export default class NewWorkflow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarShow: true,
            workflowId: 0


        }

    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            workflowId: params.index,
        });

    }

    workflowDetailsTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Workflow Details
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    workflowImplementationTemplate(options) {
        const toggleIcon = options.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className} style={{ padding: "10px" }}>

                <h4 className={titleClassName} style={{ display: "inline-block" }}>
                    Workflow Implementation
                </h4>
                <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ float: "right", borderRadius: "50%" }}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }
    render() {
        return (
            <div onClick={this.onBodyClick}>
                <div className={this.state.sidebarShow ? "sidebar-mini sidebar-open" : "sidebar-mini sidebar-closed sidebar-collapse"}>
                    <TopNavBar BarsClick={this.onBarsClick}></TopNavBar>
                    <Sidebar></Sidebar>

                    {/* <PageHeader PageTitle={this.state.workflowId==0?"Add Workflow" : "Update Workflow"}></PageHeader> */}

                    <div className="content-wrapper">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        {this.state.workflowId==0?<h1>Add New Workflow</h1>:<h1>Edit Workflow</h1>}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"></h3>
                                                <div className="card-tools">
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">

                                                    <Panel headerTemplate={this.workflowDetailsTemplate} toggleable>
                                                        <WorkflowDetails workflowId={this.state.workflowId}></WorkflowDetails>
                                                    </Panel><br />
                                                    {
                                                        this.state.workflowId == 0 ?
                                                            null
                                                            :
                                                            <Panel headerTemplate={this.workflowImplementationTemplate} toggleable collapsed>
                                                                <WorkflowImplementation workflowId={this.state.workflowId}></WorkflowImplementation>
                                                            </Panel>
                                                    }<br />
                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div >
                <Footer></Footer>
            </div >

        )
    }
}
