import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { withRouter } from 'react-router';
import { InputSwitch } from 'primereact/inputswitch';
import { Editor } from 'primereact/editor';

class EventDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            eventId: 0,
            eventName: "",
            eventDate: new Date(),
            eventEndDate: new Date(),
            name: "",
            eventDescription: "",
            phoneNumber: "",
            emailId: "",
            location: "",
            link: "",
            errorMessage: "",
            successMessage: "",
            eventDetailData: [],
            checkedStatus: false,
            userInfoEditable: false,
        }
    }

    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;
        this.setState({
            eventId: params.index,
        });

        if (params.index != 0) {
            this.getEventDetails(params.index)
        }else{
            this.getUserDetailsById();
        }
    }


    getEventDetails = (id) => {
        this.setState({
            eventId: id,
        });
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Community_Url}/api/events/eventdetails/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        this.setState({
            loading: true
        })
        axios(config)
            .then((response) => {

                this.setState({
                    eventDetailData: response.data,
                    loading: false
                });
                this.setState({
                    eventName: response.data.title,
                    eventDate: new Date(response.data.blogDateTime),
                    eventDescription: response.data.description,
                    name: response.data.name,
                    phoneNumber: response.data.phoneNo,
                    emailId: response.data.email,
                    link: response.data.link,
                    location: response.data.location,
                    eventEndDate: new Date(response.data.endDate),
                    

                })
                if (response.data.status == "True") {
                    this.setState({
                        checkedStatus: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    }
    getUserDetailsById = () => {
        this.setState({
            loading: true
        })
        var token = CommonValues.GetToken();

        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/userdetails`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    name: response.data.firstName,
                    emailId: response.data.workEmail,
                    phoneNumber: response.data.phoneNumber,
                    loading: false
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log(error)
                if (error.response != null) {
                    if (error.response.status == 401) {

                    }
                    else {
                        this.setState({
                            errorMessage: "Unknown error while getting the logged in user!!"
                        });
                    }
                } else {
                    this.setState({
                        errorMessage: "Unknown error while getting the logged in user!!"
                    })
                }
            });
    }
    checkValidation = () => {
        this.setState({
            inputTextEventNameErrorClass:false,
            inputTextEventDateErrorClass: false,
            inputTextEventEndDateErrorClass: false,
            inputTextEventDescriptionErrorClass: false,
            inputTextNameErrorClass: false,
            inputTextEmailErrorClass: false,
            inputTextLinkErrorClass: false,
            errorMessageEventName:"",
            errorMessageEventDate:"",
            errorMessageEventEndDate:"",
            errorMessageEventDescription:"",
            errorMessageName:"",
            errorMessageEmail:"",
            errorMessageLink:""
        })
        let valid = true;
        if (this.state.eventName == "" || this.state.eventName == null) {
            this.setState({
                inputTextEventNameErrorClass: true,
                errorMessageEventName: "Please enter event name"
            });           
            valid= false;
        }

        if (this.state.eventDate == "" || this.state.eventDate == null) {
            this.setState({
                inputTextEventDateErrorClass: true,
                errorMessageEventDate: "Please select event date"
            });           
            valid= false;
        }

        if (this.state.eventEndDate == "" || this.state.eventEndDate == null) {
            this.setState({
                inputTextEventEndDateErrorClass: true,
                errorMessageEventEndDate: "Please select event end date"
            });
            valid= false;
        }
        if (this.state.eventDate > this.state.eventEndDate) {
            this.setState({
                inputTextEventEndDateErrorClass: true,
                errorMessageEventEndDate: "End date must be greater or equal to start date"
            });
            valid= false;
        }
        if (this.state.eventDescription == "" || this.state.eventDescription == null) {
            this.setState({
                inputTextEventDescriptionErrorClass: true,
                errorMessageEventDescription: "Please enter event description"
            });
            valid= false;
        }

        if (this.state.eventId == 0) {
            if (this.state.name == "" || this.state.name == null) {
                this.setState({
                    inputTextNameErrorClass: true,
                    errorMessageName: "Please enter name"
                });
                valid= false;
            }

            if (this.state.emailId == "" || this.state.emailId == null) {
                this.setState({
                    inputTextEmailErrorClass: true,
                    errorMessageEmail: "Please enter email-id"
                });
                valid= false;
            }

        }
        if (this.state.link == "" || this.state.link == null) {
            this.setState({
                inputTextLinkErrorClass: true,
                errorMessageLink: "Please enter event link"
            });
          
            valid= false;
        }

        return valid;
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    dateSelected = (e) => {
        this.setState({
            eventDate: e.value,
        })
    }
    onViewDateChange = (e) => {
        this.setState({ eventDate: e.value })
    }
    onViewEndDateChange = (e) => {
        this.setState({ eventEndDate: e.value })
    }
    enddateSelected = (e) => {
        this.setState({
            eventEndDate: e.value,
        })
    }
    onEndViewDateChange = (e) => {
        this.setState({ eventEndDate: e.value })
    }
    onCreateClick = () => {
        if (this.checkValidation()) {
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Title": this.state.eventName,
                "Link": this.state.link,
                "Description": this.state.eventDescription,
                "BlogDateTime": moment(this.state.eventDate).format('yyyy-MM-DD'),
                "EndDate": moment(this.state.eventEndDate).format('yyyy-MM-DD'),
                "Status": (this.state.checkedStatus).toString(),
                "Name": this.state.name,
                "Email": this.state.emailId,
                "PhoneNo": this.state.phoneNumber,
                "Location": this.state.location
            });
            this.setState({
                btnloadingsave: true
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Community_Url}/api/events/eventinsertforadmin?`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then((response) => {


                    this.setState({
                        btnloadingsave: false,
                        eventId: response.data
                    })
                    this.getEventDetails(response.data);
                    this.props.history.push(`/event/${response.data}`)

                    this.setState({
                        successMessage: "Event created successfully..!!!",
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );

                    // this.clearAll();
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response != null) {
                        if (error.response.status == 401) {

                        }
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp`s something went wrong"
                        });
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    this.setState({
                        btnloadingsave: false
                    })
                });
        }
    }
    clearAll = () => {
        this.setState({
            eventName: "",
            eventDate: new Date(),
            eventEndDate: new Date(),
            link: "",
            location: "",
            eventDescription: "",
            checkedStatus: false
        })

    }
    onEventUpdateClick = (id) => {

        if (this.checkValidation()) {
            var token = CommonValues.GetToken();
            var axios = require('axios');
            var data = JSON.stringify({
                "Description": this.state.eventDescription,
                "Email": this.state.emailId,
                "Id": id,
                "Link": this.state.link,
                "Location": this.state.location,
                "Name": this.state.name,
                "PhoneNo": this.state.phoneNumber,
                "BlogDateTime": moment(this.state.eventDate).format('yyyy-MM-DD'),
                "EndDate": moment(this.state.eventEndDate).format('yyyy-MM-DD'),
                "Title": this.state.eventName,
                "Status": (this.state.checkedStatus).toString()
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Community_Url}/api/events/update`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            this.setState({
                btnloading: true
            })
            axios(config)
                .then((response) => {
                    this.setState({
                        btnloading: false,
                        successMessage: "Event updated successfully..!!",

                    })
                    this.getEventDetails(this.state.eventId)
                    
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false
                    })
                });
        }
    }

    render() {


        return (
            <div>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div >
                                <label className="form-label">
                                    Event Name
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="eventName"
                                    name="eventName"
                                    value={this.state.eventName}
                                    className={this.state.inputTextEventNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageEventName}</small>
                            </div><br />
                            <div >
                                <label className="form-label">
                                    Description
                                    <span style={{ color: "red" }}>*</span>
                                </label>

                                <Editor style={{ height: '320px' }}
                                    value={this.state.eventDescription}
                                    onTextChange={(e) => this.setState({ eventDescription: e.htmlValue })}
                                    className={this.state.inputTextEventDescriptionErrorClass ? " p-invalid p-mr-2" : null}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageEventDescription}</small>

                            </div><br />
                            <div className="">
                                <label className="form-label">
                                    Event Start Date
                                    <span style={{ color: "red" }}>*</span>
                                </label><br />
                                <Calendar
                                    className={this.state.inputTextEventDateErrorClass ? " p-invalid p-mr-2 w-100" : "w-100"}
                                    name="eventDate"
                                    value={this.state.eventDate}
                                    onChange={this.dateSelected}
                                    onViewDateChange={(e) => this.onViewDateChange(e)}
                                    viewDate={this.state.eventDate}
                                    errorMessage={this.state.errorMessageEventDate}
                                    placeholder="Enter your event date"
                                    minDate={moment(new Date()).toDate()}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageEventDate}</small>
                            </div><br />

                            <div className="">
                                <label className="form-label">
                                    Event End Date
                                    <span style={{ color: "red" }}>*</span>
                                </label><br />
                                <Calendar
                                    className={this.state.inputTextEventEndDateErrorClass ? " p-invalid p-mr-2 w-100" : "w-100"}
                                    name="eventEndDate"
                                    value={this.state.eventEndDate}
                                    onChange={this.enddateSelected}
                                    viewDate={this.state.eventEndDate}
                                    onViewEndDateChange={(e) => this.onViewEndDateChange(e)}
                                    placeholder="Enter your event end date"
                                    minDate={moment(new Date()).toDate()}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageEventEndDate}</small>

                                {/* <input
                                    className={this.state.inputTextEventEndDateErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    type="text"
                                    id="eventEndDate"
                                    name="eventEndDate"
                                    value={moment(this.state.eventEndDate).format('YYYY-MM-DD')}
                                    placeholder="Enter your event end date"
                                ></input>
                                <small id="username-help" className="p-error">{this.state.errorMessageEventEndDate}</small> */}
                            </div><br />

                            <div >
                                <label className="form-label">
                                    Name
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    className={this.state.inputTextNameErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    type="text"
                                    id="name"
                                    name="name"
                                    onChange={this.onChange}
                                    value={this.state.name}
                                    autoComplete="off"
                                    placeholder="Enter your name"
                                    disabled={this.state.userInfoEditable}
                                    readOnly="true"
                                ></input>

                                <small id="username-help" className="p-error">{this.state.errorMessageName}</small>

                            </div>
                            <br />
                            <div >
                                <label className="form-label">
                                    Phone Number
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    onChange={this.onChange}
                                    value={this.state.phoneNumber}
                                    autoComplete="off"
                                    placeholder="Enter your phone"
                                    disabled={this.state.userInfoEditable}
                                    readOnly="true"
                                ></input>

                            </div>
                            <br />
                            <div >
                                <label className="form-label">
                                    Email
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    className={this.state.inputTextEmailErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    type="email"
                                    id="emailId"
                                    name="emailId"
                                    onChange={this.onChange}
                                    value={this.state.emailId}
                                    autoComplete="off"
                                    placeholder="Enter your email"
                                    disabled={this.state.userInfoEditable}
                                    readOnly="true"
                                ></input>
                                <small id="username-help" className="p-error">{this.state.errorMessageEmail}</small>
                            </div>
                            <br />
                            <div >
                                <label className="form-label">
                                    Link
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    className={this.state.inputTextLinkErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    type="text"
                                    id="link"
                                    name="link"
                                    onChange={this.onChange}
                                    value={this.state.link}
                                    autoComplete="off"
                                    placeholder="Enter event link"
                                ></input>

                                <small id="username-help" className="p-error">{this.state.errorMessageLink}</small>

                            </div>
                            <br />
                            <div >
                                <label className="form-label">
                                    Location
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="location"
                                    name="location"
                                    onChange={this.onChange}
                                    value={this.state.location}
                                    autoComplete="off"
                                    placeholder="Enter event location"
                                ></input>

                            </div>
                            <br />
                            <div className="row additional" >
                                <div className="col-1">
                                    <label className="form-label">
                                        Approve
                                    </label>
                                </div>
                                <div className="col-1">
                                    <InputSwitch checked={this.state.checkedStatus} onChange={(e) => this.setState({ checkedStatus: e.value })} />
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.eventId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onCreateClick} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={() => this.onEventUpdateClick(this.state.eventId)} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>

                        </>
                }

            </div>
        );
    }

}
export default withRouter(EventDetails);
