import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import CommonValues from '../../Common/Util';
import moment from 'moment'

class AdditionalDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            serviceId: 0,
            errorMessage: "",
            successMessage: "",
            showTryout: false,
            showOnMarketplace: false,
            showPriceDetails: false,
            liveStatus: "",
            launchDate: "",
            fevNote: "",
            apigatewayId: "",
            draftStatus: "",
            launchDateValue: "",
            priority: "",
            serviceFeatureList: [],
            serviceFeatureIds:[],
            serviceFeature: "",

        }
        this.liveStatusList = [
            { name: 'Live', id: '1' },
            { name: 'Coming Soon', id: '2' },
            { name: 'Inactive', id: '4' }
        ];
        this.draftStatusList = [
            { name: 'Draft', id: '0' },
            { name: 'Approval Sent', id: '1' },
            { name: 'Approved ', id: '2' },
        ];
    }
    componentDidMount = () => {
        this.setState({
            serviceId: this.props.serviceId,
        });
        this.getServiceFeature();


    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onLiveStatusChange = (e) => {
        this.setState({
            liveStatus: e.value
        })
    }
    onDraftStatusChange = (e) => {
        this.setState({
            draftStatus: e.value
        })
    }
    onLaunchDateChange = (e) => {
        const lDate = moment(e.value).format('MM-DD-yyyy');
        this.setState({
            launchDate: lDate,
            launchDateValue: e.value
        });

    }
    // onServiceFeatureChange = (e) => {
    //     this.setState({
    //         serviceFeature: e.value
    //     })
    // }
    onServiceFeatureChange = (e) => {
        this.setState({ serviceFeature: e.value });      
        this.setState({
            serviceFeatureId:e.value.map(element => element.id )
        });
    }
   
   
    getServiceFeature = () => {
        this.setState({
            loading: true
        });
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/featurelist`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {

                this.setState({
                    serviceFeatureList: response.data,
                    loading: false,

                });
             
                if (this.props.serviceId != 0) {
                    this.getAdditionalDetails(this.props.serviceId)
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    }
    getAdditionalDetails = (id) => {
      
        this.setState({
            loading: true
        });
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getadditionaldetailsbyserviceid?serviceId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                response.data.forEach(element => {
                    this.liveStatusList.forEach(listItem => {
                        if (listItem.name == element.liveStatus) {
                            this.setState({
                                liveStatus: listItem

                            });
                        }
                    });
                });
                response.data.forEach(element => {
                    this.draftStatusList.forEach(listItem => {
                        if (listItem.id == element.draftStatus) {
                            this.setState({
                                draftStatus: listItem

                            });
                        }
                    });
                });
                

                const SelectedFeature = [];
                    this.state.serviceFeatureList.forEach(companyTypeId => {                      
                        response.data[0].serviceFeatureIds.forEach(item => {
                            if (item== companyTypeId.id) {
                                SelectedFeature.push(companyTypeId)
                            }
                        });
                    });
              
                this.setState({
                    serviceFeature: SelectedFeature,
                })
                this.setState({
                    loading: false,

                    fevNote: response.data[0].favouriteNote,
                    apigatewayId: response.data[0].apiGatewayId,
                    showTryout: response.data[0].showTryOut,
                    showOnMarketplace: response.data[0].showOnMarketplace,
                    showPriceDetails: response.data[0].showPriceDetails,
                    priority: response.data[0].priority,
                   //serviceFeature: response.data[0].serviceFeatureIds

                });
                if (response.data[0].LaunchDate != null) {
                    this.setState({
                        launchDateValue: moment(response.data[0].launchDate)._d,
                    });

                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });

    }
    checkValidation = () => {
        if (this.state.liveStatus == "" || this.state.liveStatus == null) {
            this.setState({
                inputTextLivestatusErrorClass: true,
                errorMessagelivestatus: "Please select live status"
            });
            setTimeout(() => {
                this.setState({
                    inputTextLivestatusErrorClass: false,
                    errorMessagelivestatus: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.priority == "" || this.state.priority == null) {
            this.setState({
                inputTextPriorityErrorClass: true,
                errorMessagepriority: "Please enter priority"
            });
            setTimeout(() => {
                this.setState({
                    inputTextPriorityErrorClass: false,
                    errorMessagepriority: "",
                });
            }, 3000);

            return false;
        }
        return true
    }

    onAdditionalServiceInfoUpdateClick = (id) => {
        if (this.checkValidation()) {
          
            this.setState({
                serviceId: id
            });

            const serviceFeatureIds = [];
            if (this.state.serviceFeature != '') {
                this.state.serviceFeature.forEach(element => {
                    serviceFeatureIds.push(element.id)
                });
            }
            var axios = require('axios');
            var token = CommonValues.GetToken();
            var data = JSON.stringify({
                "apiGatewayId": this.state.apigatewayId,
                "draftStatus": this.state.draftStatus.id,
                "FavouriteNote": this.state.fevNote,
                "launchDate": this.state.launchDateValue,
                "livestatus": this.state.liveStatus.name,
                "showOnMarketplace": this.state.showOnMarketplace,
                "showPricedetails": this.state.showPriceDetails,
                "showTryout": this.state.showTryout,
                "Priority": (this.state.priority).toString(),
                "ServiceFeatureIds": serviceFeatureIds
            });
            this.setState({
                btnloading: true
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ServicesService_Url}/api/services/updateAdditionalInfo?serviceId=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {

                    this.setState({
                        btnloading: false,
                        successMessage: "Additional Details Updated Successfully"
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                    this.getAdditionalDetails(this.props.serviceId)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false
                    });

                });
        }
    }
    render() {
    
        return (
            <div>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>
                            <div >
                                <label className="form-label">
                                    Live Status
                                </label><span style={{ color: "red" }}>*</span>
                                <Dropdown
                                    className={this.state.inputTextLivestatusErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.liveStatus}
                                    options={this.liveStatusList}
                                    onChange={this.onLiveStatusChange}
                                    optionLabel="name"
                                    placeholder="Select Live Status"
                                    style={{ width: "100%" }}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessagelivestatus}</small>
                            </div>
                            <div >
                                <label className="form-label">
                                    Feature
                                </label>

                                <MultiSelect value={this.state.serviceFeature}
                                    options={this.state.serviceFeatureList}
                                    onChange={this.onServiceFeatureChange}
                                    optionLabel="name"
                                    placeholder="Select feature"
                                    maxSelectedLabels={5}
                                    style={{ width: "100%" }} />
                                <small id="username-help" className="p-error">{this.state.errorMessageServiceFeature}</small>

                            </div>
                            <div >
                                <label className="form-label">
                                    Launch Date
                                    <span style={{ color: "red" }}></span>
                                </label>
                                <div className="form-group w-100 ">
                                    <Calendar
                                        id="basic"

                                        name="launchDateValue"
                                        value={this.state.launchDateValue}
                                        onChange={this.onLaunchDateChange}


                                    />
                                </div>
                                <small id="username-help" className="p-error">{this.state.errorMessageTagline}</small>

                            </div>
                            <div>
                                <label className="form-label">
                                    Favourite Note
                                    <span style={{ color: "red" }}></span>
                                </label>
                                <textarea type="text"
                                    id="fevNote"
                                    name="fevNote"
                                    value={this.state.fevNote}
                                    className={this.state.inputTextDescErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>

                            </div>
                            <div>
                                <label className="form-label">
                                    Apigateway Id
                                    <span style={{ color: "red" }}></span>
                                </label>
                                <input type="text"
                                    id="apigatewayId"
                                    name="apigatewayId"
                                    value={this.state.apigatewayId}
                                    className={this.state.inputTextDescErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>

                            </div>



                            <div className="row additional">
                                <div className="col-3">
                                    <label className="form-label">
                                        Show Tryout
                                        <span style={{ color: "red" }}></span>
                                    </label>
                                </div>
                                <div className="col-3">
                                    <InputSwitch checked={this.state.showTryout} onChange={(e) => this.setState({ showTryout: e.value })}></InputSwitch>
                                </div>
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>
                            </div>
                            <div className="row additional">
                                <div className="col-3">
                                    <label className="form-label">
                                        Show On Marketplace
                                        <span style={{ color: "red" }}></span>
                                    </label>
                                </div>
                                <div className="col-3">
                                    <InputSwitch checked={this.state.showOnMarketplace} onChange={(e) => this.setState({ showOnMarketplace: e.value })}></InputSwitch>
                                </div>
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>
                            </div>
                            <div className="row additional">
                                <div className="col-3">
                                    <label className="form-label">
                                        Show Price Details
                                        <span style={{ color: "red" }}></span>
                                    </label>
                                </div>
                                <div className="col-3">
                                    <InputSwitch checked={this.state.showPriceDetails} onChange={(e) => this.setState({ showPriceDetails: e.value })}></InputSwitch>
                                </div>
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>
                            </div>


                            <div>
                                <label className="form-label">
                                    Draft Status
                                    <span style={{ color: "red" }}></span>
                                </label>
                                <Dropdown
                                    className={this.state.inputTextCostTypeErrorClass ? " p-invalid p-mr-2" : ""}
                                    value={this.state.draftStatus}
                                    options={this.draftStatusList}
                                    onChange={this.onDraftStatusChange}
                                    optionLabel="name"
                                    placeholder="Select Draft Status"
                                    style={{ width: "100%" }}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>

                            </div>
                            <div>
                                <label className="form-label">
                                    Priority
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="text"
                                    id="priority"
                                    name="priority"
                                    value={this.state.priority}
                                    className={this.state.inputTextPriorityErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessagepriority}</small>

                            </div>
                            <br />

                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.serviceId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloading} onClick={() => this.onAdditionalServiceInfoUpdateClick(this.props.serviceId)} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={() => this.onAdditionalServiceInfoUpdateClick(this.props.serviceId)} style={{ float: "right" }} />
                                    }

                                </div>
                            </div>

                        </>
                }

            </div>
        );
    }
}
export default AdditionalDetails;
