class CommonValues{
    static SetValues=(token, userId, OrgName,UserName,RoleId,AvtarName)=>{
        localStorage.setItem('Token', token)
        localStorage.setItem('userId', userId)
        localStorage.setItem('OrgName', OrgName)
        localStorage.setItem('UserName', UserName)
        localStorage.setItem('RoleId', RoleId)
        localStorage.setItem('AvtarName', AvtarName)
    }
    static GetUserId=()=>{
        return localStorage.getItem('userId');
    }
    static GetToken=()=>{
        return  localStorage.getItem('Token');
    }
    static GetUserName=()=>{
        return localStorage.getItem('UserName');
    }
    static GetCompanyName=()=>{
        return localStorage.getItem('OrgName');
    }
    static GetRoleId=()=>{
        return localStorage.getItem('RoleId');
    }
    static GetAvtarName=()=>{
        return localStorage.getItem('AvtarName');
    }
    static ClearValues=()=>{
        localStorage.removeItem("Token");
        localStorage.removeItem("userId");
        localStorage.removeItem("OrgName");
        localStorage.removeItem("UserName");
        localStorage.removeItem("RoleId");
        localStorage.removeItem("AvtarName");
    }

    static Logout=()=>{
        this.ClearValues();
        window.location = "/";
    }
}
export default CommonValues;
