import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonValues from '../../Common/Util';
import { Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { AutoComplete } from 'primereact/autocomplete';


var axios = require('axios');
class Overview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            serviceId: 0,
            serviceName: "",
            tagline: "",
            description: "",
            errorMessage: "",
            successMessage: "",
            overviewList: [],
            companyListOption: [],
            companyListId: [],
            companyName: "",
            companyNameId: "",
            companyNameTest: "",
            filteredCompany: null,
        }
      
    }
    componentDidUpdate = (prevProps) => {

        if (prevProps.serviceId != this.props.serviceId) {
            this.getOverviewDetails(this.props.serviceId)
        }
    }
    componentDidMount = () => {
        this.getAllCompanyList('');
        this.setState({
            serviceId: this.props.serviceId,
        });

    }
   
    getAllCompanyList = () => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/company?searchText=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };


        axios(config)
            .then((response) => {
                this.setState({
                    companyListOption: response.data,

                });
                if (this.props.serviceId != 0) {
                    this.getOverviewDetails(this.props.serviceId)
                }
                else {
                    this.setState({
                        loading: false
                    });

                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });

                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        this.setState({
                            errorMessage: "Opp's something went wrong."
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                }
            });

    }
    getOverviewDetails = (id) => {
        this.setState({
            serviceId: id,

        });
        var token = CommonValues.GetToken();
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/getbyid?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {

                this.state.companyListOption.forEach(element => {
                    if (element.companyId == response.data[0].companyId)

                        this.setState({
                            companyName: element
                        });

                });
                this.setState({
                    overviewList: response.data,
                    serviceName: response.data[0].serviceName,
                    tagline: response.data[0].tagline,
                    description: response.data[0].description,
                    loading: false,

                });


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    checkValidation = () => {
        if (this.state.companyName == "" || this.state.companyName == null) {
            this.setState({
                inputTextCompanyNameErrorClass: true,
                errorMessageCompanyname: "Please select company name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextCompanyNameErrorClass: false,
                    errorMessageCompanyname: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.serviceName == "" || this.state.serviceName == null) {
            this.setState({
                inputTextServiceErrorClass: true,
                errorMessageServicename: "Please enter service name"
            });
            setTimeout(() => {
                this.setState({
                    inputTextServiceErrorClass: false,
                    errorMessageServicename: "",
                });
            }, 3000);

            return false;
        }
        if (this.state.tagline == "" || this.state.tagline == null) {
            this.setState({
                inputTextTagErrorClass: true,
                errorMessageTagline: "Please enter tagline"
            });
            setTimeout(() => {
                this.setState({
                    inputTextTagErrorClass: false,
                    errorMessageTagline: "",
                });
            }, 3000);

            return false;
        }

        if (this.state.description == "" || this.state.description == null) {
            this.setState({
                inputTextDescErrorClass: true,
                errorMessageDesc: "Please enter description"
            });
            setTimeout(() => {
                this.setState({
                    inputTextDescErrorClass: false,
                    errorMessageDesc: "",
                });
            }, 3000);

            return false;
        }

        return true
    }

    onOverviewSaveClick = () => {
        if (this.checkValidation()) {
            this.setState({
                successMessage: "",
                infoMessage: "",
                errorMessage: "",
                btnloadingsave: true
            });

            var token = CommonValues.GetToken();

            var data = JSON.stringify({
                "Description": this.state.description,
                "ServiceName": this.state.serviceName,
                "Tagline": this.state.tagline,
                "CompanyId": this.state.companyName.companyId


            });
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ServicesService_Url}/api/Services/insert`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)

                .then((response) => {
                    this.setState({
                        btnloadingsave: false,
                        serviceId: response.data
                    })

                    window.location = `/service/${response.data}`;
                    this.setState({
                        successMessage: "Service added successfully...!!!",
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                })


                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloadingsave: false
                    });

                    let errorMessage = '';
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else {
                            errorMessage = "Unknown error while saving the service";
                        }
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );
                    }
                    else {
                        errorMessage = "Unknown error while saving the service";
                    }
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                    this.setState({ errorMessage: errorMessage });
                });
        }
    }
    onCompanyNameChange = (e) => {
        this.setState({
            companyName: e.value,

        })
    }
    itemTemplate = (item) => {
        return (
            <div className="company-item">
                <div>{item.name}</div>
            </div>
        );
    }
    searchCountry = (event) => {
        setTimeout(() => {
            let filteredCompany;
            if (!event.query.trim().length) {
                filteredCompany = [...this.state.companyListOption];
            }
            else {
                filteredCompany = this.state.companyListOption.filter((company) => {
                    return company.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            this.setState({ filteredCompany });
        }, 250);
    }
    onOverviewUpdateClick = (id) => {
        if (this.checkValidation()) {
            this.setState({
                successMessage: "",
                infoMessage: "",
                errorMessage: "",
                btnloading: true
            });

            var axios = require('axios');
            var token = CommonValues.GetToken();

            var data = JSON.stringify({
                "Description": this.state.description,
                "ServiceName": this.state.serviceName,
                "Tagline": this.state.tagline,
                "CompanyId": this.state.companyName.companyId

            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_ServicesService_Url}/api/services/update?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.setState({
                        btnloading: false,
                        successMessage: "Service updated successfully."
                    });
                    setTimeout(
                        () => this.setState({ successMessage: "" }),
                        3000
                    );
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        btnloading: false
                    });

                    let errorMessage = '';
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                        else {
                            this.setState({
                                errorMessage: "Unknown error while updating the service"
                            })
                            setTimeout(
                                () => this.setState({ errorMessage: "" }),
                                3000
                            );

                        }
                    }
                    else {
                        this.setState({
                            errorMessage: "Unknown error while updating the service"
                        })
                        setTimeout(
                            () => this.setState({ errorMessage: "" }),
                            3000
                        );

                    }
                    this.setState({ errorMessage: errorMessage });
                });

        }

    }
    render() {


        return (
            <div>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :
                        <>


                            <div >
                                <label className="form-label">
                                    Company Name
                                </label><span style={{ color: "red" }}>*</span>
                                <AutoComplete
                                    className={this.state.inputTextCompanyNameErrorClass ? " p-invalid p-mr-2 w-100" : "w-100"}
                                    value={this.state.companyName}
                                    suggestions={this.state.filteredCompany}
                                    completeMethod={this.searchCountry}
                                    field="name" dropdown forceSelection
                                    onChange={this.onCompanyNameChange}
                                    placeholder="Select Company Name"
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageCompanyname}</small>
                            </div>
                            <div >
                                <label className="form-label">
                                    Service Name
                                </label><span style={{ color: "red" }}>*</span>
                                <input type="text"
                                    id="serviceName"
                                    name="serviceName"
                                    value={this.state.serviceName}
                                    className={this.state.inputTextServiceErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageServicename}</small>
                            </div>

                            <div >

                                <label className="form-label">
                                    Tagline
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input type="text"
                                    id="tagline"
                                    name="tagline"
                                    value={this.state.tagline}
                                    className={this.state.inputTextTagErrorClass ? "form-control p-invalid p-mr-2" : "form-control"}
                                    onChange={this.onChange}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageTagline}</small>

                            </div>
                            <div className='editor-description'>
                                <label className="form-label">
                                    Description
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Editor style={{ height: '320px' }}
                                    value={this.state.description}
                                    onTextChange={(e) => this.setState({ description: e.htmlValue })}
                                    className={this.state.inputTextDescErrorClass ? " p-invalid p-mr-2" : null}
                                />
                                <small id="username-help" className="p-error">{this.state.errorMessageDesc}</small>

                            </div>

                            <br />

                            <div className="row">
                                <div className="col-10">
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}
                                    {this.state.errorMessage != "" ? <Message severity="error" text={this.state.errorMessage} style={{ float: "right" }} />
                                        : null}
                                </div>
                                <div className="col-2">

                                    {
                                        this.state.serviceId == 0 ?
                                            <Button label="Save" icon="pi pi-check" loading={this.state.btnloadingsave} onClick={this.onOverviewSaveClick} style={{ float: "right" }} />
                                            :
                                            <Button label="Update" icon="pi pi-check" loading={this.state.btnloading} onClick={() => this.onOverviewUpdateClick(this.props.serviceId)} style={{ float: "right" }} />
                                    }


                                </div>
                            </div>

                        </>
                }

            </div>
        );
    }
}
export default withRouter(Overview)
