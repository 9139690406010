import React, { Component } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonValues from '../../Common/Util';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Redirect } from "react-router-dom";
import { MultiSelect } from 'primereact/multiselect';

export default class AdditionalCompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedForPartner: false,
            checkedForCompetitor: false,
            checkedForEcosystem: false,
            checkedForCommercialEngagement: false,
            keyTechnologies: "",
            introducedVia: "",
            introducedViaName: "",
            partnerName: "",
            pointOfContact: "",
            representativeAssigned: "",
            provideDetails: "",
            successMessage: "",
            representativeList: [],
            partnerList: [],
            partnerNameId: "",
            representativeAssignedId: "",
            introducedViaAnother: "",
            introViaAnotherId: "",
            introducedViaPersonal: "",
            introducedViaPersonalId: "",
            companyInfo: [],
            loading: false,
            selectedIntroContact: "",
            selectedIntroCompany: "",
            selectedRepresentativeName: "",
            representativeAssignedLoading: false,
            partnerLoading: false,
            directPatnerIds:[],
            directPatnerIdAndVersion:"",
            directPartners:"",
            PartnerImplementationId:""
        };

        this.introducedVia = [
            { name: 'Personal' },
            { name: 'Commercial' },
        ];


    }
    componentDidMount = () => {
        if (this.props.companyId != 0) {
            this.setState({
                loading: true
            })
            this.getAdditionalInfoByComapanyId();
        }
        else {
            this.getPartnerList();
            this.getIntroducedViaList();
            this.getRepresentativeList();
        }
    }
    getAdditionalInfoByComapanyId = () => {
        var token = CommonValues.GetToken();
        var axios = require('axios');


        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getadditionalinfoofcomapny?companyId=${this.props.companyId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },

        };
        this.setState({
            loading: true,
        });
        axios(config)
            .then((response) => {
                this.setState({
                    introducedViaName: response.data[0].introType
                })
                this.setState({
                    companyInfo: response.data,
                    loading: false,
                    checkedForPartner: response.data[0].isCurrentPartner,
                    checkedForCompetitor: response.data[0].isCompetitor,
                    checkedForEcosystem: response.data[0].forEcosystem,
                    checkedForCommercialEngagement: response.data[0].isIntroCommercial,
                    selectedIntroContact: response.data[0].introContact,
                    selectedIntroCompany: response.data[0].introCompany,
                    selectedRepresentativeName: response.data[0].representativeName,
                    selectedDirectPartners:response.data[0].partnerIds!=null ?response.data[0].partnerIds.split(','):'',
                    PartnerImplementationId:response.data[0].partnerImplementationId
                });
                this.getIntroducedViaList();
                this.getPartnerList();

                this.getRepresentativeList();

                response.data.forEach(element => {
                    this.introducedVia.forEach(listItem => {
                        if (listItem.name == element.introType) {
                            this.setState({
                                introducedVia: listItem

                            });
                        }
                    });
                });
               

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    getIntroducedViaList = () => {
        this.setState({
            partnerLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getintroducedcompanyvialist`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    IntroducedViaList: response.data,
                    partnerLoading: false
                });
                response.data.forEach(listItem => {
                    if (listItem.userId == this.state.selectedIntroContact) {
                        this.setState({
                            introducedViaPersonal: listItem,
                            introducedViaPersonalId: listItem.userId
                        });
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    partnerLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    getPartnerList = () => {
        this.setState({
            partnerLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/getpartnerlist`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    partnerList: response.data,
                    partnerLoading: false
                });
                response.data.forEach(listItem => {
                    if (listItem.companyId == this.state.selectedIntroCompany) {
                        this.setState({
                            introducedViaAnother: listItem,
                            introViaAnotherId: listItem.companyId
                            
                        });
                    }                    
                });
                
                if (response.data != null && response.data.length > 0 && this.state.selectedDirectPartners!='' ) {
                    const directPartners = [];
                    response.data.forEach(list => {
                        this.state.selectedDirectPartners.forEach(item => {
                            if (item.trim() == list.companyId.trim()) {
                                directPartners.push(list)
                            }
                        })
                    })
                    this.setState({
                        directPartners: directPartners
                    });
                }
                
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    partnerLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    getRepresentativeList = () => {
        this.setState({
            representativeAssignedLoading: true
        })
        var token = CommonValues.GetToken();
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/getrepresentativelist?`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    representativeList: response.data,
                    representativeAssignedLoading: false
                });
                response.data.forEach(listItem => {
                    if (listItem.userId == this.state.selectedRepresentativeName) {
                        this.setState({
                            representativeAssigned: listItem,
                            representativeAssignedId: listItem.userId
                        });
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    representativeAssignedLoading: false
                })
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });
    }
    onintroducedViaAnotherChange = (e) => {
        let companyId = e.value.companyId
        let selectedRequest = {};

        this.state.partnerList.forEach(element => {
            if (element.companyId == companyId) {
                selectedRequest = element;
            }
        });
        this.setState({
            introducedViaAnother: selectedRequest,
            introViaAnotherId: selectedRequest.companyId
        });
    }
    onintroducedViaChange = (e) => {
        this.setState({
            introducedVia: e.value,
            introducedViaName: e.value.name
        });

    }
    onintroducedViaPersonalChange = (e) => {
        let userId = e.value.userId
        let selectedRequest = {};

        this.state.IntroducedViaList.forEach(element => {
            if (element.userId == userId) {
                selectedRequest = element;
            }
        });
        this.setState({
            introducedViaPersonal: selectedRequest,
            introducedViaPersonalId: selectedRequest.userId
        });
    }
    onpartnerNameChange = (e) => {

        let companyId = e.value.companyId
        let selectedRequest = {};

        this.state.partnerList.forEach(element => {
            if (element.companyId == companyId) {
                selectedRequest = element;
            }
        });
        this.setState({
            partnerName: selectedRequest,
            partnerNameId: selectedRequest.companyId
        });
    }
    onRepresentativeAssignedChange = (e) => {

        let userId = e.value.userId
        let selectedRequest = {};

        this.state.representativeList.forEach(element => {
            if (element.userId == userId) {
                selectedRequest = element;
            }
        });
        this.setState({
            representativeAssigned: selectedRequest,
            representativeAssignedId: selectedRequest.userId
        });


    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onDirectPartnerChange =(e)=>{
        
        this.setState({ directPartners: e.value });
        this.setState({
            directPatnerIds: e.value.map(element => element.companyId)
        });
    }
    onAdditionalInfoSaveClick = () => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var data = JSON.stringify({
            "CompanyId": this.props.companyId,
            "isCompetitor": this.state.checkedForCompetitor,
            "isCurrentPartner": this.state.checkedForPartner,
            "forEcosystem": this.state.checkedForEcosystem,
            "isIntroCommercial": this.state.checkedForCommercialEngagement,
            "RepresentativeName": this.state.representativeAssignedId,
            "IntroContact": this.state.introducedViaPersonalId,
            "IntroCompany": this.state.introViaAnotherId,
            "IntroType": this.state.introducedVia.name,
            "DirectPartnerIds":this.state.directPatnerIds,
            "PartnerImplementationId":this.state.PartnerImplementationId
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_CompanyService_Url}/api/company/updateadditionalinfo?`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        this.setState({
            btnsavebtnloading: true
        });
        axios(config)
            .then((response) => {
                this.getAdditionalInfoByComapanyId();
                this.setState({
                    successMessage: "Additional information added successfully...!!!",
                    btnsavebtnloading: false,
                });
                setTimeout(
                    () => this.setState({ successMessage: "" }),
                    3000
                );
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    btnsavebtnloading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
                else {
                    this.setState({
                        errorMessage: "Opp`s something went wrong"
                    });
                    setTimeout(
                        () => this.setState({ errorMessage: "" }),
                        3000
                    );
                }
            });

    }
    render() {
        const token = CommonValues.GetToken();
        if (token == null) {
            CommonValues.ClearValues();
            return <Redirect to="/" />
        }
        return (
            <>
                {
                    this.state.loading ?
                        <div style={{ textAlign: "center" }}>
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :

                        <div>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="form-label">
                                        Have we partnered with this company in the past or working with them currently?
                                    </label>
                                </div>
                                <div className="col-md-4" style={{ textAlign: "right" }} >
                                    <InputSwitch checked={this.state.checkedForPartner} onChange={(e) => this.setState({ checkedForPartner: e.value })} />
                                </div>

                                <div className="col-md-8">
                                    <label className="form-label">
                                        Is it our competitor?
                                    </label>
                                </div>
                                <div className="col-md-4" style={{ textAlign: "right" }}>
                                    <InputSwitch checked={this.state.checkedForCompetitor} onChange={(e) => this.setState({ checkedForCompetitor: e.value })} />
                                </div>

                                <div className="col-md-8">
                                    <label className="form-label">
                                        Is it for ecosystem only?
                                    </label>
                                </div>
                                <div className="col-md-4" style={{ textAlign: "right" }}>
                                    <InputSwitch checked={this.state.checkedForEcosystem} onChange={(e) => this.setState({ checkedForEcosystem: e.value })} />

                                </div>


                                <div className="col-8" style={{ marginBottom: "15px" }} >
                                    <label className="form-label">
                                        This company was introduced to you via:
                                    </label>
                                </div>

                                <div className="col-md-4" >
                                    <Dropdown value={this.state.introducedVia}
                                        options={this.introducedVia}
                                        onChange={this.onintroducedViaChange}
                                        optionLabel="name"
                                        placeholder="Select a Company Introduced to you via"
                                        style={{ width: "100%" }} />
                                </div>

                                <div className="col-md-6">
                                </div>
                                <div className="col-8" >
                                    <label className="form-label">
                                        Select Partner Name
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    {
                                        this.state.partnerLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <>
                                                {

                                                    this.state.introducedViaName == 'Personal' ?

                                                        <Dropdown value={this.state.introducedViaPersonal}
                                                            options={this.state.IntroducedViaList}
                                                            onChange={this.onintroducedViaPersonalChange}
                                                            optionLabel="fullName"
                                                            placeholder="Select a partner name"
                                                            style={{ width: "100%" }}
                                                        />
                                                        :
                                                        <>
                                                            {
                                                                this.state.introducedViaName == 'Commercial' ?
                                                                    <Dropdown value={this.state.introducedViaAnother}
                                                                        options={this.state.partnerList}
                                                                        onChange={this.onintroducedViaAnotherChange}
                                                                        optionLabel="name"
                                                                        placeholder="Select a partner name"
                                                                        style={{ width: "100%" }} />
                                                                    :
                                                                    null
                                                            }
                                                        </>

                                                }
                                            </>
                                    }


                                </div>
                                <div className="col-md-8">
                                    <label className="form-label">
                                        Is it a commercial engagement?
                                    </label>
                                </div>
                                <div className="col-md-4" style={{ textAlign: "right" }}>
                                    <InputSwitch checked={this.state.checkedForCommercialEngagement} onChange={(e) => this.setState({ checkedForCommercialEngagement: e.value })} />
                                </div>

                                <div className="col-8"  style={{ marginBottom: "15px" }}>
                                    <label className="form-label">
                                        InsuranceGiG representative assigned:
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    {
                                        this.state.representativeAssignedLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <Dropdown value={this.state.representativeAssigned}
                                                options={this.state.representativeList}
                                                onChange={this.onRepresentativeAssignedChange}
                                                optionLabel="fullName"
                                                placeholder="Select a representative name"
                                                style={{ width: "100%" }} />
                                    }

                                </div>

                                <div className="col-8" >
                                    <label className="form-label">
                                        Direct Partner Relationship:
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    {
                                        this.state.partnerLoading ?
                                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration=".5s" />
                                            :
                                            <>
                                                <MultiSelect value={this.state.directPartners}
                                                    options={this.state.partnerList}
                                                    onChange={this.onDirectPartnerChange}
                                                    optionLabel="name"
                                                    placeholder="Select Partner"
                                                    maxSelectedLabels={5}
                                                    style={{ width: "100%" }} />
                                            </>
                                    }
                                </div>

                            </div><br />
                            <div className="row">
                                <div className='col-10'>
                                    {this.state.successMessage != "" ? <Message severity="success" text={this.state.successMessage} style={{ float: "right" }} />
                                        : null}

                                </div>
                                <div className="col-2">
                                    <Button label="Save" icon="pi pi-check" loading={this.state.btnsavebtnloading} onClick={this.onAdditionalInfoSaveClick} style={{ float: "right" }} />
                                </div>
                            </div>


                        </div >
                }
            </>
        )
    }
}

